import styled from 'styled-components';

export const GroupForm = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  margin-top: 15px;

  > form {
    width: 70%;

    > button {
      width: 100%;
    }
  }
`;
