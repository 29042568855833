import styled from 'styled-components';
import coloricon from '../../styles/coloricon';

const { secondaryColor } = coloricon;

export const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

export const Content = styled.div`
  width: 100%;
  max-width: 900px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 0 10px;
  margin-bottom: 25px;

  @media (max-width: 768px) {
    font-size: 15px;
  }

  @media (max-width: 425px) {
    font-size: 14px;
  }

  @media (max-width: 375px) {
    font-size: 13px;
  }

  @media (max-width: 320px) {
    font-size: 12px;
  }

  > h1 {
    margin: 15px 0px;
  }

  > form {
    width: 90%;

    > button {
      width: 100%;
    }
  }
`;

export const GroupCategory = styled.div`
  display: flex;
  width: 100%;
  flex-direction: row;
  align-items: center;
  justify-content: center;
`;

export const GroupCategoryItem = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: ${coloricon.backgroundHeader};
  border-radius: 5px;
  padding: 30px 50px;
  margin: 15px;
  transition: all 0.3s ease-in-out;

  h1 {
    font-weight: bolder;
    margin: 7px 0px;
  }

  h2 {
    font-weight: bolder;
    font-size: 1.45em;
    margin: 7px 0px;
  }

  h3 {
    font-weight: bolder;
    font-size: 1.7em;
    margin: 7px 0px;
  }

  > h4 {
    color: ${secondaryColor};
    font-weight: bolder;
    font-size: 1.5em;
    margin: 7px 0px;
  }

  button {
    width: 100%;
  }

  &:hover {
    background-color: ${coloricon.backgroundHeaderHover};
  }
`;
