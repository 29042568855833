import { useState, useCallback } from 'react';
import { toast } from 'react-toastify';
import api from '../services/api';
import clearLocalStorage from './clearLocalStorage';

export default function ManagerContext() {
  const [dataManager, setDataManager] = useState(() => {
    const data = localStorage.getItem(
      `@${process.env.REACT_APP_NAME}:dataManager`
    );

    if (data) {
      const dataParsed = JSON.parse(data);
      api.defaults.headers.Authorization = `Bearer ${dataParsed.token}`;
      return {
        token: dataParsed.token,
        manager: dataParsed.manager,
        openDrawer: dataParsed.openDrawer,
      };
    }

    return { token: null, manager: null, openDrawer: true };
  });

  const handleLoginManager = useCallback(async (email, password) => {
    try {
      const response = await api.post('/sessionManager', {
        email,
        password,
      });

      const { token, manager } = response.data;

      const data = {
        token,
        manager,
        openDrawer: true,
      };

      clearLocalStorage();

      localStorage.setItem(
        `@${process.env.REACT_APP_NAME}:dataManager`,
        JSON.stringify(data)
      );

      setDataManager({ token, manager, openDrawer: true });

      api.defaults.headers.Authorization = `Bearer ${token}`;

      return '/dashManager';
    } catch (err) {
      toast.info(err.response.data.error);
      return null;
    }
  }, []);

  const handleLogoutManager = useCallback(async () => {
    clearLocalStorage();
    setDataManager({ token: null, manager: null, openDrawer: true });
    api.defaults.headers.Authorization = undefined;
  }, []);

  return {
    dataManager,
    setDataManager,
    handleLoginManager,
    handleLogoutManager,
  };
}
