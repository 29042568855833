import styled from 'styled-components';
import { Link } from 'react-router-dom';
import coloricon from '../../styles/coloricon';

const { backgroundHeader, fontColor, secondaryColor, secondaryColorHover } =
  coloricon;

export const Header = styled.header`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-around;
  background-color: ${backgroundHeader};
  height: 80px;

  @media (max-width: 700px) {
    font-size: 14px;
    flex-direction: column;
    height: auto;
    padding: 30px 0px;
  }
`;

export const ContentLogo = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;

  @media (max-width: 700px) {
    margin-bottom: 25px;
  }

  @media (max-width: 425px) {
    flex-direction: column;

    > img {
      margin-bottom: 10px;
    }
  }

  > img {
    width: 3.5em;
    height: 3.5em;
  }

  > h1 {
    font-size: 1.5em;
    padding-left: 5px;
    color: ${fontColor};
  }
`;

export const ContentMenu = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;

  nav ul {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;

    @media (max-width: 425px) {
      flex-direction: column;
    }
  }

  nav ul li > a {
    display: block;
    padding: 10px;
    font-size: 1.1em;
    color: ${fontColor};
    transition: all 0.3s ease-in-out;

    &:hover {
      color: ${secondaryColor};
    }
  }
`;

export const LoginButton = styled(Link)`
  display: block;
  padding: 10px;
  font-size: 1.1em;
  color: ${secondaryColor} !important;
  border-radius: 4px;
  transition: all 0.3s ease-in-out;

  &:hover {
    color: ${secondaryColorHover} !important;
  }
`;
