import React, { useEffect, useState, useCallback } from 'react';
import { toast } from 'react-toastify';
import { Link, useNavigate, useParams } from 'react-router-dom';
import api from '../../services/api';
import ReactTable from '../../components/ReactTable';
import Modal from '../../components/Modal';
import Button from '../../components/Button';
import coloricon from '../../styles/coloricon';
import Loading from '../../components/Loading';
import BreadCrumbs from '../../components/BreadcrumbsMUI';
import { GroupButton, ButtonArea, IconArea } from './styles';

export default function AnimalManager() {
  const [loading, setLoading] = useState(true);
  const { idPlayer } = useParams();
  const navigation = useNavigate();
  const { IconDelete, IconEdit } = coloricon;
  const [animals, setAnimals] = useState([]);
  const [openModal, setOpenModal] = useState(false);
  const [itemModal, setItemModal] = useState({});

  useEffect(() => {
    async function loaderAnimals() {
      try {
        const response = await api.get(
          `/animalsFromPlayerByManager/${idPlayer}`
        );
        setAnimals(response.data);
        setLoading(false);
      } catch (error) {
        setLoading(false);
        toast.info(error.response.data.error || 'Falha no carregamento!');
      }
    }
    loaderAnimals();
  }, [idPlayer]);

  const handleOpenModal = useCallback((model) => {
    setItemModal(model);
    setOpenModal(true);
  }, []);

  const handleCloseModal = useCallback(() => {
    setOpenModal(false);
    setItemModal({});
  }, []);

  const handleDelete = useCallback(async () => {
    try {
      const response = await api.delete(
        `/animalDeleteByManager/${idPlayer}/${itemModal.id}`
      );
      const newArray = animals.filter((item) => item.id !== itemModal.id);
      setAnimals(newArray);
      toast.info(response.data.message);
      setOpenModal(false);
      setItemModal({});
    } catch (error) {
      toast.info(error.response.data.error);
    }
  }, [animals, itemModal.id, idPlayer]);

  return loading === true ? (
    <Loading />
  ) : (
    <>
      <BreadCrumbs
        nameOfCurrentPage="Controle"
        arrayOfTitleAndPath={[
          {
            title: 'Início',
            path: '/dashManager',
          },
          {
            title: 'Animais',
            path: '/animalsFromPlayers',
          },
        ]}
      />
      {animals && animals.length === 0 && (
        <>
          <ButtonArea>
            <Button
              type="button"
              onClick={() =>
                navigation({ pathname: `/animalCreateByManager/${idPlayer}` })
              }
            >
              Adicionar animal
            </Button>
          </ButtonArea>
          <p>Não existem registros para exibir.</p>
        </>
      )}
      {animals && animals.length > 0 && (
        <GroupButton>
          <Button
            type="button"
            onClick={() =>
              navigation({ pathname: `/animalCreateByManager/${idPlayer}` })
            }
          >
            Adicionar animal
          </Button>
        </GroupButton>
      )}
      {animals && animals.length > 0 && (
        <ReactTable
          data={animals}
          columns={[
            { Header: 'Nome', accessor: 'name', width: '70%' },
            {
              Header: 'Animal',
              accessor: 'animal',
              disableFilters: true,
            },
            {
              Header: 'Editar',
              Cell: ({ row }) => (
                <IconArea>
                  <Link
                    to={`/animalUpdateByManager/${idPlayer}/${row.original.id}`}
                  >
                    <IconEdit />
                  </Link>
                </IconArea>
              ),
            },
            {
              Header: 'Excluir',
              Cell: ({ row }) => (
                <IconArea>
                  <IconDelete onClick={() => handleOpenModal(row.original)} />
                </IconArea>
              ),
            },
          ]}
        />
      )}

      <Modal
        isOpen={openModal}
        onClickClose={handleCloseModal}
        onClickAction={handleDelete}
        title="Confirma a exclusão do animal?"
        nameOfItem={`${itemModal.name} - ${itemModal.animal}`}
      />
    </>
  );
}
