import styled from 'styled-components';
import coloricon from '../../styles/coloricon';

const { background } = coloricon;

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  max-width: 900px;
  background-color: ${background};
  min-height: 100%;
`;
