import styled from 'styled-components';
import coloricon from '../../styles/coloricon';

const { secondaryColor, secondaryColorHover } = coloricon;

export const IconArea = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  cursor: pointer;

  > a {
    cursor: pointer;
    color: ${secondaryColor};
    transition: all 0.3s ease-in-out;

    &:hover {
      color: ${secondaryColorHover};
    }
  }
`;
