import React from 'react';
import { Link } from 'react-router-dom';
import { Header, ContentLogo, ContentMenu, LoginButton } from './styles';

export default function HeaderHome() {
  return (
    <Header>
      <Link to="/">
        <ContentLogo>
          <h1>{process.env.REACT_APP_NAME}</h1>
        </ContentLogo>
      </Link>
      <ContentMenu>
        <nav>
          <ul>
            <li>
              <Link to="/">Início</Link>
            </li>
            <li>
              <Link to="/eventsHome">Eventos</Link>
            </li>
            <li>
              <Link to="/rankingHome">Ranking</Link>
            </li>
            <li>
              <Link to="/faq">Dúvidas</Link>
            </li>
            <li>
              <Link to="/contactus">Contato</Link>
            </li>
            <li>
              <LoginButton to="/loginPlayer">Entrar</LoginButton>
            </li>
          </ul>
        </nav>
      </ContentMenu>
    </Header>
  );
}
