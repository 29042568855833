import React, {
  useState,
  useEffect,
  useRef,
  useCallback,
  useContext,
} from 'react';
import { mask, unMask } from 'remask';
import { Form } from '@unform/web';
import { toast } from 'react-toastify';
import * as Yup from 'yup';
import { ButtonArea, ContentArea } from './styles';
import { states } from '../../enums/states';
import SelectInput from '../../components/SelectInput';
import coloricon from '../../styles/coloricon';
import Input from '../../components/Input';
import Button from '../../components/Button';
import api from '../../services/api';
import Loading from '../../components/Loading';
import BreadCrumbs from '../../components/BreadcrumbsMUI';
import { AuthContext } from '../../context/AuthContext';

export default function ProfilePlayer() {
  const [loading, setLoading] = useState(true);
  const { IconUser, IconPhone, IconMail, IconLock, IconSex, IconMap } =
    coloricon;
  const [profile, setProfile] = useState({});
  const [maskPhone, setMaskPhone] = useState('');
  const [gender, setGender] = useState([]);
  const [cityList, setCityList] = useState([]);

  const formRef = useRef(null);
  const { setDataPlayer, dataPlayer } = useContext(AuthContext);

  const onChangeMaskPhone = useCallback((event) => {
    const originalValue = unMask(event.target.value);
    const maskedValue = mask(originalValue, ['(99) 99999-9999']);
    setMaskPhone(maskedValue);
  }, []);

  const handleInputSelected = useCallback(async (event) => {
    /* limpando o select */
    const select = formRef?.current?.getFieldRef('city');

    select?.clearValue();

    const stateObj = event;

    const response = await fetch(
      `https://servicodados.ibge.gov.br/api/v1/localidades/estados/${
        stateObj && stateObj.value
      }/distritos`
    )
      .then((result) => result.json())
      .catch((error) => {
        if (error) {
          toast.info('Ocorreu um erro ao carregar as cidades!');
        }
      });
    const arrayCities = response;
    const finalArray = arrayCities
      .map((item) => {
        const result = {
          id: item.id,
          value: item.nome,
          label: item.nome,
        };
        return result;
      })
      .sort((a, b) => {
        if (a.label > b.label) {
          return 1;
        }
        if (a.label < b.label) {
          return -1;
        }
        // a must be equal to b
        return 0;
      });
    setCityList(finalArray);
  }, []);

  useEffect(() => {
    async function loaderProfile() {
      try {
        const response = await api.get('/players');
        setProfile(response.data);
        setMaskPhone(response.data.phone || '');
        response.data.gender === 'M'
          ? setGender([
              {
                id: 'feminino',
                value: 'F',
                label: 'Feminino',
                selected: false,
              },
              {
                id: 'masculino',
                value: 'M',
                label: 'Masculino',
                selected: true,
              },
            ])
          : setGender([
              {
                id: 'feminino',
                value: 'F',
                label: 'Feminino',
                selected: true,
              },
              {
                id: 'masculino',
                value: 'M',
                label: 'Masculino',
                selected: false,
              },
            ]);

        // abastecendo as cidades
        const objectForLoadCitiesIBGE = states.find(
          (item) => item.sigla === response.data.state
        );

        // formRef.current.setFieldRef('city', 'Alfenas');
        const objectForLoadCitiesIBGEFormatted = {
          ...objectForLoadCitiesIBGE,
          value: objectForLoadCitiesIBGE.sigla,
        };

        await handleInputSelected(objectForLoadCitiesIBGEFormatted);

        setLoading(false);
      } catch (error) {
        setLoading(false);
        toast.info(error.response.data.error || 'Falha no carregamento!');
      }
    }
    loaderProfile();
  }, [handleInputSelected]);

  const stateList = states
    .map((item) => {
      const items = {
        id: item.id,
        value: item.sigla,
        label: item.nome,
      };
      return items;
    })
    .sort((a, b) => {
      if (a.label > b.label) {
        return 1;
      }
      if (a.label < b.label) {
        return -1;
      }
      // a must be equal to b
      return 0;
    });

  const handleSubmit = useCallback(
    async (data) => {
      try {
        // montando o novo objeto que vem do data para nao pegar vazio
        const newDataFiltered = {};
        Object.entries(data).forEach(([key, value]) => {
          newDataFiltered[key === 'phoneFormatted' ? 'phone' : key] = value;
        });

        const schemaValidation = Yup.object().shape({
          name: Yup.string()
            .required('O nome é obrigatório!')
            .min(3, 'O nome precisa mínimo 3 caracteres!')
            .max(75, 'O nome suporta máximo 75 caracteres'),

          nickname: Yup.string()
            .required('O nickname é obrigatório!')
            .min(3, 'O nickname precisa mínimo 3 caracteres!')
            .max(75, 'O nickname suporta máximo 75 caracteres'),

          gender: Yup.string().required('O gênero é obrigatório!'),

          phoneFormatted: Yup.string()
            .required('O celular é obrigatório!')
            .min(15, 'O celular precisa mínimo 11 dígitos!')
            .max(15, 'O celular suporta máximo 11 dígitos!'),

          email: Yup.string()
            .email('Endereço inválido!')
            .required('O e-mail é obrigatório!'),

          city: Yup.string().required('A cidade é obrigatória!'),

          state: Yup.string().required('O estado é obrigatório!'),

          oldPassword: Yup.string().when({
            is: (val) => val && val.length > 0,
            then: Yup.string()
              .required('A senha atual é obrigatória!')
              .min(6, 'A senha atual precisa mínimo 6 dígitos!')
              .max(12, 'A senha atual suporta máximo 12 dígitos!'),
            otherwise: Yup.string(),
          }),

          password: Yup.string().when('oldPassword', {
            is: (val) => val && val.length > 0,
            then: Yup.string()
              .required('A nova senha é obrigatória!')
              .min(6, 'A nova senha precisa mínimo 6 dígitos!')
              .max(12, 'A nova senha suporta máximo 12 dígitos!'),
            otherwise: Yup.string(),
          }),

          confirmPassword: Yup.string().when('password', {
            is: (val) => val && val.length > 0,
            then: Yup.string()
              .required('A confirmação da senha é obrigatória!')
              .oneOf(
                [Yup.ref('password')],
                'A nova senha e a confirmação são diferentes!'
              ),
            otherwise: Yup.string(),
          }),
        });

        await schemaValidation.validate(data, {
          abortEarly: false,
        });

        formRef.current.setErrors({});

        const response = await api.put('/players', newDataFiltered);

        formRef.current.setData({
          name: response.data.playerUpdated.name,
          nickname: response.data.playerUpdated.nickname,
          email: response.data.playerUpdated.email,
          gender: response.data.playerUpdated.gender,
          phoneFormatted: response.data.playerUpdated.phone,
          city: response.data.playerUpdated.city,
          state: response.data.playerUpdated.state,

          oldPassword: null,
          password: null,
          confirmPassword: null,
        });

        const player = {
          id: response.data.playerUpdated.id,
          name: response.data.playerUpdated.name,
        };

        dataPlayer.player = player;

        localStorage.setItem(
          `@${process.env.REACT_APP_NAME}:dataPlayer`,
          JSON.stringify(dataPlayer)
        );

        setDataPlayer(dataPlayer);

        toast.info(response.data.message);
      } catch (err) {
        if (err instanceof Yup.ValidationError) {
          const errorMessages = {};
          err.inner.forEach((error) => {
            errorMessages[error.path] = error.message;
          });
          formRef.current.setErrors(errorMessages);
        } else {
          toast.info(err.response.data.error);
        }
      }
    },
    [setDataPlayer, dataPlayer]
  );

  return loading === true ? (
    <Loading />
  ) : (
    <>
      <BreadCrumbs
        nameOfCurrentPage="Meus dados"
        arrayOfTitleAndPath={[
          {
            title: 'Início',
            path: '/dashPlayer',
          },
        ]}
      />
      <ContentArea>
        <Form ref={formRef} initialData={profile} onSubmit={handleSubmit}>
          <Input
            type="text"
            name="name"
            placeholder="Nome e sobrenome"
            required
            icon={IconUser}
            inputProps={{ minLength: 3, maxLength: 75, size: 75 }}
          />

          <Input
            type="text"
            name="nickname"
            placeholder="Nickname / Apelido"
            required
            icon={IconUser}
            inputProps={{ minLength: 3, maxLength: 75, size: 75 }}
          />

          <SelectInput
            options={gender}
            name="gender"
            placeholder="Gênero"
            icon={IconSex}
            defaultValue={
              gender[gender.findIndex((item) => item.value === profile.gender)]
            }
          />

          <Input
            type="text"
            name="phoneFormatted"
            placeholder="DDD + Celular"
            required
            icon={IconPhone}
            onChange={onChangeMaskPhone}
            inputProps={{ maxLength: 15, size: 15 }}
            value={maskPhone}
          />

          <Input
            type="email"
            name="email"
            placeholder="E-mail"
            required
            icon={IconMail}
          />

          <SelectInput
            options={stateList}
            name="state"
            placeholder="Estado"
            onChange={handleInputSelected}
            icon={IconMap}
            defaultValue={
              stateList[
                stateList.findIndex((item) => item.value === profile.state)
              ]
            }
          />

          <SelectInput
            options={cityList}
            name="city"
            placeholder="Cidade"
            icon={IconMap}
            defaultValue={
              cityList[
                cityList.findIndex((item) => item.value === profile.city)
              ]
            }
          />

          <Input
            type="password"
            name="oldPassword"
            autoComplete="on"
            placeholder="Senha atual"
            icon={IconLock}
            inputProps={{ minLength: 6, maxLength: 12, size: 12 }}
          />
          <Input
            type="password"
            name="password"
            autoComplete="on"
            placeholder="Nova senha"
            icon={IconLock}
            inputProps={{ minLength: 6, maxLength: 12, size: 12 }}
          />
          <Input
            type="password"
            name="confirmPassword"
            autoComplete="on"
            placeholder="Confirmação"
            icon={IconLock}
            inputProps={{ minLength: 6, maxLength: 12, size: 12 }}
          />
          <ButtonArea>
            <Button type="submit">Atualizar</Button>
          </ButtonArea>
        </Form>
      </ContentArea>
    </>
  );
}
