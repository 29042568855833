import React, { useState, useEffect, useCallback } from 'react';
import { toast } from 'react-toastify';
import { useParams, useNavigate } from 'react-router-dom';
import Button from '../../components/Button';
import Loading from '../../components/Loading';
import BreadCrumbs from '../../components/BreadcrumbsMUI';
import Modal from '../../components/Modal';
import api from '../../services/api';
import { formatPrice } from '../../utils/format';
import { GroupDetails, ButtonArea } from './styles';

export default function RegistrationPlayers() {
  const [event, setEvent] = useState([]);
  const [loading, setLoading] = useState(true);
  const { idRegistration } = useParams();
  const [openModal, setOpenModal] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    async function loaderRegistration() {
      try {
        const response = await api.get(
          `/registrationsPlayer/${idRegistration}`
        );
        if (response.data.length > 0) {
          const eventList = response.data.map((item) => {
            const data = {
              ...item,
              id: item.id,
              registration_number: item.registration_number,
              category: item.tuition?.category.name || 'Não encontrado',
              event: item.tuition?.event.name || 'Não encontrado',
            };
            return data;
          });
          setEvent(eventList);
          setLoading(false);
        }
        setLoading(false);
      } catch (error) {
        setLoading(false);
        toast.info(error?.response.data.error || 'Falha no carregamento!');
      }
    }
    loaderRegistration();
  }, [idRegistration]);

  const handleOpenModal = useCallback(() => {
    setOpenModal(true);
  }, []);

  const handleCloseModal = useCallback(() => {
    setOpenModal(false);
  }, []);

  const handleDelete = useCallback(async () => {
    try {
      const response = await api.delete(
        `/removeRegistrationByPlayer/${idRegistration}`
      );
      toast.info(response?.data.message);
      setOpenModal(false);
      navigate({ pathname: '/registrationsPlayer' });
    } catch (error) {
      toast.info(error?.response.data.message || 'Falha no processamento!');
    }
  }, [idRegistration, navigate]);

  return loading ? (
    <Loading />
  ) : (
    <>
      <BreadCrumbs
        nameOfCurrentPage="Detalhes"
        arrayOfTitleAndPath={[
          { title: 'Início', path: '/dashPlayer' },
          { title: 'Inscrições', path: '/registrationsPlayer' },
        ]}
      />
      <GroupDetails>
        {event && event.length === 0 && (
          <p>Você não possui inscrições realizadas.</p>
        )}
      </GroupDetails>
      {event && event.length > 0 && (
        <>
          <GroupDetails>
            <h1>Categoria: {event[0]?.category}</h1>
            <h1>Evento: {event[0]?.event}</h1>
            <h1>Numero inscrito: {event[0]?.registration_number}</h1>
            <h1>Patrocinador: {event[0]?.representation}</h1>
            <h1>Animal: {event[0]?.animal?.name}</h1>
            <h1>Prêmio: {event[0]?.tuition?.award}</h1>
            <h1>Taxa inscrição: {formatPrice(event[0]?.tuition?.price)}</h1>
            <h1>
              Status pagamento: {event[0]?.paid === true ? 'Pago' : 'Pendente'}
            </h1>
          </GroupDetails>
          <ButtonArea>
            <Button type="button" onClick={() => handleOpenModal()}>
              Excluir participação
            </Button>
          </ButtonArea>
        </>
      )}
      <Modal
        isOpen={openModal}
        onClickClose={handleCloseModal}
        onClickAction={handleDelete}
        title="Confirma a exclusão da inscrição?"
        nameOfItem={`Inscrição: ${event[0]?.registration_number} - Animal: ${event[0]?.animal?.name}`}
      />
    </>
  );
}
