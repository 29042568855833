import React, { useRef, useCallback } from 'react';
import { toast } from 'react-toastify';
import * as Yup from 'yup';
import { Form } from '@unform/web';
import { useNavigate, useParams } from 'react-router-dom';
import Button from '../../components/Button';
import Input from '../../components/Input';
import api from '../../services/api';
import BreadCrumbs from '../../components/BreadcrumbsMUI';
import coloricon from '../../styles/coloricon';
import { ButtonArea, FormArea } from './styles';

export default function AnimalCreate() {
  const formRef = useRef(null);
  const { IconDocument } = coloricon;
  const navigate = useNavigate();
  const { idPlayer } = useParams();

  const handleSubmit = useCallback(
    async (data) => {
      const newData = {
        ...data,
        idPlayer,
      };
      try {
        const schemaValidation = Yup.object().shape({
          name: Yup.string()
            .required('O nome é obrigatório!')
            .min(3, 'O nome precisa mínimo 3 caracteres!')
            .max(75, 'O nome suporta máximo 75 caracteres!'),

          idPlayer: Yup.string().required('O ID do competidor é obrigatório!'),
        });

        await schemaValidation.validate(newData, {
          abortEarly: false,
        });

        formRef.current.setErrors({});
        await api.post('/animalCreateByManager', newData);

        toast.info('O animal foi cadastrado com sucesso!');

        navigate({ pathname: `/animalManagerByManager/${idPlayer}` });
      } catch (err) {
        if (err instanceof Yup.ValidationError) {
          const errorMessages = {};
          err.inner.forEach((error) => {
            errorMessages[error.path] = error.message;
          });
          formRef.current.setErrors(errorMessages);
        } else {
          toast.info(err.response.data.error);
        }
      }
    },
    [navigate, idPlayer]
  );

  return (
    <>
      <BreadCrumbs
        nameOfCurrentPage="Adicionar animal"
        arrayOfTitleAndPath={[
          {
            title: 'Início',
            path: '/dashManager',
          },
          {
            title: 'Animais',
            path: '/animalsFromPlayers',
          },
          {
            title: 'Controle',
            path: `/animalManagerByManager/${idPlayer}`,
          },
        ]}
      />
      <FormArea>
        <Form ref={formRef} onSubmit={handleSubmit}>
          <Input
            name="name"
            icon={IconDocument}
            type="text"
            placeholder="Nome do animal"
            required
          />
          <ButtonArea>
            <Button type="submit">Cadastrar</Button>
          </ButtonArea>
        </Form>
      </FormArea>
    </>
  );
}
