import React, { useCallback, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import FileSaver from 'file-saver';
import XLSX from 'xlsx';
import coloricon from '../styles/coloricon';
import Button from '../components/Button';

export default function ExportCSV({ csvData, fileName, fieldNames }) {
  const { IconDownload, textButton } = coloricon;
  const [reportData, setReportData] = useState(csvData);

  // funcao para ajustar os nomes dos campos
  const changeFieldNames = useCallback(
    (arrayWithFieldNames) =>
      arrayWithFieldNames.map((entry) =>
        Object.keys(entry).reduce((obj, key) => {
          const value = entry[key]; // ler o valor
          const fieldFormatted = fieldNames[key]; // mudar o nome da chave
          obj[fieldFormatted] = value;
          return obj;
        }, {})
      ),
    [fieldNames]
  );

  useEffect(() => {
    async function loaderItems() {
      const data = changeFieldNames(csvData);
      setReportData(data);
    }
    loaderItems();
  }, [changeFieldNames, csvData]);

  const fileType =
    'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';

  const fileExtension = '.xlsx';

  const exportToCSV = (csvDataChild, fileNameChild) => {
    const ws = XLSX.utils.json_to_sheet(csvDataChild);

    const wb = { Sheets: { data: ws }, SheetNames: ['data'] };

    const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });

    const data = new Blob([excelBuffer], { type: fileType });

    FileSaver.saveAs(data, fileNameChild + fileExtension);
  };

  return (
    <Button type="button" onClick={() => exportToCSV(reportData, fileName)}>
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <IconDownload size={20} color={textButton} />
        <p style={{ paddingLeft: '7px', color: textButton }}>Baixar planilha</p>
      </div>
    </Button>
  );
}

ExportCSV.propTypes = {
  csvData: PropTypes.oneOfType([
    PropTypes.array,
    PropTypes.object,
    PropTypes.element,
  ]).isRequired,
  fileName: PropTypes.string.isRequired,
  fieldNames: PropTypes.oneOfType([
    PropTypes.array,
    PropTypes.object,
    PropTypes.element,
  ]).isRequired,
};
