import React, { useEffect, useRef } from 'react';
import { FiAlertCircle } from 'react-icons/fi';
import PropTypes from 'prop-types';
import { useField } from '@unform/core';
import { Container, ContainerError } from './styles';

function CheckboxInput({ name, label, ...rest }) {
  const inputRef = useRef(null);

  const { fieldName, registerField, defaultValue, error } = useField(name);

  useEffect(() => {
    registerField({
      name: fieldName,
      ref: inputRef.current,
      path: 'checked',
    });
  }, [fieldName, registerField]);

  return (
    <>
      <Container>
        <input
          defaultValue={defaultValue}
          type="checkbox"
          name={fieldName}
          ref={inputRef}
          {...rest}
        />
        {label && <p>{label}</p>}
      </Container>
      <ContainerError>
        {error && <FiAlertCircle size={23} />}
        {error && <span>{error}</span>}
      </ContainerError>
    </>
  );
}

CheckboxInput.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
};

export default CheckboxInput;
