import React, { useRef, useState, useContext, useCallback } from 'react';
import { toast } from 'react-toastify';
import { Form } from '@unform/web';
import { mask, unMask } from 'remask';
import * as Yup from 'yup';
import { useNavigate } from 'react-router-dom';
import coloricon from '../../styles/coloricon';
import { AuthContext } from '../../context/AuthContext';
import Header from '../../components/Header';
import Footer from '../../components/Footer';
import { Banner, Content, BannerArea, LogoArea } from './styles';
import Input from '../../components/Input';
import Button from '../../components/Button';
import TermosCliente from '../../documents/termos_de_uso_cliente.pdf';
import Privacidade from '../../documents/politica_de_privacidade.pdf';
import { states } from '../../enums/states';
import SelectInput from '../../components/SelectInput';

export default function RegisterUser() {
  const formRef = useRef(null);
  const { IconUser, IconPhone, IconMail, IconLock, IconMap, IconSex } =
    coloricon;
  const navigate = useNavigate();
  const [cityList, setCityList] = useState([]);

  const [maskPhone, setMaskPhone] = useState('');
  const onChangeMaskPhone = useCallback((event) => {
    const originalValue = unMask(event.target.value);
    const maskedValue = mask(originalValue, ['(99) 99999-9999']);
    setMaskPhone(maskedValue);
  }, []);

  const { handleRegisterPlayer } = useContext(AuthContext);

  const genderOptions = [
    {
      id: 'feminino',
      value: 'F',
      label: 'Feminino',
      selected: false,
    },
    {
      id: 'masculino',
      value: 'M',
      label: 'Masculino',
      selected: true,
    },
  ];

  const stateList = states
    .map((item) => {
      const items = {
        id: item.id,
        value: item.sigla,
        label: item.nome,
      };
      return items;
    })
    .sort((a, b) => {
      if (a.label > b.label) {
        return 1;
      }
      if (a.label < b.label) {
        return -1;
      }
      // a must be equal to b
      return 0;
    });

  const handleSubmit = useCallback(
    async (data) => {
      try {
        const schemaValidation = Yup.object().shape({
          name: Yup.string()
            .required('O nome é obrigatório!')
            .min(3, 'O nome precisa mínimo 3 caracteres!')
            .max(75, 'O nome suporta máximo 75 caracteres!'),

          nickname: Yup.string()
            .required('O nickname é obrigatório!')
            .min(3, 'O nome precisa mínimo 3 caracteres!')
            .max(75, 'O nome suporta máximo 75 caracteres!'),

          gender: Yup.string().required('O gênero é obrigatório!'),

          city: Yup.string().required('A cidade é obrigatória!'),

          state: Yup.string().required('O estado é obrigatório!'),

          phone: Yup.string()
            .required('O celular é obrigatório!')
            .min(15, 'O celular precisa mínimo 11 dígitos!')
            .max(15, 'O celular suporta máximo 11 dígitos!'),

          email: Yup.string()
            .email('Endereço inválido!')
            .required('O e-mail é obrigatório!'),

          password: Yup.string()
            .required('A senha é obrigatória!')
            .min(6, 'A senha precisa mínimo 6 dígitos')
            .max(12, 'A senha suporta máximo 12 dígitos'),
        });

        await schemaValidation.validate(data, {
          abortEarly: false,
        });

        formRef.current.setErrors({});

        const { name, nickname, gender, city, state, phone, email, password } =
          data;

        const response = await handleRegisterPlayer(
          name,
          nickname,
          gender,
          city,
          state,
          phone,
          email,
          password
        );
        if (response) {
          const path = response;
          navigate({ pathname: path });
        }
      } catch (err) {
        if (err instanceof Yup.ValidationError) {
          const errorMessages = {};

          err.inner.forEach((error) => {
            errorMessages[error.path] = error.message;
          });

          formRef.current.setErrors(errorMessages);
        } else {
          toast.info(err.response.data.error);
        }
      }
    },
    [handleRegisterPlayer, navigate]
  );

  const handleInputSelected = useCallback(async (event) => {
    /* limpando o select */
    const select = formRef.current.getFieldRef('city');
    select.clearValue();

    const stateObj = event;

    const response = await fetch(
      `https://servicodados.ibge.gov.br/api/v1/localidades/estados/${
        stateObj && stateObj.value
      }/distritos`
    )
      .then((result) => result.json())
      .catch((error) => {
        if (error) {
          toast.info('Ocorreu um erro ao carregar as cidades!');
        }
      });
    const arrayCities = response;
    const finalArray = arrayCities
      .map((item) => {
        const result = {
          id: item.id,
          value: item.nome,
          label: item.nome,
        };
        return result;
      })
      .sort((a, b) => {
        if (a.label > b.label) {
          return 1;
        }
        if (a.label < b.label) {
          return -1;
        }
        // a must be equal to b
        return 0;
      });
    setCityList(finalArray);
  }, []);

  return (
    <>
      <Header />
      <Banner>
        <BannerArea>
          <Content>
            <LogoArea />
            <h1>Cadastro de cliente</h1>
            <Form ref={formRef} onSubmit={handleSubmit}>
              <Input
                icon={IconUser}
                name="name"
                type="text"
                placeholder="Nome e sobrenome"
                required
                inputProps={{ maxLength: 75, size: 75 }}
              />

              <Input
                icon={IconUser}
                name="nickname"
                type="text"
                placeholder="Nickname"
                required
                inputProps={{ maxLength: 75, size: 75 }}
              />

              <SelectInput
                options={genderOptions}
                name="gender"
                placeholder="Gênero"
                icon={IconSex}
              />

              <Input
                name="phone"
                type="text"
                placeholder="DDD + Celular"
                required
                icon={IconPhone}
                inputProps={{ maxLength: 15, size: 15 }}
                onChange={onChangeMaskPhone}
                value={maskPhone}
              />
              <Input
                name="email"
                type="email"
                placeholder="E-mail"
                required
                icon={IconMail}
              />
              <Input
                name="password"
                type="password"
                autoComplete="on"
                placeholder="Senha"
                required
                icon={IconLock}
                inputProps={{ minLength: 6, maxLength: 12, size: 12 }}
              />

              <SelectInput
                options={stateList}
                name="state"
                placeholder="Estado"
                onChange={handleInputSelected}
                icon={IconMap}
              />

              <SelectInput
                options={cityList}
                name="city"
                placeholder="Cidade"
                icon={IconMap}
              />

              <Button type="submit">Enviar</Button>
            </Form>
            <h3>
              Ao se cadastrar, você concorda com os
              <a
                href={TermosCliente}
                without="true"
                rel="noopener noreferrer"
                target="_blank"
              >
                termos de uso
              </a>
              e com a
              <a
                href={Privacidade}
                without="true"
                rel="noopener noreferrer"
                target="_blank"
              >
                política de privacidade
              </a>
              da plataforma.
            </h3>
          </Content>
        </BannerArea>
      </Banner>
      <Footer />
    </>
  );
}
