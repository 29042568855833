import React from 'react';
import { Route, Routes } from 'react-router-dom';
import Drawer from '../components/Drawer';

// private rules
import ManagerRoutes from './ManagerRoutes';
import PlayerRoutes from './PlayerRoutes';

// menu conforme drawer
import { menuManager, menuPlayer } from '../components/Drawer/menus';

// public access
import Home from '../pages/Home';
import Faq from '../pages/Faq';
import LoginPlayer from '../pages/LoginPlayer';
import LoginManager from '../pages/LoginManager';
import RegisterPlayer from '../pages/RegisterPlayer';
import Contact from '../pages/ContactForm';
import ForgotPassPlayer from '../pages/ForgotPassPlayer';
import ForgotPassManager from '../pages/ForgotPassManager';
import ConfirmationMailPlayer from '../pages/ConfirmationMailPlayer';
import ResetAccessPlatformPlayer from '../pages/ResetAccessPlatformPlayer';
import ResetAccessPlatformManager from '../pages/ResetAccessPlatformManager';
import EventsHome from '../pages/EventsHome';
import EventDetailsHomePage from '../pages/EventDetailsHomePage';
import RankingHome from '../pages/RankingHome';
import About from '../pages/About';
import EventFolderHome from '../pages/EventFolderHome';
import ClassificationEventCategoryHome from '../pages/ClassificationEventCategoryHome';
import PointsOfRacingHome from '../pages/PointsOfRacingHome';

// player private
import ProfilePlayer from '../pages/ProfilePlayer';
import DashPlayer from '../pages/DashPlayer';
import CloseAccountPlayer from '../pages/CloseAccountPlayer';
import AnimalCreate from '../pages/AnimalCreate';
import AnimalManager from '../pages/AnimalManager';
import AnimalUpdate from '../pages/AnimalUpdate';
import EventsByPlayer from '../pages/EventsByPlayer';
import EventDetailsPlayer from '../pages/EventDetailsPlayer';
import Registration from '../pages/Registration';
import ConfirmationRegistration from '../pages/ConfirmationRegistration';
import ConfirmationSuccess from '../pages/ConfirmationSuccess';
import RegistrationPlayers from '../pages/RegistrationPlayers';
import RegistrationPlayerDetails from '../pages/RegistrationPlayerDetails';

// manager private
import DashManager from '../pages/DashManager';
import EventManager from '../pages/EventManager';
import EventCreate from '../pages/EventCreate';
import EventUpdate from '../pages/EventUpdate';
import PlayerByManager from '../pages/PlayersByManager';
import EventsByManager from '../pages/EventsByManager';
import EventDetailsManager from '../pages/EventDetailsManager';
import RegistrationByManager from '../pages/RegistrationByManager';
import RegistrationByManagerNextStep from '../pages/RegistrationByManagerNextStep';
import AnimalsFromPlayers from '../pages/AnimalsFromPlayers';
import AnimalManagerByManager from '../pages/AnimalManagerByManager';
import AnimalCreateByManager from '../pages/AnimalCreateByManager';
import AnimalUpdateByManager from '../pages/AnimalUpdateByManager';
import ConfirmationRegistrationByManager from '../pages/ConfirmationRegistrationByManager';
import ConfirmationSuccessByManager from '../pages/ConfirmationSuccessByManager';
import Financial from '../pages/Financial';
import FinancialsDetails from '../pages/FinancialsDetails';
import Classification from '../pages/Classification';
import ClassificationEventCategory from '../pages/ClassificationEventCategory';
import Steps from '../pages/Steps';
import EventFolder from '../pages/EventFolder';
import Racings from '../pages/Racings';
import PointsOfRacing from '../pages/PointsOfRacing';

// 404 not found
import PageNotFound from '../pages/PageNotFound';

export default function AllRoutes() {
  return (
    <Routes>
      {/* sequencia de rotas publicas */}
      <Route path="/" exact element={<Home />} />
      <Route path="/faq" element={<Faq />} />
      <Route path="/loginManager" element={<LoginManager />} />
      <Route path="/loginPlayer" element={<LoginPlayer />} />
      <Route path="/registerPlayer" element={<RegisterPlayer />} />
      <Route
        path="/confirmationMailPlayer/:token"
        element={<ConfirmationMailPlayer />}
      />
      <Route
        path="/resetAccessPlatformPlayer/:token"
        element={<ResetAccessPlatformPlayer />}
      />
      <Route
        path="/resetAccessPlatformManager/:token"
        element={<ResetAccessPlatformManager />}
      />
      <Route path="/forgotPassPlayer" element={<ForgotPassPlayer />} />
      <Route path="/forgotPassManager" element={<ForgotPassManager />} />
      <Route path="/contactus" element={<Contact />} />
      <Route path="/eventsHome" element={<EventsHome />} />
      <Route
        path="/eventHomePageDetails/:idEvent"
        element={<EventDetailsHomePage />}
      />
      <Route path="/rankingHome" element={<RankingHome />} />
      <Route path="/about" element={<About />} />
      <Route
        path="/eventFolderHome/:idEvent/name/:eventName"
        element={<EventFolderHome />}
      />
      <Route
        path="/classificationEventCategoryHome/:idEvent"
        element={<ClassificationEventCategoryHome />}
      />
      <Route
        path="/pointsOfRacingHome/event/:idEvent/category/:idCategory"
        element={<PointsOfRacingHome />}
      />

      {/* sequencia de rotas dos competidores */}
      <Route
        exact
        path="/"
        element={
          <Drawer menu={menuPlayer}>
            <PlayerRoutes />
          </Drawer>
        }
      >
        <Route path="/dashPlayer" element={<DashPlayer />} isPrivate />
        <Route path="/profilePlayer" element={<ProfilePlayer />} isPrivate />
        <Route path="/animalCreate" element={<AnimalCreate />} isPrivate />
        <Route path="/animalManager" element={<AnimalManager />} isPrivate />
        <Route
          path="/animalUpdate/:idAnimal"
          element={<AnimalUpdate />}
          isPrivate
        />

        <Route
          path="/closeAccountPlayer"
          element={<CloseAccountPlayer />}
          isPrivate
        />

        <Route path="/eventsByPlayer" element={<EventsByPlayer />} isPrivate />
        <Route
          path="/eventDetailsPlayer/:idEvent"
          element={<EventDetailsPlayer />}
          isPrivate
        />

        <Route
          path="/registration/:idEvent/:idCategory"
          element={<Registration />}
          isPrivate
        />

        <Route
          path="/confirmationRegistration/:idEvent/:idCategory/:representation/:idAnimal"
          element={<ConfirmationRegistration />}
          isPrivate
        />

        <Route
          path="/confirmationSuccess"
          element={<ConfirmationSuccess />}
          isPrivate
        />

        <Route
          path="/registrationsPlayer"
          element={<RegistrationPlayers />}
          isPrivate
        />
        <Route
          path="/registrationsPlayerDetails/:idRegistration"
          element={<RegistrationPlayerDetails />}
          isPrivate
        />
      </Route>

      {/* sequencia de rotas do administrador */}
      <Route
        exact
        path="/"
        element={
          <Drawer menu={menuManager}>
            <ManagerRoutes />
          </Drawer>
        }
      >
        <Route path="/dashManager" element={<DashManager />} isPrivate />
        <Route path="/eventManager" element={<EventManager />} isPrivate />
        <Route path="/eventCreate" element={<EventCreate />} isPrivate />
        <Route
          path="/eventUpdate/:idEvent"
          element={<EventUpdate />}
          isPrivate
        />
        <Route
          path="/playersByManager"
          element={<PlayerByManager />}
          isPrivate
        />
        <Route
          path="/eventsByManager"
          element={<EventsByManager />}
          isPrivate
        />
        <Route
          path="/eventDetailsManager/:idEvent"
          element={<EventDetailsManager />}
          isPrivate
        />
        <Route
          path="/registrationByManager/:idEvent/:idCategory"
          element={<RegistrationByManager />}
          isPrivate
        />
        <Route
          path="/registrationByManagerNextStep/:idEvent/:idCategory/:idPlayer"
          element={<RegistrationByManagerNextStep />}
          isPrivate
        />
        <Route
          path="/animalsFromPlayers"
          element={<AnimalsFromPlayers />}
          isPrivate
        />
        <Route
          path="/animalManagerByManager/:idPlayer"
          element={<AnimalManagerByManager />}
          isPrivate
        />
        <Route
          path="/animalCreateByManager/:idPlayer"
          element={<AnimalCreateByManager />}
          isPrivate
        />
        <Route
          path="/animalUpdateByManager/:idPlayer/:idAnimal"
          element={<AnimalUpdateByManager />}
          isPrivate
        />
        <Route
          path="/confirmationRegistrationByManager/:idEvent/:idCategory/:idPlayer/:representation/:idAnimal"
          element={<ConfirmationRegistrationByManager />}
          isPrivate
        />
        <Route
          path="/confirmationSuccessByManager"
          element={<ConfirmationSuccessByManager />}
          isPrivate
        />
        <Route path="/financial" element={<Financial />} isPrivate />
        <Route
          path="/eventFolder/:idEvent"
          element={<EventFolder />}
          isPrivate
        />
        <Route
          path="/financialsDetails/:idEvent"
          element={<FinancialsDetails />}
          isPrivate
        />
        <Route path="/classification" element={<Classification />} isPrivate />
        <Route
          path="/classificationEventCategory/:idEvent"
          element={<ClassificationEventCategory />}
          isPrivate
        />
        <Route
          path="/steps/:idEvent/:idCategory/:idRacing"
          element={<Steps />}
          isPrivate
        />
        <Route
          path="/racings/:idEvent/:idCategory"
          element={<Racings />}
          isPrivate
        />
        <Route
          path="/pointsOfRacing/:idEvent/:idCategory"
          element={<PointsOfRacing />}
          isPrivate
        />
      </Route>
      <Route path="*" element={<PageNotFound />} />
    </Routes>
  );
}
