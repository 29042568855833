import React, { useState, useEffect } from 'react';
import { toast } from 'react-toastify';
import { Link } from 'react-router-dom';
import ReactTable from '../../components/ReactTable';
import coloricon from '../../styles/coloricon';
import Loading from '../../components/Loading';
import BreadcrumbsMUI from '../../components/BreadcrumbsMUI';
import api from '../../services/api';
import { IconArea } from './styles';

export default function RegistrationPlayers() {
  const [events, setEvents] = useState([]);
  const [loading, setLoading] = useState(true);
  const { IconLogin } = coloricon;

  useEffect(() => {
    async function loaderRegistration() {
      try {
        const response = await api.get('/registrationsPlayer');
        if (response.data.length > 0) {
          const eventList = response.data.map((item) => {
            const data = {
              id: item.id,
              registration_number: item.registration_number,
              category: item.tuition?.category.name || 'Não encontrado',
              event: item.tuition?.event.name || 'Não encontrado',
            };
            return data;
          });
          setEvents(eventList);
          setLoading(false);
        }
        setLoading(false);
      } catch (error) {
        setLoading(false);
        toast.info(error.response.data.error || 'Falha no carregamento!');
      }
    }
    loaderRegistration();
  }, []);

  return loading ? (
    <Loading />
  ) : (
    <>
      <BreadcrumbsMUI
        nameOfCurrentPage="Inscrições"
        arrayOfTitleAndPath={[{ title: 'Início', path: '/dashPlayer' }]}
      />
      {events && events.length === 0 && (
        <p style={{ marginTop: 30 }}>Você não possui inscrições realizadas.</p>
      )}
      {events && events.length > 0 && (
        <>
          <div style={{ marginTop: 30 }} />
          <ReactTable
            data={events}
            columns={[
              { Header: 'Número', accessor: 'registration_number' },
              {
                Header: 'Categoria',
                accessor: 'category',
                disableFilters: true,
              },
              { Header: 'Evento', accessor: 'event' },
              {
                Header: 'Detalhes',
                Cell: ({ row }) => (
                  <IconArea>
                    <Link to={`/registrationsPlayerDetails/${row.original.id}`}>
                      <IconLogin />
                    </Link>
                  </IconArea>
                ),
              },
            ]}
          />
        </>
      )}
    </>
  );
}
