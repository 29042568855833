import React from 'react';
import { Link } from 'react-router-dom';
import { Banner, BannerArea, Content } from './styles';

export default function BannerHome() {
  return (
    <Banner>
      <BannerArea>
        <h1>Conheça nossos patrocinadores</h1>
        <Content>
          <Link to="/">
            <h2>Brutus Roupas</h2>
            <p>Encontre roupas para seu evento!</p>
          </Link>

          <Link to="/">
            <h2>Mercado 3 irmãos</h2>
            <p>Os melhores preços para você e sua família!</p>
          </Link>
        </Content>
      </BannerArea>
    </Banner>
  );
}
