import React, { useState, useCallback, useEffect } from 'react';
import { toast } from 'react-toastify';
import { useParams, useLocation } from 'react-router-dom';
import Button from '../../components/Button';
import Footer from '../../components/Footer';
import Header from '../../components/Header';
import Loading from '../../components/Loading';
import {
  InfoSecondary,
  ActionArea,
  FileName,
  ButtonView,
  Title,
  Container,
} from './styles';
import api from '../../services/api';
import coloricon from '../../styles/coloricon';

export default function Folder() {
  const { idEvent, eventName } = useParams();
  const location = useLocation();
  const [loading, setLoading] = useState(true);
  const [folder, setFolder] = useState({});
  const { IconDownload, IconSearch } = coloricon;

  useEffect(() => {
    async function loaderEvent() {
      try {
        const response = await api.get(`/foldersHome/${idEvent}`);
        setFolder(response.data);
        setLoading(false);
      } catch (error) {
        setLoading(false);
        toast.info(
          error?.response.data.error || 'Falha ao carregaras informações!'
        );
      }
    }
    loaderEvent();
  }, [idEvent, location?.key]);

  const handleDownloadByUrl = useCallback(() => {
    // get url from file
    const urlFile = folder.url;
    const nameFile = folder.name;

    fetch(urlFile, {
      mode: 'no-cors', //! propriedade que permite fazer o download
      method: 'get',
      headers: {
        'Content-Type': 'application/json',
      },
    })
      .then((response) => response.blob())
      .then((blob) => {
        const blobURL = window.URL.createObjectURL(new Blob([blob]));
        // Create an anchor element
        const link = document.createElement('a');

        // Set the href attribute to the image URL
        link.href = blobURL;

        // Set the download attribute with the desired filename
        link.setAttribute('download', nameFile);

        // Append the anchor element to the document
        document.body.appendChild(link);

        // Trigger a click event on the anchor element to start the download
        link.click();
        link.remove();
      });
  }, [folder]);

  return loading === true ? (
    <Loading />
  ) : (
    <>
      <Header />

      <Container>
        {!folder && (
          <InfoSecondary>Esse evento ainda não possui folder!</InfoSecondary>
        )}

        {folder && <Title>{eventName}</Title>}

        {folder && folder?.name && <FileName>Nome: {folder?.name}</FileName>}

        {folder && (
          <ActionArea>
            <Button type="button" onClick={() => handleDownloadByUrl()}>
              <div>
                <IconDownload />
                <p>Baixar</p>
              </div>
            </Button>

            <ButtonView
              href={folder.url}
              without="true"
              rel="noopener noreferrer"
              target="_blank"
            >
              <IconSearch />
              Visualizar
            </ButtonView>
          </ActionArea>
        )}
      </Container>

      <Footer />
    </>
  );
}
