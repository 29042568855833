import React, { useState, useEffect } from 'react';
import { parseISO, format } from 'date-fns';
import { pt } from 'date-fns/locale';
import { toast } from 'react-toastify';
import { useNavigate, useParams } from 'react-router-dom';
import Button from '../../components/Button';
import api from '../../services/api';
import Loading from '../../components/Loading';
import BreadCrumbs from '../../components/BreadcrumbsMUI';
import { GroupCategory, GroupCategoryItem, DetailsArea } from './styles';

export default function EventDetailsPlayer() {
  const { idEvent } = useParams();
  const [event, setEvent] = useState({});
  const [loading, setLoading] = useState(true);

  const navigate = useNavigate();

  useEffect(() => {
    async function loaderEvent() {
      try {
        const response = await api.get(`/events/${idEvent}`);
        setEvent({
          ...response.data,
          date_start: format(
            parseISO(response.data.date_start),
            'dd-MM-yyyy HH:mm',
            { locale: pt }
          ),
          date_end: format(
            parseISO(response.data.date_end),
            'dd-MM-yyyy HH:mm',
            { locale: pt }
          ),
        });
        setLoading(false);
      } catch (error) {
        toast.info(
          error?.response?.data.error || 'Falha no carregamento da página!'
        );
        setLoading(false);
      }
    }
    loaderEvent();
  }, [idEvent]);

  return loading === true ? (
    <Loading />
  ) : (
    <>
      <BreadCrumbs
        nameOfCurrentPage="Categorias"
        arrayOfTitleAndPath={[
          {
            title: 'Início',
            path: '/dashManager',
          },
          {
            title: 'Eventos',
            path: '/classification',
          },
        ]}
      />
      <DetailsArea>
        <h2>Detalhes do evento</h2>
        <h1>Nome: {event.name}</h1>
        <h1>
          Endereço: {event.street} , {event.number} , {event.district} ,{' '}
          {event.complement ? event.complement : 'Sem complemento'},{' '}
          {event.city} - {event.state} , {event.zipcode}.
        </h1>
      </DetailsArea>
      <GroupCategory>
        {event.category.map((cat) => (
          <GroupCategoryItem key={cat.id}>
            <h1>Categoria {cat.name}</h1>
            <Button
              type="button"
              onClick={() =>
                navigate({
                  pathname: `/racings/${idEvent}/${cat.id}`,
                })
              }
            >
              Tempos
            </Button>
            <div style={{ marginTop: 15 }} />
            <Button
              type="button"
              onClick={() =>
                navigate(`/pointsOfRacing/${idEvent}/${cat.id}`, {
                  state: {
                    eventName: event.name,
                    categoryName: cat.name,
                  },
                })
              }
            >
              Pontuação
            </Button>
          </GroupCategoryItem>
        ))}
      </GroupCategory>
    </>
  );
}
