import React, { useState, useCallback, useEffect } from 'react';
import { toast } from 'react-toastify';
import { format, parseISO } from 'date-fns';
import { pt } from 'date-fns/locale';
import * as Yup from 'yup';
import { useNavigate, useParams } from 'react-router-dom';
import { ButtonArea, ConfirmationArea } from './styles';
import Button from '../../components/Button';
import Loading from '../../components/Loading';
import BreadCrumbs from '../../components/BreadcrumbsMUI';
import api from '../../services/api';
import { formatPrice } from '../../utils/format';

export default function ConfirmationRegistrationByManager() {
  const { idEvent, idCategory, idPlayer, representation, idAnimal } =
    useParams();
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();
  const [dataConfirmation, setDataConfirmation] = useState({});

  useEffect(() => {
    async function loaderAnimal() {
      try {
        const response = await api.get(
          `/prepareDataForConfirmationByManager/${idEvent}/${idCategory}/${idPlayer}/${idAnimal}`
        );
        setDataConfirmation(response.data);
        setLoading(false);
      } catch (error) {
        setLoading(false);
        toast.info(error.response.data.error || 'Falha no carregamento!');
      }
    }
    loaderAnimal();
  }, [idAnimal, idCategory, idEvent, idPlayer]);

  const handleSubmit = useCallback(async () => {
    const data = {
      event_id: idEvent,
      category_id: idCategory,
      player_id: idPlayer,
      representation,
      animal_id: idAnimal,
    };

    try {
      const schemaValidation = Yup.object().shape({
        representation: Yup.string()
          .required('A representação é obrigatória!')
          .min(3, 'A representação precisa mínimo 3 caracteres!')
          .max(75, 'A representação suporta máximo 75 caracteres!'),

        animal_id: Yup.string().required('O animal é obrigatório!'),

        player_id: Yup.string().required('O ID do competidor é obrigatório!'),

        event_id: Yup.string().required('O ID do evento é obrigatório!'),
        category_id: Yup.string().required('O ID da categoria é obrigatório!'),
      });

      await schemaValidation.validate(data, {
        abortEarly: false,
      });

      const registration = await api.post(
        '/confirmationRegistrationByManager',
        data
      );

      navigate(`/confirmationSuccessByManager`, {
        state: {
          registration_number: registration.data.registration_number,
          tuition: registration.data.default_value,
        },
      });
    } catch (err) {
      if (err instanceof Yup.ValidationError) {
        const errorMessages = {};
        err.inner.forEach((error) => {
          errorMessages[error.path] = error.message;
        });
        errorMessages.forEach((message) => {
          toast.info(message || 'Falha no envio das informações!');
        });
      } else {
        toast.info(err?.response.data.message || 'Falha no processamento!');
      }
    }
  }, [idAnimal, idCategory, idEvent, navigate, representation, idPlayer]);

  return loading ? (
    <Loading />
  ) : (
    <>
      <BreadCrumbs
        nameOfCurrentPage="Confirmação"
        arrayOfTitleAndPath={[
          {
            title: 'Início',
            path: '/dashManager',
          },
          {
            title: 'Inscrições',
            path: '/eventsByManager',
          },
          {
            title: 'Detalhes',
            path: `/eventDetailsManager/${idEvent}`,
          },
          {
            title: 'Competidores',
            path: `/registrationByManager/${idEvent}/${idCategory}`,
          },
          {
            title: 'Dados',
            path: `/registrationByManagerNextStep/${idEvent}/${idCategory}/${idPlayer}`,
          },
        ]}
      />
      <ConfirmationArea>
        <h2>Confira os detalhes</h2>
        <h1>Competidor: {dataConfirmation.data.player.name}</h1>
        <h1>Nickname: {dataConfirmation.data.player.nickname}</h1>

        <h1>Evento: {dataConfirmation.data.event.name}</h1>
        <h1>
          Endereço:
          {dataConfirmation.data.event.street},
          {dataConfirmation.data.event.number},
          {dataConfirmation.data.event.complement},
          {dataConfirmation.data.event.district},
          {dataConfirmation.data.event.city},{dataConfirmation.data.event.state}
          ,
        </h1>
        <h1>
          Data:{' '}
          {format(
            parseISO(dataConfirmation.data.event.date_start),
            'dd/MM/yyyy HH:mm',
            {
              locale: pt,
            }
          )}
          -
          {format(
            parseISO(dataConfirmation.data.event.date_end),
            'dd/MM/yyyy HH:mm',
            {
              locale: pt,
            }
          )}
        </h1>

        <h1>Categoria: {dataConfirmation.data.category.name}</h1>
        <h1>
          Valor inscrição:{' '}
          {formatPrice(dataConfirmation.data.category?.tuition[0]?.price)}
        </h1>

        <h1>Animal: {dataConfirmation.data.animal.name}</h1>
      </ConfirmationArea>
      <ButtonArea>
        <Button type="button" onClick={handleSubmit}>
          Confirmar inscrição
        </Button>
      </ButtonArea>
    </>
  );
}
