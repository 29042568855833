import styled from 'styled-components';
import banner from '../../assets/banner-racing-1.jpg';
import coloricon from '../../styles/coloricon';

const { secondaryColor, backgroundHeader } = coloricon;

const imageChoosed = banner;

export const Banner = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background: url(${imageChoosed}) no-repeat center;
  width: 100%;
  min-height: calc(100vh - 80px - 100px);
  background-size: cover;
`;

export const BannerArea = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: rgba(0, 0, 0, 0.8);
  width: 100%; //heranca
  min-height: calc(100vh - 80px - 100px);

  @media (max-width: 830px) {
    font-size: 14px;
  }
`;

export const Content = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  max-width: 900px;
  padding: 20px;

  @media (max-width: 768px) {
    font-size: 15px;
  }

  @media (max-width: 600px) {
    flex-direction: column;
  }

  @media (max-width: 425px) {
    font-size: 14px;
  }

  @media (max-width: 375px) {
    font-size: 13px;
  }

  @media (max-width: 320px) {
    font-size: 12px;
  }

  a {
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    background-color: transparent;
    padding: 20px;
    border-radius: 4px;
    transition: all 0.4s ease-in-out;

    :hover {
      background-color: ${backgroundHeader};
      transform: scale(1.02);
    }

    h2 {
      color: ${secondaryColor};
      text-transform: uppercase;
      margin-bottom: 15px;
    }
  }
`;
