import React, { useState, useEffect } from 'react';
import { toast } from 'react-toastify';
import Loading from '../../components/Loading';
import BreadCrumbs from '../../components/BreadcrumbsMUI';
import api from '../../services/api';
import ReactTable from '../../components/ReactTable';
import { GroupButton } from './styles';
import ExportCSV from '../../utils/ExportCSV';

export default function PlayerByManager() {
  const [loading, setLoading] = useState(true);
  const [players, setPlayers] = useState([]);
  const [playersForDownload, setPlayerForDownload] = useState([]);

  const fieldNames = {
    nickname: 'apelido',
    city: 'cidade',
    animal: 'animal',
    category: 'categoria',
    representation: 'patrocinador',
  };

  useEffect(() => {
    async function loaderPlayers() {
      try {
        const response = await api.get('/playersByManager');
        setPlayers(response.data.players);
        setPlayerForDownload(response.data.playersForDownload);
        setLoading(false);
      } catch (error) {
        setLoading(false);
        toast.info(error?.response.data.error || 'Falha no carregamento!');
      }
    }
    loaderPlayers();
  }, []);

  return loading === true ? (
    <Loading />
  ) : (
    <>
      <BreadCrumbs
        nameOfCurrentPage="Competidores"
        arrayOfTitleAndPath={[
          {
            title: 'Início',
            path: '/dashManager',
          },
        ]}
      />
      {players && players.length === 0 && (
        <p>Não existem registros para exibir.</p>
      )}

      {players && players.length > 0 && (
        <>
          <GroupButton>
            <div>
              <ExportCSV
                fileName="dados_competidores"
                csvData={playersForDownload}
                fieldNames={fieldNames}
              />
            </div>
          </GroupButton>
          <ReactTable
            data={players}
            columns={[
              {
                Header: 'Apelido',
                accessor: 'nickname',
              },
              {
                Header: 'Patrocinador',
                accessor: 'representation',
              },
              { Header: 'Animal', accessor: 'animal' },
              { Header: 'Categoria', accessor: 'category' },
              { Header: 'Cidade', accessor: 'city' },
            ]}
          />
        </>
      )}
    </>
  );
}
