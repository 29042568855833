import React from 'react';
import { ToastContainer } from 'react-toastify';
import { BsX } from 'react-icons/bs';
import { BrowserRouter as Router } from 'react-router-dom';
import { FiAlertTriangle } from 'react-icons/fi';
import Routes from './routes';
import GlobalStyle from './styles/global';
import { AuthProvider } from './context/AuthContext';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import coloricon from './styles/coloricon';

const { fontColor, secondaryColorHover } = coloricon;

const CloseButton = ({ closeToast }) => (
  <BsX size={22} color={fontColor} onClick={closeToast} />
);

function App() {
  return (
    <>
      <AuthProvider>
        <Router>
          <Routes />
        </Router>
      </AuthProvider>
      <GlobalStyle />
      <ToastContainer
        toastStyle={{
          color: fontColor,
          backgroundColor: secondaryColorHover,
          borderRadius: '4px',
          width: 'auto',
        }}
        closeButton={CloseButton}
        autoClose={2000}
        position="bottom-right"
        hideProgressBar
        icon={<FiAlertTriangle color={fontColor} size={20} />}
      />
    </>
  );
}

export default App;
