import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  > div {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;

    button {
      width: 100%;
      max-width: 900px;
      margin: 10px;
    }
  }
`;

export const AlertFinished = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin-bottom: 15px;

  > svg {
    font-size: 30px;
    margin-right: 15px;
    color: red;
  }

  > h2 {
    color: red;
    font-size: 1.3em;
  }
`;
