import React, { useState, useCallback } from 'react';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';
import { GroupInfo } from './styles';
import Button from '../../components/Button';
import Modal from '../../components/Modal';
import api from '../../services/api';
import BreadcrumbsMUI from '../../components/BreadcrumbsMUI';

export default function CloseAccountPlayer() {
  const [openModal, setOpenModal] = useState(false);
  const navigation = useNavigate();

  const handleClose = useCallback(async () => {
    const response = await api.delete('/players');
    toast.info(response.data.message);
    navigation({ pathname: '/' });
  }, [navigation]);

  return (
    <>
      <BreadcrumbsMUI
        nameOfCurrentPage="Desativar conta"
        arrayOfTitleAndPath={[
          {
            title: 'Início',
            path: '/dashPlayer',
          },
        ]}
      />
      <GroupInfo>
        <Button type="button" onClick={() => setOpenModal(true)}>
          Desativar conta
        </Button>
      </GroupInfo>

      <Modal
        title="Confirma a desativação da conta?"
        isOpen={openModal}
        onClickClose={() => setOpenModal(false)}
        onClickAction={handleClose}
      />
    </>
  );
}
