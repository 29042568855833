import React, { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { Link } from 'react-router-dom';
import Loading from '../../components/Loading';
import BreadCrumbs from '../../components/BreadcrumbsMUI';
import api from '../../services/api';
import ReactTable from '../../components/ReactTable';
import coloricon from '../../styles/coloricon';
import { IconArea } from './styles';

export default function EventsByPlayer() {
  const [loading, setLoading] = useState(true);
  const { IconLogin } = coloricon;
  const [events, setEvents] = useState([]);

  useEffect(() => {
    async function loaderEvents() {
      try {
        const response = await api.get('/events');
        setEvents(response.data);
        setLoading(false);
      } catch (error) {
        setLoading(false);
        toast.info(error.response.data.error);
      }
    }
    loaderEvents();
  }, []);

  return loading === true ? (
    <Loading />
  ) : (
    <>
      <BreadCrumbs
        nameOfCurrentPage="Inscrições"
        arrayOfTitleAndPath={[
          {
            title: 'Início',
            path: '/dashManager',
          },
        ]}
      />
      {events && events.length === 0 && (
        <p>Não existem registros para exibir.</p>
      )}

      {events && events.length > 0 && (
        <>
          <div style={{ marginTop: 20 }} />
          <ReactTable
            data={events}
            columns={[
              { Header: 'Nome', accessor: 'name', width: '70%' },
              {
                Header: 'Início',
                accessor: 'date_start',
                disableFilters: true,
              },
              { Header: 'Cidade', accessor: 'city', disableFilters: true },
              { Header: 'UF', accessor: 'state', disableFilters: true },
              {
                Header: 'Inscrição',
                Cell: ({ row }) => (
                  <IconArea>
                    <Link to={`/eventDetailsManager/${row.original.id}`}>
                      <IconLogin />
                    </Link>
                  </IconArea>
                ),
              },
            ]}
          />
        </>
      )}
    </>
  );
}
