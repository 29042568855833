import React, { useRef, useCallback } from 'react';
import { toast } from 'react-toastify';
import * as Yup from 'yup';
import { Form } from '@unform/web';
import { useNavigate } from 'react-router-dom';
import { GroupForm } from './styles';
import Button from '../../components/Button';
import Input from '../../components/Input';
import api from '../../services/api';
import BreadCrumbs from '../../components/BreadcrumbsMUI';
import coloricon from '../../styles/coloricon';

export default function AnimalCreate() {
  const formRef = useRef(null);
  const { IconDocument } = coloricon;
  const navigate = useNavigate();

  const handleSubmit = useCallback(
    async (data) => {
      try {
        const schemaValidation = Yup.object().shape({
          name: Yup.string()
            .required('O nome é obrigatório!')
            .min(3, 'O nome precisa mínimo 3 caracteres!')
            .max(75, 'O nome suporta máximo 75 caracteres!'),
        });

        await schemaValidation.validate(data, {
          abortEarly: false,
        });

        formRef.current.setErrors({});

        await api.post('/animals', data);

        toast.info('O animal foi cadastrado com sucesso!');
        navigate({ pathname: '/animalManager' });
      } catch (err) {
        if (err instanceof Yup.ValidationError) {
          const errorMessages = {};
          err.inner.forEach((error) => {
            errorMessages[error.path] = error.message;
          });
          formRef.current.setErrors(errorMessages);
        } else {
          toast.info(err.response.data.error);
        }
      }
    },
    [navigate]
  );

  return (
    <>
      <BreadCrumbs
        nameOfCurrentPage="Adicionar animal"
        arrayOfTitleAndPath={[
          { title: 'Início', path: '/dashPlayer' },
          { title: 'Animais', path: '/animalManager' },
        ]}
      />
      <GroupForm>
        <Form ref={formRef} onSubmit={handleSubmit}>
          <Input
            name="name"
            icon={IconDocument}
            type="text"
            placeholder="Nome do animal"
            required
          />

          <Button type="submit">Cadastrar</Button>
        </Form>
      </GroupForm>
    </>
  );
}
