import React, { useState, useEffect } from 'react';
import { toast } from 'react-toastify';
import { Link } from 'react-router-dom';
import api from '../../services/api';
import coloricon from '../../styles/coloricon';
import ReactTable from '../../components/ReactTable';
import Loading from '../../components/Loading';
import BreadCrumbs from '../../components/BreadcrumbsMUI';
import { IconArea } from './styles';

export default function PlayerByManager() {
  const [loading, setLoading] = useState(true);
  const [players, setPlayers] = useState([]);
  const { IconLogin } = coloricon;

  useEffect(() => {
    async function loaderPlayers() {
      try {
        const response = await api.get('/playersByManager');
        setPlayers(response.data.players);
        setLoading(false);
      } catch (error) {
        setLoading(false);
        toast.info(error?.response.data.error || 'Falha no carregamento!');
      }
    }
    loaderPlayers();
  }, []);

  return loading === true ? (
    <Loading />
  ) : (
    <>
      <BreadCrumbs
        nameOfCurrentPage="Animais"
        arrayOfTitleAndPath={[
          {
            title: 'Início',
            path: '/dashManager',
          },
        ]}
      />
      {players && players.length === 0 && (
        <p>Não existem registros para exibir.</p>
      )}

      {players && players.length > 0 && (
        <>
          <div style={{ marginTop: 30 }} />
          <ReactTable
            data={players}
            columns={[
              {
                Header: 'Nome',
                accessor: 'name',
                width: '70%',
              },
              {
                Header: 'Apelido',
                accessor: 'nickname',
              },
              { Header: 'Celular', accessor: 'phone' },
              { Header: 'Email', accessor: 'email' },
              {
                Header: 'Inscrição',
                Cell: ({ row }) => (
                  <IconArea>
                    <Link to={`/animalManagerByManager/${row.original.id}`}>
                      <IconLogin />
                    </Link>
                  </IconArea>
                ),
              },
            ]}
          />
        </>
      )}
    </>
  );
}
