import styled from 'styled-components';
import coloricon from '../../styles/coloricon';

const { fontColor, secondaryColor } = coloricon;

export const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: calc(100vh - 80px - 100px);
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 900px;
  padding: 20px 30px;
  align-items: center;
  text-align: center;

  > h1 {
    text-align: center;
    margin: 20px 0px;
    color: ${secondaryColor};
    font-size: 1.5em;
    font-weight: bold;
  }

  > h2 {
    text-align: center;
    margin: 20px 0px;
    font-size: 1.3em;
    color: ${fontColor};
  }

  > h3 {
    text-align: center;
    margin: 20px 0px;
    font-size: 1.1em;
    color: ${fontColor};
    line-height: 40px;
  }
`;
