import React, { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { ItemResume, ResumeArea } from './styles';
import BreadCrumbs from '../../components/BreadcrumbsMUI';
import api from '../../services/api';
import Loading from '../../components/Loading';

export default function DashCompany() {
  const [resume, setResume] = useState({});
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    async function loaderResume() {
      try {
        const response = await api.get('/resume');
        setResume(response.data);
        setLoading(false);
      } catch (error) {
        toast.info(
          error?.response.data.message || 'Falha ao carregar as informações'
        );
        setLoading(false);
      }
    }
    loaderResume();
  }, []);

  return loading ? (
    <Loading />
  ) : (
    <>
      <BreadCrumbs
        nameOfCurrentPage="Resumo"
        arrayOfTitleAndPath={[
          {
            title: 'Início',
            path: '/dashManager',
          },
        ]}
      />
      {!resume && <h1>Não existem registros para exibir.</h1>}
      <ResumeArea>
        <ItemResume>
          <h1>Eventos</h1>
          <h2>Total: {resume.totalEvents}</h2>
          <h3>
            Andamento: {resume.totalOpenedEvents} -{' '}
            {Number(
              (resume.totalOpenedEvents / resume.totalEvents) * 100
            ).toFixed(2)}
            %
          </h3>
          <h3>
            Finalizados: {resume.totalFinishedEvents} -{' '}
            {Number(
              (resume.totalFinishedEvents / resume.totalEvents) * 100
            ).toFixed(2)}
            %
          </h3>
        </ItemResume>
        <ItemResume>
          <h1>Financeiro</h1>
          <h2>Total: {resume.totalAmountRegistrationsFormatted}</h2>
          <h3>
            Pagos: {resume.totalAmountPaidRegistrationsFormatted} -{' '}
            {Number(
              (resume.totalAmountPaidRegistrations /
                resume.totalAmountRegistrations) *
                100
            ).toFixed(2)}
            %
          </h3>
          <h3>
            Pendente: {resume.totalAmountNoPaidRegistrationsFormatted} -{' '}
            {Number(
              (resume.totalAmountNoPaidRegistrations /
                resume.totalAmountRegistrations) *
                100
            ).toFixed(2)}
            %
          </h3>
        </ItemResume>

        <ItemResume>
          <h1>Competidores</h1>
          <h2>Total: {resume.totalPlayers}</h2>
          <h3>
            Homens: {resume.totalMenPlayers} -{' '}
            {Number(
              (resume.totalMenPlayers / resume.totalPlayers) * 100
            ).toFixed(2)}
            %
          </h3>
          <h3>
            Mulheres: {resume.totalGirlsPlayers} -{' '}
            {Number(
              (resume.totalGirlsPlayers / resume.totalPlayers) * 100
            ).toFixed(2)}
            %
          </h3>
        </ItemResume>
      </ResumeArea>
    </>
  );
}
