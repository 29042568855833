import React, { useState, useEffect } from 'react';
import { parseISO, format } from 'date-fns';
import { pt } from 'date-fns/locale';
import { toast } from 'react-toastify';
import { useNavigate, useParams } from 'react-router-dom';
import Loading from '../../components/Loading';
import Footer from '../../components/Footer';
import Header from '../../components/Header';
import Button from '../../components/Button';
import api from '../../services/api';
import { Container, Content, GroupCategory, GroupCategoryItem } from './styles';
import { formatPrice } from '../../utils/format';

export default function EventDetailsHomePage() {
  const { idEvent } = useParams();
  const [event, setEvent] = useState({});
  const [loading, setLoading] = useState(true);

  const navigate = useNavigate();

  useEffect(() => {
    async function loaderEvent() {
      try {
        const response = await api.get(`/eventHomePageDetails/${idEvent}`);
        setEvent({
          ...response.data,
          date_start: format(
            parseISO(response.data.date_start),
            'dd-MM-yyyy HH:mm',
            { locale: pt }
          ),
          date_end: format(
            parseISO(response.data.date_end),
            'dd-MM-yyyy HH:mm',
            { locale: pt }
          ),
        });
        setLoading(false);
      } catch (error) {
        toast.info(
          error?.response?.data.error || 'Falha no carregamento da página!'
        );
        setLoading(false);
      }
    }
    loaderEvent();
  }, [idEvent]);

  return loading === true ? (
    <Loading />
  ) : (
    <>
      <Header />
      <Container>
        <Content>
          <h1>Detalhes do evento</h1>
          <h1>Nome: {event.name}</h1>
          <h1>Data início: {event.date_start}</h1>
          <h1>Data término: {event.date_end}</h1>
          <h1>
            Endereço: {event.street} , {event.number} , {event.district} ,{' '}
            {event.complement ? event.complement : 'Sem complemento'},{' '}
            {event.city} - {event.state} , {event.zipcode}.
          </h1>
          <GroupCategory>
            {event.category.map((cat) => (
              <GroupCategoryItem key={cat.id}>
                <h4>Categoria {cat.name}</h4>
                <h2>
                  Inscrição: {formatPrice(Number(cat?.tuition[0]?.price))}
                </h2>
                {/* precisa quebrar os eventos em ; */}
                <h3>Prêmios</h3>
                {cat?.tuition[0]?.award.split(';').map((item) => (
                  <h1 key={item}>{item}</h1>
                ))}

                <Button
                  type="button"
                  onClick={() =>
                    navigate({ pathname: `/registration/${idEvent}/${cat.id}` })
                  }
                >
                  Inscrição
                </Button>
              </GroupCategoryItem>
            ))}
          </GroupCategory>
        </Content>
      </Container>
      <Footer />
    </>
  );
}
