import React, { useRef, useState, useCallback, useEffect } from 'react';
import { toast } from 'react-toastify';
import { Form } from '@unform/web';
import * as Yup from 'yup';
import { useNavigate, useParams } from 'react-router-dom';
import { ButtonArea } from './styles';
import coloricon from '../../styles/coloricon';
import Input from '../../components/Input';
import Button from '../../components/Button';
import Loading from '../../components/Loading';
import BreadCrumbs from '../../components/BreadcrumbsMUI';
import SelectInput from '../../components/SelectInput';
import api from '../../services/api';

export default function Registration() {
  const formRef = useRef(null);
  const { idEvent, idCategory } = useParams();
  const [loading, setLoading] = useState(true);
  const { IconUser, IconHorse } = coloricon;
  const navigate = useNavigate();
  const [animals, setAnimals] = useState([]);

  useEffect(() => {
    async function loaderAnimal() {
      try {
        const response = await api.get('/animals');
        if (response.data.length > 0) {
          const animalOptions = response.data.map((anim) => {
            const item = {
              id: anim.id,
              value: anim.id,
              label: anim.name,
              selected: false,
            };
            return item;
          });
          setAnimals(animalOptions);
          setLoading(false);
        }
        setLoading(false);
      } catch (error) {
        setLoading(false);
        toast.info(error.response.data.error || 'Falha no carregamento!');
      }
    }
    loaderAnimal();
  }, []);

  const handleSubmit = useCallback(
    async (data) => {
      const dataFormatted = {
        ...data,
        event_id: idEvent,
        category_id: idCategory,
      };
      try {
        const schemaValidation = Yup.object().shape({
          representation: Yup.string()
            .required('A representação é obrigatória!')
            .min(3, 'A representação precisa mínimo 3 caracteres!')
            .max(75, 'A representação suporta máximo 75 caracteres!'),

          animal_id: Yup.string().required('O animal é obrigatório!'),

          event_id: Yup.string().required('O ID do evento é obrigatório!'),
          category_id: Yup.string().required(
            'O ID da categoria é obrigatório!'
          ),
        });

        await schemaValidation.validate(dataFormatted, {
          abortEarly: false,
        });

        formRef.current.setErrors({});

        navigate({
          pathname: `/confirmationRegistration/${idEvent}/${idCategory}/${data.representation}/${data.animal_id}`,
        });
      } catch (err) {
        if (err instanceof Yup.ValidationError) {
          const errorMessages = {};

          err.inner.forEach((error) => {
            errorMessages[error.path] = error.message;
          });

          formRef.current.setErrors(errorMessages);
        } else {
          toast.info(err.response.data.error);
        }
      }
    },
    [navigate, idCategory, idEvent]
  );

  return loading ? (
    <Loading />
  ) : (
    <>
      <BreadCrumbs
        nameOfCurrentPage="Inscrição"
        arrayOfTitleAndPath={[
          {
            title: 'Início',
            path: '/dashPlayer',
          },
          {
            title: 'Eventos',
            path: `/eventsByPlayer`,
          },
          {
            title: 'Detalhes',
            path: `/eventDetailsPlayer/${idEvent}`,
          },
        ]}
      />
      {animals && animals.length === 0 && (
        <p style={{ textAlign: 'center', marginTop: 25 }}>
          Você precisa cadastrar um animal para participar da competição.
        </p>
      )}
      {animals && animals.length > 0 && (
        <Form ref={formRef} onSubmit={handleSubmit} style={{ marginTop: 30 }}>
          <Input
            icon={IconUser}
            name="representation"
            type="text"
            placeholder="Patrocinador"
            required
            inputProps={{ maxLength: 75, size: 75 }}
          />

          <SelectInput
            options={animals}
            name="animal_id"
            placeholder="Animal"
            icon={IconHorse}
          />
          <ButtonArea>
            <Button type="submit">Enviar</Button>
          </ButtonArea>
        </Form>
      )}
    </>
  );
}
