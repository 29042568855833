import React, { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { useParams, useLocation } from 'react-router-dom';
import Loading from '../../components/Loading';
import ReactTable from '../../components/ReactTable';
import BreadCrumbs from '../../components/BreadcrumbsMUI';
import api from '../../services/api';
import { TitleDetails } from './styles';

export default function PointsOfRacing() {
  const [points, setPoints] = useState([]);
  const [loading, setLoading] = useState(true);
  const { idEvent, idCategory } = useParams();
  const { state } = useLocation();

  useEffect(() => {
    async function loaderPoints() {
      try {
        const response = await api.get(
          `/preparePointsOfBattleByCategory/${idEvent}/${idCategory}`
        );
        setPoints(response.data);
        setLoading(false);
      } catch (error) {
        toast.info(
          error?.response?.data.error ||
            'Falha no carregamento das informações!'
        );
        setLoading(false);
      }
    }
    loaderPoints();
  }, [idCategory, idEvent]);

  return loading === true ? (
    <Loading />
  ) : (
    <>
      <BreadCrumbs
        nameOfCurrentPage="Pontuação Categoria"
        arrayOfTitleAndPath={[
          {
            title: 'Início',
            path: '/dashManager',
          },
          {
            title: 'Eventos',
            path: '/classification',
          },
          {
            title: 'Categorias',
            path: `/classificationEventCategory/${idEvent}`,
          },
        ]}
      />
      <TitleDetails>
        {state.eventName} - {state.categoryName}
      </TitleDetails>
      {points.length === 0 && (
        <p style={{ marginTop: 20, textAlign: 'center' }}>
          Os dados dessa página estarão disponíveis quando concluir as etapas
          anteriores de pontuação!
        </p>
      )}

      {points.length > 0 && (
        <>
          <div style={{ marginTop: 20 }} />
          <ReactTable
            data={points}
            columns={[
              {
                Header: 'Nickname',
                accessor: 'nickname',
                disableFilters: true,
              },
              {
                Header: 'Animal',
                accessor: 'animal',
                disableFilters: true,
              },
              {
                Header: 'Patrocinador',
                accessor: 'representation',
                disableFilters: true,
              },
              {
                Header: '6 balizas',
                disableFilters: true,
                accessor: 'sixPolePoints',
              },
              {
                Header: '3 tambores',
                disableFilters: true,
                accessor: 'threeDrumPoints',
              },
              {
                Header: '5 tambores',
                disableFilters: true,
                accessor: 'fiveDrumPoints',
              },
              {
                Header: 'Total',
                disableFilters: true,
                accessor: 'totalPoints',
              },
              {
                Header: 'Nº',
                accessor: 'ranking',
                disableFilters: true,
              },
            ]}
          />
        </>
      )}
    </>
  );
}
