import React, { useRef, useState, useCallback, useEffect } from 'react';
import { isBefore, addDays } from 'date-fns';
import { Scope } from '@unform/core';
import axios from 'axios';
import { toast } from 'react-toastify';
import * as Yup from 'yup';
import { Form } from '@unform/web';
import { useNavigate } from 'react-router-dom';
import { unMask, mask } from 'remask';
import CheckBox from '../../components/CheckBoxInput';
import Button from '../../components/Button';
import DateInput from '../../components/DatePickerInput';
import Input from '../../components/Input';
import api from '../../services/api';
import Loading from '../../components/Loading';
import BreadCrumbs from '../../components/BreadcrumbsMUI';
import {
  GroupDate,
  GroupLink,
  Instructions,
  ButtonArea,
  InputsArea,
  GroupCheckBoxArea,
  CheckBoxArea,
} from './styles';
import coloricon from '../../styles/coloricon';

export default function EventCreate() {
  const formRef = useRef(null);
  const [start, setStart] = useState(new Date());
  const [maskedCep, setMaskedCep] = useState('');
  const [categories, setCategories] = useState([]);
  const [racings, setRacings] = useState([]);
  const [loading, setLoading] = useState(true);
  const [end, setEnd] = useState(addDays(new Date(), 1));
  const { IconMap, IconDocument, IconLocation, IconPrice } = coloricon;
  const navigate = useNavigate();

  useEffect(() => {
    async function loaderCategories() {
      await axios
        .all([await api.get('/categories'), await api.get('/racings')])
        .then(
          axios.spread((cat, rac) => {
            setCategories(cat.data);
            setRacings(rac.data);
            setLoading(false);
          })
        )
        .catch((error) => {
          setLoading(false);
          toast.info(
            error?.response.data.error ||
              'Falha no carregamento das informações!'
          );
        });
    }
    loaderCategories();
  }, []);

  const onChangeMaskCep = useCallback(async (event) => {
    const { value } = event.target;

    const originalValue = unMask(value);
    const masked = mask(originalValue, ['99999-999']);
    setMaskedCep(masked);

    if (value && value.length === 9) {
      const response = await fetch(
        `https://viacep.com.br/ws/${value}/json`
      ).then((result) => result.json());

      if (response) {
        formRef.current.setData({
          street: response.logradouro ? response.logradouro : null,
          complement: response.complemento ? response.complemento : null,
          district: response.bairro ? response.bairro : null,
          city: response.localidade ? response.localidade : null,
          state: response.uf ? response.uf : null,
          number: null,
        });
      }
    }
  }, []);

  const handleSubmit = useCallback(
    async (data) => {
      try {
        const schemaValidation = Yup.object().shape({
          name: Yup.string()
            .required('O nome é obrigatório!')
            .min(3, 'O nome precisa mínimo 3 caracteres!')
            .max(75, 'O nome suporta máximo 75 caracteres!'),

          date_start: Yup.date().required('A data início é obrigatória!'),

          date_end: Yup.date()
            .required('A data fim é obrigatória!')
            .min(new Date(), 'A mínima é a data de hoje!'),

          street: Yup.string().required('O endereço é obrigatório!'),

          number: Yup.number()
            .positive('O número precisa ser positivo!')
            .integer('O número precisa ser inteiro!')
            .required('O número é obrigatório!'),

          complement: Yup.string().when({
            is: (val) => val && val.length > 0,
            then: Yup.string().required('O complemento é obrigatório!'),
            otherwise: Yup.string().nullable(true),
          }),

          city: Yup.string().required('A cidade é obrigatória!'),
          state: Yup.string().required('O estado é obrigatório!'),
          zipcode: Yup.string().required('O cep é obrigatório!'),
        });

        await schemaValidation.validate(data, {
          abortEarly: false,
        });

        const category = [];
        Object.entries(data).forEach(([key, value]) => {
          // encontrando os campos de categorias
          const isCategory = key.includes('category');
          if (isCategory === true) {
            // se é um campo de categoria preciso organizar as racings

            const racingsEnabled = [];
            Object.entries(value).forEach(([keys, values]) => {
              const isRacing = keys.includes('racing');
              if (isRacing === true && values === true) {
                const arrayAfterSplit = keys.split('#');
                const dataRacing = {
                  id: arrayAfterSplit[1],
                  typeOfItem: arrayAfterSplit[0],
                  value: values,
                };
                racingsEnabled.push(dataRacing);
              }
            });
            const arrayAfterSplit = key.split('#');
            const prepareArray = {
              id: arrayAfterSplit[1],
              value: value.category,
              price: value.price,
              award: value.award,
              racing: racingsEnabled,
            };
            category.push(prepareArray);
          }
        });
        const schema = Yup.object().shape({
          category: Yup.array()
            .required('A categoria é obrigatória!')
            .min(1, 'Selecione no mínimo 1 categoria!'),
        });

        await schema.validate(
          { category },
          {
            abortEarly: false,
          }
        );

        formRef.current.setErrors({});

        const checkDate = isBefore(data.date_start, data.date_end);
        if (checkDate === false) {
          toast.info('A data inicial não pode ser depois da data final!');
          return;
        }
        // requer no mínimo uma categoria selecionada
        const hasMinOneCategorySelected = category.some(
          (cat) => cat.value === true
        );
        if (!hasMinOneCategorySelected) {
          toast.info(
            'É preciso selecionar no mínimo uma categoria pra o evento'
          );
          return;
        }

        // precisa categoria, preço da inscricao e racing cadastrada

        const hasMissingPriceInCategorySelected = category.some(
          (cat) =>
            (cat.value === true && !cat.price) ||
            (cat.value === false && cat.price) ||
            (cat.value === true && !cat.award) ||
            (cat.value === false && cat.award) ||
            (cat.value === true && cat.racing.length === 0) ||
            (cat.value === false && cat.racing.length > 0)
        );
        if (hasMissingPriceInCategorySelected) {
          toast.info(
            'É preciso informar categoria, valor da inscrição, premiação e corridas!'
          );
          return;
        }

        await api.post('/events', { ...data, categories: category });

        toast.info('O evento foi cadastrado com sucesso!');
        navigate({ pathname: '/eventManager' });
      } catch (err) {
        if (err instanceof Yup.ValidationError) {
          const errorMessages = {};
          err.inner.forEach((error) => {
            errorMessages[error.path] = error.message;
          });
          formRef.current.setErrors(errorMessages);
        } else {
          toast.info(
            err?.response.data.error || 'Falha no envio das informações!'
          );
        }
      }
    },
    [navigate]
  );

  return loading === true ? (
    <Loading />
  ) : (
    <>
      <BreadCrumbs
        nameOfCurrentPage="Adicionar evento"
        arrayOfTitleAndPath={[
          {
            title: 'Início',
            path: '/dashManager',
          },
          {
            title: 'Eventos',
            path: '/eventManager',
          },
        ]}
      />

      <Form ref={formRef} onSubmit={handleSubmit}>
        <GroupDate>
          <span>Início:</span>

          <div>
            <DateInput
              name="date_start"
              timeIntervals={15}
              selected={start}
              onChange={(date) => setStart(date)}
              showTimeSelect
              dateFormat="dd/MM/yyyy,HH:mm aa"
            />
          </div>

          <span>Fim:</span>

          <DateInput
            name="date_end"
            timeIntervals={15}
            selected={end}
            onChange={(date) => setEnd(date)}
            showTimeSelect
            dateFormat="dd/MM/yyyy,HH:mm aa"
          />
        </GroupDate>

        <Input
          name="name"
          icon={IconDocument}
          type="text"
          placeholder="Nome do evento"
          required
        />
        <GroupLink>
          <a
            href="https://buscacepinter.correios.com.br/app/endereco/index.php?"
            target="blank"
          >
            Não sabe seu cep, pesquise aqui.
          </a>
        </GroupLink>
        <Input
          name="zipcode"
          icon={IconLocation}
          type="text"
          inputProps={{ maxLength: 9, size: 9 }}
          placeholder="CEP"
          required
          value={maskedCep}
          onChange={onChangeMaskCep}
        />

        <Input
          name="street"
          icon={IconMap}
          type="text"
          placeholder="Endereço"
          required
        />
        <Input
          name="number"
          icon={IconMap}
          type="number"
          placeholder="Número"
          required
        />
        <Input
          name="complement"
          icon={IconMap}
          type="text"
          placeholder="Complemento"
        />
        <Input
          name="district"
          icon={IconMap}
          type="text"
          placeholder="Bairro"
          required
        />
        <Input
          required
          name="city"
          icon={IconMap}
          type="text"
          placeholder="Cidade"
        />

        <Input
          required
          name="state"
          icon={IconMap}
          type="text"
          placeholder="Estado"
          inputProps={{ maxLength: 2, size: 2 }}
        />

        <Instructions>
          <h2>Categorias, Prêmios e Taxas</h2>
          <p>
            <span>IMPORTANTE:</span> Ao preencher o campo prêmios, faça a
            separação utilizando ponto e vírgula ( ; ).
          </p>
          <p>
            <span>EXEMPLO:</span> 1º lugar: R$ 500,00 ; 2º lugar: R$ 300,00 ; 3º
            lugar: R$ 200,00
          </p>
        </Instructions>

        {categories.map((cat) => (
          <Scope path={`category#${cat.id}`} key={cat.id}>
            <InputsArea>
              <CheckBox
                name="category"
                defaultChecked={false}
                label={`Categoria ${cat.name}`}
              />
              <Input
                name="price"
                icon={IconPrice}
                type="number"
                placeholder="Inscrição"
              />

              <Input
                name="award"
                icon={IconPrice}
                type="text"
                placeholder="Prêmios"
              />
              <h1>Selecione as provas:</h1>
              <GroupCheckBoxArea>
                {racings &&
                  racings.map((rac) => (
                    <CheckBoxArea key={rac.id}>
                      <CheckBox
                        name={`racing#${rac.id}`}
                        defaultChecked={false}
                        label={rac.name}
                      />
                    </CheckBoxArea>
                  ))}
              </GroupCheckBoxArea>
            </InputsArea>
          </Scope>
        ))}
        <ButtonArea>
          <Button type="submit">Cadastrar</Button>
        </ButtonArea>
      </Form>
    </>
  );
}
