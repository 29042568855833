import React, { useState, useCallback, useEffect } from 'react';
import { toast } from 'react-toastify';
import { useParams, useNavigate, useLocation } from 'react-router-dom';
import Button from '../../components/Button';
import Loading from '../../components/Loading';
import {
  UploadArea,
  InfoSecondary,
  ActionArea,
  FileName,
  Info,
  ButtonView,
} from './styles';
import api from '../../services/api';
import coloricon from '../../styles/coloricon';
import Modal from '../../components/Modal';
import BreadCrumbs from '../../components/BreadcrumbsMUI';
import BlockUI from '../../components/BlockUi';

export default function Folder() {
  const { idEvent } = useParams();
  const navigate = useNavigate();
  const location = useLocation();
  const [openBlockUI, setOpenBlockUI] = useState(false);
  const [loading, setLoading] = useState(true);
  const [folder, setFolder] = useState({});
  const [openModal, setOpenModal] = useState(false);
  const { IconUpload, IconDownload, IconSearch, IconDelete } = coloricon;

  useEffect(() => {
    async function loaderEvent() {
      try {
        const response = await api.get(`/folders/${idEvent}`);
        setFolder(response.data);
        setLoading(false);
      } catch (error) {
        setLoading(false);
        toast.info(
          error?.response.data.error || 'Falha ao carregaras informações!'
        );
      }
    }
    loaderEvent();
  }, [idEvent, location?.key]);

  const handleOpenModal = useCallback(() => {
    setOpenModal(true);
  }, []);

  const handleCloseModal = useCallback(() => {
    setOpenModal(false);
  }, []);

  const handleUploadFolder = useCallback(
    async (event) => {
      try {
        setOpenBlockUI(true);
        const data = new FormData();
        data.append('file', event.target.files[0]);
        await api.post(`/folders/${idEvent}`, data);
        toast.info('O upload foi realizado com sucesso!');
        setOpenBlockUI(false);
        navigate({ pathname: `/eventFolder/${idEvent}` });
      } catch (error) {
        setLoading(false);
        toast.info(
          error?.response.data.error || 'Falha ao executar o upload do arquivo!'
        );
      }
    },
    [idEvent, navigate]
  );

  const handleDelete = useCallback(async () => {
    try {
      const response = await api.delete(`/folders/${idEvent}`);
      setOpenModal(false);
      toast.info(response.data.message);
      navigate({ pathname: `/eventFolder/${idEvent}` });
    } catch (error) {
      toast.info(error?.response.data.error || 'Falha na deleção do evento!');
    }
  }, [idEvent, navigate]);

  const handleDownloadByUrl = useCallback(() => {
    // get url from file
    const urlFile = folder.url;
    const nameFile = folder.name;

    fetch(urlFile, {
      mode: 'no-cors', //! propriedade que permite fazer o download
      method: 'get',
      headers: {
        'Content-Type': 'application/json',
      },
    })
      .then((response) => response.blob())
      .then((blob) => {
        const blobURL = window.URL.createObjectURL(new Blob([blob]));
        // Create an anchor element
        const link = document.createElement('a');

        // Set the href attribute to the image URL
        link.href = blobURL;

        // Set the download attribute with the desired filename
        link.setAttribute('download', nameFile);

        // Append the anchor element to the document
        document.body.appendChild(link);

        // Trigger a click event on the anchor element to start the download
        link.click();
        link.remove();
      });
  }, [folder]);

  return loading === true ? (
    <Loading />
  ) : (
    <>
      <BlockUI blocking={openBlockUI} />
      <BreadCrumbs
        nameOfCurrentPage="Editar folder"
        arrayOfTitleAndPath={[
          {
            title: 'Início',
            path: '/dashManager',
          },
          {
            title: 'Eventos',
            path: '/eventManager',
          },
        ]}
      />

      <Info>É permitido upload de fotos e arquivos em PDF.</Info>

      <UploadArea>
        <label htmlFor="folder">
          <IconUpload />
          Selecione um arquivo
          <input
            type="file"
            id="folder"
            onChange={handleUploadFolder}
            accept="image/*, .doc , .pdf"
          />
        </label>
      </UploadArea>

      {!folder && (
        <InfoSecondary>Esse evento ainda não possui folder!</InfoSecondary>
      )}

      {folder && folder?.name && <FileName>Nome: {folder?.name}</FileName>}

      {folder && (
        <ActionArea>
          <Button type="button" onClick={() => handleDownloadByUrl()}>
            <div>
              <IconDownload />
              <p>Baixar</p>
            </div>
          </Button>

          <ButtonView
            href={folder.url}
            without="true"
            rel="noopener noreferrer"
            target="_blank"
          >
            <IconSearch />
            Visualizar
          </ButtonView>

          <Button type="button" onClick={() => handleOpenModal()}>
            <div>
              <IconDelete />
              <p>Excluir</p>
            </div>
          </Button>
        </ActionArea>
      )}
      <Modal
        isOpen={openModal}
        onClickClose={handleCloseModal}
        onClickAction={handleDelete}
        title="Confirma a exclusão do folder?"
        nameOfItem={`${folder?.name || 'Nome não encontrado'}`}
      />
    </>
  );
}
