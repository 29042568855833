import styled from 'styled-components';
import coloricon from '../../styles/coloricon';

const { backgroundHeader, secondaryColor, background } = coloricon;

export const ResumeArea = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  background-color: ${background};
  //min-height: 100vh;
`;

export const ItemResume = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: ${backgroundHeader};
  padding: 30px;
  margin: 10px;
  min-width: 40%;
  border-radius: 5px;

  h1 {
    margin: 10px 0px;
    color: ${secondaryColor};
    font-size: 1.5em;
    font-weight: bold;
  }
`;
