import styled from 'styled-components';
import coloricon from '../../styles/coloricon';

const { fontColor, secondaryColorHover, secondaryColor } = coloricon;

export const GroupDate = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-around;
`;

export const GroupLink = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  margin: 10px 0px;

  a {
    cursor: pointer;
    color: ${fontColor};
    transition: all 0.3s ease-in-out;

    &:hover {
      color: ${secondaryColorHover};
    }
  }
`;

export const Instructions = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 15px 0px;

  h2 {
    color: ${secondaryColor};
    margin: 5px 0px;
  }

  p {
    span {
      font-weight: bold;
      color: red;
    }
  }
`;

export const ButtonArea = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  margin: 15px 0px;

  button {
    width: 100%;
  }
`;

export const GroupCheckBox = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 100%;
`;

export const GroupCheckBoxArea = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-around;
  width: 100%;
`;

export const InputsArea = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  margin: 50px 0px;
  border-bottom: 3px dashed ${fontColor};

  > h1 {
    margin: 7px 0px;
    color: ${secondaryColor};
    font-size: 1.2em;
  }
`;

export const CheckBoxArea = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;
