import React, { useEffect, useState, useCallback } from 'react';
import { toast } from 'react-toastify';
import { Link, useNavigate } from 'react-router-dom';
import api from '../../services/api';
import ReactTable from '../../components/ReactTable';
import Modal from '../../components/Modal';
import Button from '../../components/Button';
import coloricon from '../../styles/coloricon';
import { GroupButton, IconArea } from './styles';
import Loading from '../../components/Loading';
import BreadCrumbs from '../../components/BreadcrumbsMUI';

export default function EventManager() {
  const [loading, setLoading] = useState(true);
  const navigation = useNavigate();
  const { IconDelete, IconEdit, IconPDF } = coloricon;
  const [events, setEvents] = useState([]);
  const [openModal, setOpenModal] = useState(false);
  const [itemModal, setItemModal] = useState({});

  useEffect(() => {
    async function loaderEvents() {
      try {
        const response = await api.get('/events');
        setEvents(response.data);
        setLoading(false);
      } catch (error) {
        setLoading(false);
        toast.info(error.response.data.error);
      }
    }
    loaderEvents();
  }, []);

  const handleOpenModal = useCallback((model) => {
    setItemModal(model);
    setOpenModal(true);
  }, []);

  const handleCloseModal = useCallback(() => {
    setOpenModal(false);
    setItemModal({});
  }, []);

  const handleDelete = useCallback(async () => {
    try {
      const response = await api.delete(`/events/${itemModal.id}`);
      const newArray = events.filter((item) => item.id !== itemModal.id);
      setEvents(newArray);
      toast.info(response.data.message);
      setOpenModal(false);
      setItemModal({});
    } catch (error) {
      toast.info(error.response.data.error);
    }
  }, [events, itemModal.id]);

  return loading === true ? (
    <Loading />
  ) : (
    <>
      <BreadCrumbs
        nameOfCurrentPage="Eventos"
        arrayOfTitleAndPath={[{ title: 'Início', path: '/dashManager' }]}
      />
      {events && events.length === 0 && (
        <>
          <GroupButton>
            <Button
              type="button"
              onClick={() => navigation({ pathname: '/eventCreate' })}
            >
              Adicionar evento
            </Button>
          </GroupButton>
          <p style={{ textAlign: 'center' }}>
            Não existem registros para exibir.
          </p>
        </>
      )}
      {events && events.length > 0 && (
        <GroupButton>
          <Button
            type="button"
            onClick={() => navigation({ pathname: '/eventCreate' })}
          >
            Adicionar evento
          </Button>
        </GroupButton>
      )}
      {events && events.length > 0 && (
        <ReactTable
          data={events}
          columns={[
            { Header: 'Nome', accessor: 'name', width: '70%' },
            {
              Header: 'Início',
              accessor: 'date_start',
              disableFilters: true,
            },
            { Header: 'Cidade', accessor: 'city', disableFilters: true },
            { Header: 'UF', accessor: 'state', disableFilters: true },
            {
              Header: 'Folder',
              Cell: ({ row }) => (
                <IconArea>
                  <Link to={`/eventFolder/${row.original.id}`}>
                    <IconPDF />
                  </Link>
                </IconArea>
              ),
            },
            {
              Header: 'Editar',
              Cell: ({ row }) => (
                <IconArea>
                  <Link to={`/eventUpdate/${row.original.id}`}>
                    <IconEdit />
                  </Link>
                </IconArea>
              ),
            },
            {
              Header: 'Excluir',
              Cell: ({ row }) => (
                <IconArea>
                  <IconDelete onClick={() => handleOpenModal(row.original)} />
                </IconArea>
              ),
            },
          ]}
        />
      )}

      <Modal
        isOpen={openModal}
        onClickClose={handleCloseModal}
        onClickAction={handleDelete}
        title="Confirma a exclusão do evento?"
        nameOfItem={`${itemModal.name}-${itemModal.date_start}-${itemModal.date_end}`}
      />
    </>
  );
}
