import React, { useEffect, useState, useCallback } from 'react';
import { toast } from 'react-toastify';
import { Link, useNavigate } from 'react-router-dom';
import api from '../../services/api';
import ReactTable from '../../components/ReactTable';
import Modal from '../../components/Modal';
import Button from '../../components/Button';
import coloricon from '../../styles/coloricon';
import Loading from '../../components/Loading';
import BreadcrumbsMUI from '../../components/BreadcrumbsMUI';
import { GroupButton, IconArea } from './styles';

export default function AnimalManager() {
  const [loading, setLoading] = useState(true);
  const navigation = useNavigate();
  const { IconDelete, IconEdit } = coloricon;
  const [animals, setAnimals] = useState([]);
  const [openModal, setOpenModal] = useState(false);
  const [itemModal, setItemModal] = useState({});

  useEffect(() => {
    async function loaderAnimals() {
      try {
        const response = await api.get('/animals');
        setAnimals(response.data);
        setLoading(false);
      } catch (error) {
        setLoading(false);
        toast.info(error.response.data.error || 'Falha no carregamento!');
      }
    }
    loaderAnimals();
  }, []);

  const handleOpenModal = useCallback((model) => {
    setItemModal(model);
    setOpenModal(true);
  }, []);

  const handleCloseModal = useCallback(() => {
    setOpenModal(false);
    setItemModal({});
  }, []);

  const handleDelete = useCallback(async () => {
    try {
      const response = await api.delete(`/animals/${itemModal.id}`);
      const newArray = animals.filter((item) => item.id !== itemModal.id);
      setAnimals(newArray);
      toast.info(response.data.message);
      setOpenModal(false);
      setItemModal({});
    } catch (error) {
      toast.info(error.response.data.error);
    }
  }, [animals, itemModal.id]);

  return loading === true ? (
    <Loading />
  ) : (
    <>
      <BreadcrumbsMUI
        nameOfCurrentPage="Animais"
        arrayOfTitleAndPath={[{ title: 'Início', path: '/dashPlayer' }]}
      />
      <GroupButton>
        <Button
          type="button"
          onClick={() => navigation({ pathname: '/animalCreate' })}
        >
          Adicionar animal
        </Button>
      </GroupButton>
      {animals && animals.length === 0 && (
        <p style={{ textAlign: 'center', marginTop: 15 }}>
          Não existem registros para exibir.
        </p>
      )}

      {animals && animals.length > 0 && (
        <ReactTable
          data={animals}
          columns={[
            { Header: 'Nome', accessor: 'name', width: '70%' },
            {
              Header: 'Animal',
              accessor: 'animal',
              disableFilters: true,
            },
            {
              Header: 'Editar',
              Cell: ({ row }) => (
                <IconArea>
                  <Link to={`/animalUpdate/${row.original.id}`}>
                    <IconEdit />
                  </Link>
                </IconArea>
              ),
            },
            {
              Header: 'Excluir',
              Cell: ({ row }) => (
                <IconArea>
                  <IconDelete onClick={() => handleOpenModal(row.original)} />
                </IconArea>
              ),
            },
          ]}
        />
      )}

      <Modal
        isOpen={openModal}
        onClickClose={handleCloseModal}
        onClickAction={handleDelete}
        title="Confirma a exclusão do animal?"
        nameOfItem={`${itemModal.name} - ${itemModal.animal}`}
      />
    </>
  );
}
