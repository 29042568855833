import styled from 'styled-components';
import coloricon from '../../styles/coloricon';

const { secondaryColor } = coloricon;

export const SuccessArea = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  margin: 15px 0px;

  > h3 {
    font-weight: bold;
    font-size: 1.2em;
    color: ${secondaryColor};
    margin: 10px 0px;
  }

  > h1 {
    font-size: 1.1em;
    margin: 7px 0px;
  }

  svg {
    font-size: 50px;
    color: ${secondaryColor};
    margin: 7px 0px;
  }
`;
