import React, { useRef, useCallback } from 'react';
import * as Yup from 'yup';
import { toast } from 'react-toastify';
import { useParams, useNavigate } from 'react-router-dom';
import { Form } from '@unform/web';
import { FiLock } from 'react-icons/fi';
import Input from '../../components/Input';
import Button from '../../components/Button';
import api from '../../services/api';
import logo from '../../assets/logo.svg';
import Footer from '../../components/Footer';
import Header from '../../components/Header';
import { Banner, BannerArea, Content, LogoArea } from './styles';

export default function ResetAccessPlatform() {
  const formRef = useRef(null);
  const navigation = useNavigate();
  const { token } = useParams();

  const handleResetPass = useCallback(
    async (data) => {
      try {
        const schemaValidation = Yup.object().shape({
          password: Yup.string()
            .required('A senha é obrigatória!')
            .min(6, 'A senha precisa mínimo 6 dígitos!')
            .max(12, 'A senha suporta máximo 12 dígitos!'),
          confirmPassword: Yup.string().when('password', {
            is: (val) => val && val.length > 0,
            then: Yup.string()
              .required()
              .oneOf(
                [Yup.ref('password')],
                'A nova senha e a confirmação são diferentes!'
              ),
            otherwise: Yup.string,
          }),
        });

        await schemaValidation.validate(data, {
          abortEarly: false,
        });

        formRef.current.setErrors({});

        const { password, confirmPassword } = data;
        const response = await api.put(`/resetAccessPlayer/${token}`, {
          password,
          confirmPassword,
        });
        toast.info(response.data.message);
        navigation({ pathname: '/loginPlayer' });
      } catch (err) {
        if (err instanceof Yup.ValidationError) {
          const errorMessages = {};

          err.inner.forEach((error) => {
            errorMessages[error.path] = error.message;
          });

          formRef.current.setErrors(errorMessages);
        } else {
          toast.info(err.response.data.error);
        }
      }
    },
    [token, navigation]
  );

  return (
    <>
      <Header />
      <Banner>
        <BannerArea>
          <Content>
            <LogoArea>
              <img src={logo} alt={process.env.REACT_APP_NAME} />
              <h1>{process.env.REACT_APP_NAME}</h1>
            </LogoArea>
            <h1>Resetar senha competidor</h1>
            <Form ref={formRef} onSubmit={handleResetPass}>
              <Input
                icon={FiLock}
                name="password"
                type="password"
                autoComplete="on"
                placeholder="Nova senha"
                required
                inputProps={{ minLength: 6, maxLength: 12, size: 12 }}
              />
              <Input
                icon={FiLock}
                name="confirmPassword"
                type="password"
                autoComplete="on"
                placeholder="Confirmação"
                required
                inputProps={{ minLength: 6, maxLength: 12, size: 12 }}
              />
              <Button type="submit">Enviar</Button>
            </Form>
          </Content>
        </BannerArea>
      </Banner>
      <Footer />
    </>
  );
}
