import styled from 'styled-components';
import banner from '../../assets/banner-racing-1.jpg';
import coloricon from '../../styles/coloricon';

const { fontColor, secondaryColor } = coloricon;

const imageChoosed = banner;

export const Banner = styled.div`
  width: 100%;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-image: url(${imageChoosed});
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
`;

export const BannerArea = styled.div`
  width: 100%;
  min-height: calc(100vh - 70px);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: rgba(0, 0, 0, 0.8);
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 700px;
  padding: 20px 30px;
  align-items: center;
  text-align: center;

  @media (max-width: 768px) {
    font-size: 15px;
  }

  @media (max-width: 425px) {
    font-size: 14px;
  }

  @media (max-width: 375px) {
    font-size: 13px;
  }

  @media (max-width: 320px) {
    font-size: 12px;
  }

  > h3 {
    text-align: center;
    margin: 15px 0px;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;

    a {
      color: ${secondaryColor};
      font-size: 1em;
      margin: 0px 7px;
    }
  }

  > form {
    width: 80%;
    display: flex;
    flex-direction: column;
  }

  > a {
    display: flex;
    align-items: center;
    justify-content: center;
    color: ${fontColor};
    margin-bottom: 20px;
    font-size: 1em;
    transition: all 0.3s ease-in-out;

    svg {
      margin-right: 5px;
    }

    &:hover {
      color: ${secondaryColor};
    }
  }
`;

export const LogoArea = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin: 15px 0px;

  @media (max-width: 425px) {
    flex-direction: column;

    > img {
      margin-bottom: 10px;
    }
  }

  > img {
    width: 2.25em;
    height: 2.25em;
  }

  > h1 {
    font-size: 2em;
    margin-left: 5px;
  }
`;
