import styled from 'styled-components';

export const GroupDetails = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 7px 0px;

  > p {
    margin: 7px 0px;
  }

  > h1 {
    font-size: 1.1em;
    margin: 5px 0px;
  }
`;

export const ButtonArea = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 7px 0px;
  width: 100%;

  > button {
    width: 100%;
  }
`;
