import styled from 'styled-components';
import coloricon from '../../styles/coloricon';

const { backgroundHeader, fontColor } = coloricon;

export const Overlay = styled.div`
  background-color: rgba(255, 255, 255, 0.2);
  position: fixed; // mesmo que tenha scroll na tela ele ficara fixo
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;

  @media (max-width: 768px) {
    font-size: 15px;
  }

  @media (max-width: 425px) {
    font-size: 14px;
  }

  @media (max-width: 375px) {
    font-size: 13px;
  }

  @media (max-width: 320px) {
    font-size: 12px;
  }
`;

export const Background = styled.div`
  position: fixed;
  background-color: ${backgroundHeader};
  border-radius: 5px;
  padding: 20px;
  width: 60%;
  height: 60%;
  /* top: 50%;
  left: 50%; */

  top: 50%;
  left: 58%;

  transform: translate(-50%, -42%);

  @media (max-width: 768px) {
    font-size: 15px;
  }

  @media (max-width: 425px) {
    font-size: 14px;
  }

  @media (max-width: 375px) {
    font-size: 13px;
  }

  @media (max-width: 320px) {
    font-size: 12px;
  }

  > button {
    float: right;
    background: unset; // unset remove os estilos padrao que o browser coloca
    border: unset;

    svg {
      color: ${fontColor};
      font-size: 25px;
      margin-right: 15px;
    }
  }
`;

export const ModalContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: ${fontColor};
  width: 100%;

  > form {
    width: 80%;
    margin: 20px 0px;
  }

  > h2 {
    font-size: 1.3em;
    margin-top: 3%;
    text-align: center;
  }

  > strong {
    margin-top: 3%;
    font-size: 1em;
    text-align: center;
  }

  > div {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    margin-top: 3%;
    width: 100%;

    @media (max-width: 768px) {
      flex-direction: column;
    }

    button {
      width: 100%;
      margin: 15px;
    }
  }
`;
