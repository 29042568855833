import { useState, useCallback } from 'react';
import { toast } from 'react-toastify';
import api from '../services/api';
import clearLocalStorage from './clearLocalStorage';

export default function PlayerContext() {
  const [dataPlayer, setDataPlayer] = useState(() => {
    const data = localStorage.getItem(
      `@${process.env.REACT_APP_NAME}:dataPlayer`
    );

    if (data) {
      const dataParsed = JSON.parse(data);
      api.defaults.headers.Authorization = `Bearer ${dataParsed.token}`;
      return {
        token: dataParsed.token,
        player: dataParsed.player,
        openDrawer: dataParsed.openDrawer,
      };
    }
    return { token: null, player: null, openDrawer: true };
  });

  const handleRegisterPlayer = useCallback(
    async (name, nickname, gender, city, state, phone, email, password) => {
      try {
        const response = await api.post('/players', {
          name,
          nickname,
          gender,
          city,
          state,
          phone,
          email,
          password,
        });

        toast.info(response.data.message);
        return '/loginPlayer';
      } catch (error) {
        toast.info(error.response.data.error);
        return null;
      }
    },
    []
  );

  const handleLoginPlayer = useCallback(async (email, password) => {
    try {
      const response = await api.post('/sessionPlayer', { email, password });
      const { token, player } = response.data;

      const data = {
        token,
        player,
        openDrawer: true,
      };

      clearLocalStorage();

      localStorage.setItem(
        `@${process.env.REACT_APP_NAME}:dataPlayer`,
        JSON.stringify(data)
      );

      setDataPlayer({ token, player, openDrawer: true });
      api.defaults.headers.Authorization = `Bearer ${token}`;
      return '/dashPlayer';
    } catch (err) {
      toast.info(err.response.data.error);
      return null;
    }
  }, []);

  const handleLogoutPlayer = useCallback(async () => {
    clearLocalStorage();
    setDataPlayer({ token: null, player: null, openDrawer: true });
    api.defaults.headers.Authorization = undefined;
  }, []);

  return {
    dataPlayer,
    setDataPlayer,
    handleRegisterPlayer,
    handleLoginPlayer,
    handleLogoutPlayer,
  };
}
