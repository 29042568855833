import React, { createContext, useMemo } from 'react';
import PropTypes from 'prop-types';
import playerContext from './playerContext';
import managerContext from './managerContext';

const AuthContext = createContext();
function AuthProvider({ children }) {
  const {
    dataPlayer,
    setDataPlayer,
    handleRegisterPlayer,
    handleLoginPlayer,
    handleLogoutPlayer,
  } = playerContext();

  // dados manager
  const {
    dataManager,
    setDataManager,
    handleLoginManager,
    handleLogoutManager,
  } = managerContext();

  /* para ele nao ficar renderizando de forma desnecessaria */
  const contextValues = useMemo(
    () => ({
      dataPlayer,
      setDataPlayer,
      handleRegisterPlayer,
      handleLoginPlayer,
      handleLogoutPlayer,
      dataManager,
      setDataManager,
      handleLoginManager,
      handleLogoutManager,
    }),
    [
      dataPlayer,
      setDataPlayer,
      handleRegisterPlayer,
      handleLoginPlayer,
      handleLogoutPlayer,
      dataManager,
      setDataManager,
      handleLoginManager,
      handleLogoutManager,
    ]
  );

  return (
    <AuthContext.Provider value={contextValues}>
      {children}
    </AuthContext.Provider>
  );
}

export { AuthContext, AuthProvider };

AuthProvider.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.element,
    PropTypes.func,
    PropTypes.string,
  ]).isRequired,
};

/* Provider: dentro dele eu tenho um provider que é o componente mais importante
que vai compartilhar informação dentro da minha app com os componentes filhos */

/*
Dentro do provider eu preciso passar o valor do contexto que eu vou compartilhar
com toda a minha informacao. Eu faço isso através da "propriedade value" que pode
ser uma string, um objeto
*/

/*
para acessar esse contexto eu uso um hook que é o useContext
*/

/* children é todos os componentes que eu coloco dentro do provider */
