import coloricon from '../../styles/coloricon';

const {
  IconHome,
  IconUser,
  IconMap,
  IconCalendar,
  IconHorse,
  IconRemoveAccount,
  IconCreditCard,
  IconPrice,
  IconUsers,
  IconReport,
} = coloricon;

export const menuPlayer = [
  { text: 'Início', Icon: IconHome, path: '/dashPlayer' },
  { text: 'Perfil', Icon: IconUser, path: '/profilePlayer' },
  { text: 'Eventos', Icon: IconMap, path: '/eventsByPlayer' },
  { text: 'Inscrições', Icon: IconCalendar, path: '/registrationsPlayer' },
  { text: 'Animais', Icon: IconHorse, path: '/animalManager' },
  { text: 'Conta', Icon: IconRemoveAccount, path: '/closeAccountPlayer' },
];

export const menuManager = [
  { text: 'Início', Icon: IconHome, path: '/dashManager' },
  { text: 'Eventos', Icon: IconCalendar, path: '/eventManager' },
  { text: 'Inscrição', Icon: IconCreditCard, path: '/eventsByManager' },
  { text: 'Financas', Icon: IconPrice, path: '/financial' },
  { text: 'Competidor', Icon: IconUsers, path: '/playersByManager' },
  { text: 'Animais', Icon: IconHorse, path: '/animalsFromPlayers' },
  { text: 'Ranking', Icon: IconReport, path: '/classification' },
];
