import React, { useEffect, useState, useCallback, useRef } from 'react';
import { toast } from 'react-toastify';
import { Form } from '@unform/web';
import Loading from '../../../components/Loading';
import Input from '../../../components/Input';
import Button from '../../../components/Button';
import { Container, AlertFinished } from './styles';
import coloricon from '../../../styles/coloricon';
import ReactTable from '../../../components/ReactTable';
import api from '../../../services/api';
import Modal from '../../../components/Modal';
import BlockUI from '../../../components/BlockUi';
import generatePDFStep1 from '../../../utils/generatePDFFromStep1';

export default function Step1({ idEvent, idCategory, idRacing, state }) {
  const formRef = useRef(null);
  const { IconSave, IconClock, IconDelete, IconAlert } = coloricon;
  const [loading, setLoading] = useState(true);
  const [players, setPlayers] = useState([]);
  const [openModal, setOpenModal] = useState(false);
  const [openModalFinished, setOpenModalFinished] = useState(false);
  const [openBlockUI, setOpenBlockUI] = useState(false);
  const [finished, setFinished] = useState(false);
  const [itemModal, setItemModal] = useState({});
  const updateStepNumber = 1;

  const loaderPlayers = useCallback(async (idEven, idCat, idRac) => {
    try {
      const response = await api.get(
        `/registrationsFromEventAndCategory/${idEven}/${idCat}/${idRac}`
      );
      setPlayers(response.data.registrations);
      setFinished(response.data.finished);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      toast.info(error.response.data.error || 'Falha no carregamento!');
    }
  }, []);

  useEffect(() => {
    loaderPlayers(idEvent, idCategory, idRacing);
  }, [idEvent, idCategory, idRacing, loaderPlayers]);

  const handleUpdateTime = useCallback(
    async (key) => {
      try {
        setOpenBlockUI(true);
        const time1 = formRef.current.getFieldValue(`time1-${key}`);
        const time2 = formRef.current.getFieldValue(`time2-${key}`);

        //! para acionar o save time precisa ao menos enviar um número preenchido
        if (
          (!time1 && !time2) ||
          (time1 && time1 === 0) ||
          (time2 && time2 === 0)
        ) {
          toast.info(
            'É preciso preencher ao menos um dos campos e com valor diferente de zero!'
          );
          return;
        }

        const data = {
          time1: time1 ? Number(time1) : null,
          time2: time2 ? Number(time2) : null,
          idRegistration: key,
          idRacing,
        };

        await api.post('/step1', data);
        toast.info('Registro atualizado!');
        await loaderPlayers(idEvent, idCategory, idRacing);
        setOpenBlockUI(false);
      } catch (error) {
        setOpenBlockUI(false);
        toast.info(
          error?.response.data.message || 'Falha ao efetivar as informações!'
        );
      }
    },
    [idCategory, idEvent, idRacing, loaderPlayers]
  );

  const handleDeletePlayer = useCallback(async () => {
    try {
      const response = await api.delete(
        `/removeRegistrationByManager/${itemModal.id}`
      );
      toast.info(response.data.message || 'Falha ao executar as operações!');
      setOpenModal(false);
      setItemModal({});
      await loaderPlayers(idEvent, idCategory, idRacing);
    } catch (error) {
      toast.info(
        error?.response.data.message || 'Falha ao efetivar as informações!'
      );
    }
  }, [idCategory, idEvent, idRacing, loaderPlayers, itemModal]);

  const handleOpenModal = useCallback((model) => {
    setItemModal(model);
    setOpenModal(true);
  }, []);

  const handleOpenModalFinished = useCallback(() => {
    setOpenModalFinished(true);
  }, []);

  const handleCloseModal = useCallback(() => {
    setOpenModal(false);
    setItemModal({});
  }, []);

  const handleCloseModalFinished = useCallback(() => {
    setOpenModalFinished(false);
  }, []);

  const handleValidateSendNextStep = useCallback(
    async (isNeedShowToast = true) => {
      const response = await api.get(
        `/registrationsFromEventAndCategory/${idEvent}/${idCategory}/${idRacing}`
      );
      const results = response.data?.registrations;
      if (results?.length === 0) {
        toast.info(
          'É preciso preencher todos os campos para calcular os confrontos da próxima fase!'
        );
        return false;
      }
      const errorList = results.filter(
        (item) => !item.ranking || !item.result || !item.time1 || !item.time2
      );
      if (errorList?.length > 0) {
        toast.info(
          `É preciso preencher os todos os campos para calcular os próximos confrontos.Exemplo: ${errorList[0]?.nickname} - ${errorList[0]?.animal}`
        );
        return false;
      }
      isNeedShowToast
        ? toast.info(`Não existem pendências para avançar para a próxima fase!`)
        : null;

      return true;
    },
    [idCategory, idEvent, idRacing]
  );

  const handleFinishedStep = useCallback(async () => {
    try {
      const isValid = await handleValidateSendNextStep(false);
      if (isValid === false) {
        handleCloseModalFinished();
        return;
      }
      const response = await api.put(
        `/finished/event/${idEvent}/category/${idCategory}/racing/${idRacing}/step/${updateStepNumber}`
      );
      handleCloseModalFinished();
      toast.info(
        response?.data.message || 'A etapa foi finalizada com sucesso!'
      );
    } catch (error) {
      toast.info(
        error?.response.data.error || 'Falha no processamento das informações!'
      );
    }
  }, [
    handleValidateSendNextStep,
    idCategory,
    idEvent,
    idRacing,
    handleCloseModalFinished,
  ]);

  const handleGeneratePDF = useCallback(() => {
    generatePDFStep1(players, state, 'Corridas 1ª Fase');
  }, [players, state]);

  return loading ? (
    <Loading />
  ) : (
    <>
      <BlockUI blocking={openBlockUI} />
      <Container>
        {players && players.length === 0 && (
          <p>Não existem registros para exibir.</p>
        )}
        {finished && finished === true && (
          <AlertFinished>
            <IconAlert />
            <h2>Essa etapa já foi finalizada!</h2>
          </AlertFinished>
        )}
        {players && players.length > 0 && (
          <>
            {finished === false && (
              <div>
                <Button type="button" onClick={handleValidateSendNextStep}>
                  Verificar pendências - Total: {players.length} competidores
                </Button>
                <Button type="button" onClick={handleOpenModalFinished}>
                  Encerrar etapa
                </Button>
                <Button type="button" onClick={handleGeneratePDF}>
                  Gerar PDF
                </Button>
              </div>
            )}
            <Form ref={formRef}>
              <ReactTable
                data={players}
                hiddenColumn={finished ? ['Editar', 'Excluir'] : []}
                columns={[
                  {
                    Header: 'Nickname',
                    accessor: 'nickname',
                    width: '70%',
                  },
                  {
                    Header: 'Patrocinador',
                    accessor: 'representation',
                  },
                  {
                    Header: 'Animal',
                    accessor: 'animal',
                  },
                  {
                    Header: 'Tempo 1',
                    Cell: ({ row }) => (
                      <Input
                        type="number"
                        name={`time1-${row?.original?.id}`}
                        placeholder="T1"
                        icon={IconClock}
                        initialData={row?.original?.time1 || null}
                        disabled={finished}
                      />
                    ),
                  },
                  {
                    Header: 'Tempo 2',
                    Cell: ({ row }) => (
                      <Input
                        type="number"
                        name={`time2-${row.original.id}`}
                        placeholder="T2"
                        icon={IconClock}
                        initialData={row?.original?.time2 || null}
                        disabled={finished}
                      />
                    ),
                  },
                  {
                    Header: 'Resultado',
                    accessor: 'result',
                    disableFilters: true,
                  },
                  {
                    Header: 'Nº',
                    accessor: 'ranking',
                    disableFilters: true,
                  },
                  {
                    Header: 'Editar',
                    Cell: ({ row }) => (
                      <IconSave
                        style={{ cursor: 'pointer' }}
                        onClick={() => handleUpdateTime(row.original.id)}
                      />
                    ),
                  },
                  {
                    Header: 'Excluir',
                    Cell: ({ row }) => (
                      <IconDelete
                        style={{ cursor: 'pointer' }}
                        onClick={() => handleOpenModal(row.original)}
                      />
                    ),
                  },
                ]}
              />
            </Form>
          </>
        )}
      </Container>
      <Modal
        isOpen={openModal}
        onClickClose={handleCloseModal}
        onClickAction={handleDeletePlayer}
        title="Confirma a exclusão da inscrição?"
        nameOfItem={`${itemModal.nickname} - ${itemModal.animal} -${itemModal.representation}`}
      />
      {/* modal finished */}
      <Modal
        isOpen={openModalFinished}
        onClickClose={handleCloseModalFinished}
        onClickAction={handleFinishedStep}
        title="Confirma o encerramento da etapa?"
        nameOfItem={`Encerramento da Etapa: ${updateStepNumber}`}
      />
    </>
  );
}
