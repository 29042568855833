import React, { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import Loading from '../../../components/Loading';
import ReactTable from '../../../components/ReactTable';
import { Container } from '../stepsGlobalStyles';
import api from '../../../services/api';

export default function Points({ idEvent, idCategory, idRacing }) {
  const [points, setPoints] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    async function loaderPoints() {
      try {
        const response = await api.get(
          `/preparePointsOfBattleByRacing/${idEvent}/${idCategory}/${idRacing}`
        );
        setPoints(response.data);
        setLoading(false);
      } catch (error) {
        toast.info(
          error?.response?.data.error ||
            'Falha no carregamento das informações!'
        );
        setLoading(false);
      }
    }
    loaderPoints();
  }, [idCategory, idEvent, idRacing]);

  return loading === true ? (
    <Loading />
  ) : (
    <Container>
      {points.length === 0 && (
        <p>
          Os dados dessa página estarão disponíveis quando concluir a etapa
          anterior!
        </p>
      )}
      {points.length > 0 && (
        <ReactTable
          data={points}
          columns={[
            {
              Header: 'Nickname',
              accessor: 'nickname',
              disableFilters: true,
            },
            {
              Header: 'Animal',
              accessor: 'animal',
              disableFilters: true,
            },
            {
              Header: 'Patrocinador',
              accessor: 'representation',
              disableFilters: true,
            },
            {
              Header: 'Posição',
              accessor: 'position',
              disableFilters: true,
            },
            {
              Header: 'Pontos',
              accessor: 'points',
              disableFilters: true,
            },
            {
              Header: 'Ranking',
              accessor: 'ranking',
              disableFilters: true,
            },
          ]}
        />
      )}
    </Container>
  );
}
