import React, { useState, useEffect } from 'react';
import { parseISO, format } from 'date-fns';
import { pt } from 'date-fns/locale';
import { toast } from 'react-toastify';
import { useNavigate, useParams } from 'react-router-dom';
import Button from '../../components/Button';
import api from '../../services/api';
import Loading from '../../components/Loading';
import BreadCrumbs from '../../components/BreadcrumbsMUI';
import { GroupCategory, GroupCategoryItem, DetailsArea } from './styles';

export default function Racings() {
  const { idEvent, idCategory } = useParams();
  const [event, setEvent] = useState({});
  const [loading, setLoading] = useState(true);
  const [categoryName, setCategoryName] = useState('');

  const navigate = useNavigate();

  useEffect(() => {
    async function loaderEvent() {
      try {
        const response = await api.get(`/events/${idEvent}`);
        setEvent({
          ...response.data,
          date_start: format(
            parseISO(response.data.date_start),
            'dd-MM-yyyy HH:mm',
            { locale: pt }
          ),
          date_end: format(
            parseISO(response.data.date_end),
            'dd-MM-yyyy HH:mm',
            { locale: pt }
          ),
        });
        const nameOfCategory = response?.data.category.find(
          (item) => item.id === idCategory
        );
        setCategoryName(nameOfCategory?.name || 'Categoria não localizada');
        setLoading(false);
      } catch (error) {
        toast.info(
          error?.response?.data.error || 'Falha no carregamento da página!'
        );
        setLoading(false);
      }
    }
    loaderEvent();
  }, [idEvent, idCategory]);

  return loading === true ? (
    <Loading />
  ) : (
    <>
      <BreadCrumbs
        nameOfCurrentPage="Corridas"
        arrayOfTitleAndPath={[
          {
            title: 'Início',
            path: '/dashManager',
          },
          {
            title: 'Eventos',
            path: '/classification',
          },
          {
            title: 'Categorias',
            path: `/classificationEventCategory/${idEvent}`,
          },
        ]}
      />
      <DetailsArea>
        <h2>Detalhes do evento</h2>
        <h1>Nome: {event.name}</h1>
        <h1>Categoria: {categoryName}</h1>
        <h1>
          Endereço: {event.street} , {event.number} , {event.district} ,{' '}
          {event.complement ? event.complement : 'Sem complemento'},{' '}
          {event.city} - {event.state} , {event.zipcode}.
        </h1>
      </DetailsArea>
      <GroupCategory>
        {event.eventscategories
          .filter((item) => item.category_id === idCategory)?.[0]
          .eventscategoriesracings.map((rac) => (
            <GroupCategoryItem key={rac.racing.id}>
              <h1>Corrida {rac.racing.name}</h1>
              <Button
                type="button"
                onClick={() =>
                  // navigate({
                  //   pathname: `/steps/${idEvent}/${idCategory}/${rac.racing.id}`,
                  // })
                  navigate(`/steps/${idEvent}/${idCategory}/${rac.racing.id}`, {
                    state: {
                      eventName: event.name,
                      categoryName,
                      racingName: rac.racing.name,
                    },
                  })
                }
              >
                Tempos
              </Button>
            </GroupCategoryItem>
          ))}
      </GroupCategory>
    </>
  );
}
