import pdfMake from 'pdfmake/build/pdfmake';
import pdfFonts from 'pdfmake/build/vfs_fonts';
import coloricon from '../styles/coloricon';

pdfMake.vfs = pdfFonts.pdfMake.vfs;

const { secondaryColor } = coloricon;

const generatePDF = (registrations, title, subtitle) => {
  const totalBattles = registrations.length;

  const rows = [];

  if (totalBattles > 0) {
    const registrationsRevision = registrations.map((players) => {
      const player = `${players.nickname} - ${players.animal} - ${players.representation}`;
      const data = { player };
      return data;
    });

    for (const battle of registrationsRevision) {
      const data = {
        text: battle.player,
        style: 'anotherStyle',
      };

      const row = [];
      row.push(data);

      rows.push(row);
    }
  }

  if (totalBattles === 0) {
    const data = ['Não existe dados'];
    rows.push(data);
  }

  const docDefinition = {
    content: [
      {
        text: `${title.eventName}`,
        style: 'title',
      },
      {
        text: `${title.categoryName} - ${title.racingName}`,
        style: 'subtitle',
      },
      {
        text: `${totalBattles} ${subtitle}`,
        style: 'totalBattles',
      },
      {
        layout: 'lightHorizontalLines', // optional
        table: {
          headerRows: 1,
          widths: ['*'],

          body: [
            [
              {
                text: 'Competidor',
                bold: true,
                fillColor: 'gray',
                lineHeight: 1.75,
                fontSize: 18,
                alignment: 'justify',
              },
            ],
            ...rows,
          ],
        },
      },
    ],
    styles: {
      title: {
        fontSize: 20,
        marginBottom: 10,
        bold: true,
        color: 'black',
        alignment: 'center',
      },
      subtitle: {
        fontSize: 18,
        marginBottom: 10,
        bold: true,
        color: secondaryColor,
        alignment: 'center',
      },
      totalBattles: {
        fontSize: 16.5,
        marginBottom: 10,
        color: 'black',
        alignment: 'center',
      },
      anotherStyle: {
        fontSize: 14,
        italics: false,
        lineHeight: 1.5,
      },
    },
  };

  pdfMake.createPdf(docDefinition).open();
};

export default generatePDF;
