import styled from 'styled-components';
import coloricon from '../../styles/coloricon';

const { secondaryColor } = coloricon;

export const Container = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: calc(100vh - 80px - 100px);
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 900px;
  align-items: center;
  justify-content: center;
  margin-bottom: 50px;
  padding: 0 10px;

  @media (max-width: 768px) {
    font-size: 15px;
  }

  @media (max-width: 425px) {
    font-size: 14px;
  }

  @media (max-width: 375px) {
    font-size: 13px;
  }

  @media (max-width: 320px) {
    font-size: 12px;
  }

  > p {
    margin: 15px 0px;
    text-align: center;
  }

  > h2 {
    color: ${secondaryColor};
    margin: 15px 0px;
  }
`;
