import React, { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { Link } from 'react-router-dom';
import Loading from '../../components/Loading';
import api from '../../services/api';
import Header from '../../components/Header';
import Footer from '../../components/Footer';
import ReactTable from '../../components/ReactTable';
import coloricon from '../../styles/coloricon';
import { Container, Content } from './styles';

export default function EventManager() {
  const [loading, setLoading] = useState(true);
  const { IconLogin, IconPDF } = coloricon;
  const [events, setEvents] = useState([]);

  useEffect(() => {
    async function loaderEvents() {
      try {
        const response = await api.get('/eventsForHomePage');
        setEvents(response.data);
        setLoading(false);
      } catch (error) {
        setLoading(false);
        toast.info(error.response.data.error);
      }
    }
    loaderEvents();
  }, []);

  return loading === true ? (
    <Loading />
  ) : (
    <>
      <Header />

      <Container>
        <Content>
          <h1>Eventos</h1>

          {events && events.length === 0 && (
            <p>Não existem registros para exibir.</p>
          )}

          {events && events.length > 0 && (
            <ReactTable
              data={events}
              columns={[
                { Header: 'Nome', accessor: 'name', width: '70%' },
                {
                  Header: 'Início',
                  accessor: 'date_start',
                  disableFilters: true,
                },
                { Header: 'Cidade', accessor: 'city', disableFilters: true },
                { Header: 'UF', accessor: 'state', disableFilters: true },
                {
                  Header: 'Folder',
                  Cell: ({ row }) => (
                    <Link
                      to={`/eventFolderHome/${row.original.id}/name/${row.original.name}`}
                    >
                      <IconPDF />
                    </Link>
                  ),
                },
                {
                  Header: 'Detalhes',
                  Cell: ({ row }) => (
                    <Link to={`/eventHomePageDetails/${row.original.id}`}>
                      <IconLogin />
                    </Link>
                  ),
                },
              ]}
            />
          )}
        </Content>
      </Container>
      <Footer />
    </>
  );
}
