import React from 'react';
import { Link } from 'react-router-dom';
import { Footer, FooterCopy, FooterInfo, GroupRow } from './styles';
import TermosCliente from '../../documents/termos_de_uso_cliente.pdf';
import Privacidade from '../../documents/politica_de_privacidade.pdf';
import coloricon from '../../styles/coloricon';
import platformInfo from '../../config/infosPlatform';

/* colocar rede social */

export default function FooterHome() {
  const yearCurrent = new Date().getFullYear();
  const { IconInstagram, IconFacebook, IconWhatsApp } = coloricon;

  return (
    <Footer>
      <GroupRow>
        <FooterInfo>
          <a href={platformInfo.instagram} target="blank">
            <IconInstagram size={25} />
          </a>

          <a href={platformInfo.facebook} target="blank">
            <IconFacebook size={25} />
          </a>
          <a href={platformInfo.whatsapp} target="blank">
            <IconWhatsApp size={30} />
          </a>
        </FooterInfo>

        <Link to="/about">Empresa</Link>
        <a
          href={TermosCliente}
          without="true"
          rel="noopener noreferrer"
          target="_blank"
        >
          Termos de uso
        </a>

        <a
          href={Privacidade}
          without="true"
          rel="noopener noreferrer"
          target="_blank"
        >
          Privacidade
        </a>
      </GroupRow>

      <FooterCopy>
        2020 | {yearCurrent} - {process.env.REACT_APP_NAME} Todos os direitos
        reservados.
      </FooterCopy>
    </Footer>
  );
}
