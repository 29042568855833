import styled from 'styled-components';
import coloricon from '../../styles/coloricon';

const { secondaryColor } = coloricon;

export const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  > div {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;

    button {
      width: 100%;
      max-width: 900px;
      margin: 10px;
    }
  }

  > button {
    width: 70%;
    max-width: 900px;
    margin-bottom: 15px;
    margin-top: -10px;
  }

  > p {
    text-align: center;
    margin: 20px 0px;
  }

  > form {
    width: 100%;
  }
`;

export const ContentBattle = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
`;

export const GroupPlayer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;

  > h1 {
    font-size: 1.02em;
  }
`;

export const GroupInputPlayer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 100%;

  > div {
    margin: 0px 15px;
  }

  svg {
    font-size: 25px;
    cursor: pointer;
  }
`;

export const AutoClassification = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  margin: 15px 0px;

  > p {
    width: 100%;
  }

  > h1 {
    width: 100%;
    color: ${secondaryColor};
    font-size: 1.1em;
  }
`;

export const Winner = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  margin: 10px;

  > p {
    width: 100%;
  }

  > h1 {
    width: 100%;
    color: ${secondaryColor};
    font-size: 1.1em;
  }
`;

export const NoHaveBattle = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  margin: 10px;

  > p {
    width: 100%;
    font-size: 1em;
  }
`;

export const TitleDetails = styled.h3`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  color: ${secondaryColor};
  font-weight: bold;
  font-size: 1.2em;
  margin-top: 15px;
  text-transform: uppercase;
`;

export const AlertFinished = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin-bottom: 15px;

  > svg {
    font-size: 30px;
    margin-right: 15px;
    color: red;
  }

  > h2 {
    color: red;
    font-size: 1.3em;
  }
`;
