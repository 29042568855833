import styled from 'styled-components';
import coloricon from '../../styles/coloricon';

const { secondaryColor } = coloricon;

export const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  min-height: calc(100vh - 100px - 80px);
  margin-bottom: 20px;
  padding: 20px;

  > button {
    width: 70%;
    max-width: 900px;
    margin-bottom: 15px;
    margin-top: -10px;
  }

  > p {
    text-align: center;
    margin: 20px 0px;
  }

  > form {
    width: 100%;
  }
`;

export const TitleDetails = styled.h1`
  font-size: 1.2em;
  text-transform: uppercase;
  color: ${secondaryColor};
  font-weight: bold;
  text-align: center;
  margin-top: 15px;
`;
