import styled from 'styled-components';
import coloricon from '../../styles/coloricon';

const { secondaryColor, secondaryColorHover } = coloricon;

export const GroupButton = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin-bottom: 15px;

  @media (max-width: 768px) {
    flex-direction: column;
  }

  > div {
    flex: 1;
    width: 100%;
    margin: 0px 15px;

    > button {
      width: 100%;
    }
  }
`;

export const IconArea = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  cursor: pointer;

  > svg {
    cursor: pointer;
    color: ${secondaryColor};
    transition: all 0.3s ease-in-out;

    &:hover {
      color: ${secondaryColorHover};
    }
  }
`;
