import React, { useState, useEffect } from 'react';
import { toast } from 'react-toastify';
import { useLocation } from 'react-router-dom';
import { GroupDetails } from './styles';
import Loading from '../../components/Loading';
import BreadCrumbs from '../../components/BreadcrumbsMUI';
import coloricon from '../../styles/coloricon';
import api from '../../services/api';
import { formatPrice } from '../../utils/format';

export default function ConfirmationRegistration() {
  const { state } = useLocation();
  const [loading, setLoading] = useState(true);
  const { IconCheckCircle } = coloricon;
  const [account, setAccount] = useState({});

  useEffect(() => {
    async function loaderAccount() {
      try {
        const response = await api.get(`/account`);
        setAccount(response.data);
        setLoading(false);
      } catch (error) {
        setLoading(false);
        toast.info(error.response.data.message || 'Falha no carregamento!');
      }
    }
    loaderAccount();
  }, []);

  return loading ? (
    <Loading />
  ) : (
    <>
      <BreadCrumbs
        nameOfCurrentPage="Pré-inscrição"
        arrayOfTitleAndPath={[
          {
            title: 'Início',
            path: '/dashPlayer',
          },
        ]}
      />
      <GroupDetails>
        <h3>Pré-inscrição realizada!</h3>

        <IconCheckCircle />

        <h1>Inscrição número: {state.registration_number}</h1>
        <p>
          A inscrição será efetivada após o pagamento da taxa de inscrição no
          valor de {formatPrice(Number(state.tuition))}. Garanta sua
          participação e faça o pagamento agora mesmo!
        </p>

        <h1>Favorecido: {account.owner}</h1>
        <h1>Banco: {account.bank}</h1>
        <h1>Agencia: {account.agency}</h1>
        <h1>Conta: {account.account_number}</h1>
        <h1>Tipo: {account.type}</h1>
        <h1>Chave Pix: {account.key}</h1>
      </GroupDetails>
    </>
  );
}
