import styled from 'styled-components';

export const ContentArea = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  margin: 15px 0px;

  > form {
    width: 70%;
  }

  > button {
    width: 100%;
  }
`;

export const ButtonArea = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  margin: 15px 0px;

  > button {
    width: 100%;
  }
`;
