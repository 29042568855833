import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import MuiAccordion from '@material-ui/core/Accordion';
import MuiAccordionSummary from '@material-ui/core/AccordionSummary';
import MuiAccordionDetails from '@material-ui/core/AccordionDetails';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { questionsPlayer } from './questions';
import { Container, Content } from './styles';
import coloricon from '../../styles/coloricon';
import Header from '../../components/Header';
import Footer from '../../components/Footer';

export default function Faq() {
  const { backgroundHeader, fontColor, backgroundHeaderHover } = coloricon;

  const Accordion = withStyles({
    root: {
      backgroundColor: backgroundHeaderHover,
      margin: '3px',
      color: fontColor,
      width: '100%',
      boxShadow: 'none',
      '&:not(:last-child)': {
        borderBottom: 0,
      },
      '&:before': {
        display: 'none',
      },
      '&$expanded': {
        margin: 'auto',
      },
    },
    expanded: {},
  })(MuiAccordion);

  const AccordionSummary = withStyles({
    root: {
      backgroundColor: backgroundHeader,
      borderBottom: '1px solid rgba(0, 0, 0, .125)',
      marginBottom: -1,
      minHeight: 56,
      '&$expanded': {
        minHeight: 56,
      },
    },
    content: {
      '&$expanded': {
        margin: '12px 0',
      },
    },
    expanded: {},
  })(MuiAccordionSummary);

  const AccordionDetails = withStyles((theme) => ({
    root: {
      padding: theme.spacing(2),
    },
  }))(MuiAccordionDetails);

  return (
    <>
      <Header />
      <Container>
        <Content>
          <p>
            Conheça as dúvidas mais frequentes. Caso elas permaneçam, fale com
            nosso suporte.
          </p>

          {questionsPlayer &&
            questionsPlayer.map((item) => (
              <Accordion key={item.id}>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon htmlColor={fontColor} />}
                  aria-controls={item.controls}
                  id={item.id}
                >
                  <Typography>{item.title}</Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Typography>{item.content}</Typography>
                </AccordionDetails>
              </Accordion>
            ))}
        </Content>
      </Container>
      <Footer />
    </>
  );
}
