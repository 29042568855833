import React, { useRef, useCallback, useState } from 'react';
import * as Yup from 'yup';
import { Form } from '@unform/web';
import { mask, unMask } from 'remask';
import { toast } from 'react-toastify';
import Button from '../../components/Button';
import Input from '../../components/Input';
import coloricon from '../../styles/coloricon';
import api from '../../services/api';
import { Container, Content } from './styles';
import infoPlatform from '../../config/infosPlatform';
import Header from '../../components/Header';
import Footer from '../../components/Footer';

export default function ContactForm() {
  const { IconUser, IconMail, IconPhone, IconSubject, IconDocument } =
    coloricon;

  const formRef = useRef(null);

  const [maskPhone, setMaskPhone] = useState('');

  const onChangePhone = (event) => {
    const originalValue = unMask(event.target.value);
    const maskedValue = mask(originalValue, [
      '(99) 9999-9999',
      '(99) 99999-9999',
    ]);
    setMaskPhone(maskedValue);
  };

  const handleSubmit = useCallback(async (data) => {
    try {
      const schemaValidation = Yup.object().shape({
        name: Yup.string()
          .required('O nome é obrigatório!')
          .min(3, 'O nome precisa mínimo 3 caracteres!')
          .max(75, 'O nome suporta máximo 75 caracteres!'),

        email: Yup.string()
          .email('O endereço é inválido!')
          .required('O e-mail é obrigatório!'),

        phone: Yup.string()
          .required('O telefone é obrigatório!')
          .min(14, 'O telefone precisa mínimo 10 dígitos!')
          .max(15, 'O telefo suporta máximo 11 dígitos!'),

        subject: Yup.string()
          .required('O assunto é obrigatório!')
          .min(3, 'O assunto precisa mínimo 3 caracteres!')
          .max(32, 'O assunto suporta máximo 32 caracteres!'),

        message: Yup.string()
          .required('A mensagem é obrigatória!')
          .min(3, 'A mensagem precisa mínimo 3 caracteres!')
          .max(300, 'A mensagem suporta máximo 300 caracteres!'),
      });

      await schemaValidation.validate(data, {
        abortEarly: false,
      });

      formRef.current.setErrors({});

      const response = await api.post('/support', data);

      toast.info(response.data.message);

      formRef.current.reset();
      setMaskPhone('');
    } catch (err) {
      if (err instanceof Yup.ValidationError) {
        const errorMessage = {};
        err.inner.forEach((error) => {
          errorMessage[error.path] = error.message;
        });
        formRef.current.setErrors(errorMessage);
      } else {
        toast.info(err.response.data.error);
      }
    }
  }, []);

  return (
    <>
      <Header />
      <Container>
        <Content>
          <h1>Contato</h1>
          <p>
            {`Envie o formulário ou contate-nos pelo ${infoPlatform.support_phone} e/ou ${infoPlatform.support_mail}`}
          </p>

          <Form ref={formRef} onSubmit={handleSubmit}>
            <Input
              id="name"
              name="name"
              inputProps={{ minLength: 3, maxLength: 75, size: 75 }}
              type="text"
              placeholder="Nome"
              icon={IconUser}
            />
            <Input
              name="email"
              type="email"
              placeholder="Email"
              icon={IconMail}
            />
            <Input
              name="phone"
              type="text"
              placeholder="DDD + Telefone"
              icon={IconPhone}
              inputProps={{ minLength: 14, maxLength: 15, size: 15 }}
              onChange={onChangePhone}
              value={maskPhone}
            />
            <Input
              name="subject"
              type="text"
              placeholder="Assunto"
              icon={IconSubject}
              inputProps={{ maxLength: 32, size: 32 }}
            />
            <Input
              multiline
              rows={3}
              isTextAreaInput
              name="message"
              placeholder="Mensagem"
              icon={IconDocument}
              inputProps={{ maxLength: 300 }}
            />
            <Button type="submit">Enviar</Button>
          </Form>
        </Content>
      </Container>
      <Footer />
    </>
  );
}
