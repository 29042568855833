import styled from 'styled-components';
import coloricon from '../../styles/coloricon';

const { backgroundHeader, secondaryColor, backgroundHeaderHover } = coloricon;

export const GroupCategory = styled.div`
  display: flex;
  width: 100%;
  flex-direction: row;
  align-items: center;
  justify-content: center;
`;

export const DetailsArea = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: 15px;

  > h2 {
    font-weight: bold;
    font-size: 1.3em;
    color: ${secondaryColor};
    margin: 7px 0px;
  }

  > h1 {
    font-size: 1em;
    margin: 7px 0px;
  }
`;

export const GroupCategoryItem = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: ${backgroundHeader};
  border-radius: 5px;
  padding: 30px 50px;
  margin: 15px;
  transition: all 0.3s ease-in-out;

  h1 {
    font-weight: bolder;
    margin: 7px 0px;
  }

  h2 {
    font-weight: bolder;
    font-size: 1.45em;
    margin: 7px 0px;
  }

  h3 {
    font-weight: bolder;
    font-size: 1.7em;
    margin: 7px 0px;
  }

  button {
    width: 100%;
  }

  &:hover {
    background-color: ${backgroundHeaderHover};
  }
`;
