import React from 'react';
import Header from '../../components/Header';
import Banner from '../../components/Banner';
import Footer from '../../components/Footer';

export default function Home() {
  return (
    <>
      <Header />
      <Banner />
      <Footer />
    </>
  );
}
