import React, { useRef, useCallback } from 'react';
import { toast } from 'react-toastify';
import { Link, useNavigate } from 'react-router-dom';
import { Form } from '@unform/web';
import * as Yup from 'yup';
import coloricon from '../../styles/coloricon';
import { Banner, Content, BannerArea, LogoArea } from './styles';
import Input from '../../components/Input';
import Button from '../../components/Button';
import api from '../../services/api';
import Footer from '../../components/Footer';
import Header from '../../components/Header';

export default function ForgotPass() {
  const navigation = useNavigate();
  const { IconMail, IconLogin } = coloricon;
  const formRef = useRef(null);

  const handleSubmit = useCallback(
    async (data) => {
      /* validacao */
      try {
        const schemaValidation = Yup.object().shape({
          email: Yup.string()
            .email('Endereço inválido!')
            .required('O e-mail é obrigatório!'),
        });

        formRef.current.setFieldError('email', '');

        await schemaValidation.validate(data, {
          abortEarly: false,
        });

        formRef.current.setErrors({});
        const { email } = data;
        const response = await api.post('/forgotPassManager', { email });
        toast.info(response.data.message);

        navigation({ pathname: '/loginManager' });
      } catch (err) {
        if (err instanceof Yup.ValidationError) {
          const errorMessages = {};

          err.inner.forEach((error) => {
            errorMessages[error.path] = error.message;
          });

          formRef.current.setErrors(errorMessages);
        } else {
          toast.info(err.response.data.error);
        }
      }
    },
    [navigation]
  );

  return (
    <>
      <Header />
      <Banner>
        <BannerArea>
          <Content>
            <LogoArea>
              {/* <img src={logo} alt={process.env.REACT_APP_NAME} /> */}
              <h1>{process.env.REACT_APP_NAME}</h1>
            </LogoArea>
            <h2>Recuperar Acesso Manager</h2>
            <Form ref={formRef} onSubmit={handleSubmit}>
              <Input
                icon={IconMail}
                name="email"
                type="email"
                placeholder="E-mail"
                required
              />
              <Button type="submit">Enviar</Button>
            </Form>
            <Link to="/loginPlayer">
              <IconLogin size={22} /> Login
            </Link>
          </Content>
        </BannerArea>
      </Banner>
      <Footer />
    </>
  );
}
