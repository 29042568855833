import pdfMake from 'pdfmake/build/pdfmake';
import pdfFonts from 'pdfmake/build/vfs_fonts';
import coloricon from '../styles/coloricon';

pdfMake.vfs = pdfFonts.pdfMake.vfs;

const { secondaryColor } = coloricon;

const generatePDF = (registrations, title, subtitle) => {
  const registrationsFormatted = registrations.filter(
    (item) =>
      item.confrontationDoesNotExist === false &&
      item.isAutomaticWinner === false
  );

  const totalBattles = registrationsFormatted.length;

  const rows = [];

  if (totalBattles > 0) {
    const registrationsRevision = registrationsFormatted.map((players) => {
      const player1 = `${players.player1.ranking} - ${players.player1.nickname} - ${players.player1.animal} - ${players.player1.representation}`;
      const player2 = `${players.player2.ranking} - ${players.player2.nickname} - ${players.player2.animal} - ${players.player2.representation}`;
      const data = { p1: player1, p2: player2 };
      return data;
    });

    for (const battle of registrationsRevision) {
      const data1 = {
        text: battle.p1,
        style: 'anotherStyle',
      };

      const data2 = {
        text: battle.p2,
        style: 'anotherStyle',
      };

      const row = [];
      row.push(data1);
      row.push(data2);

      rows.push(row);
    }
  }
  if (totalBattles === 0) {
    const data = ['Não existe dados', 'Não existe dados'];
    rows.push(data);
  }

  const docDefinition = {
    content: [
      {
        text: `${title.eventName}`,
        style: 'title',
      },
      {
        text: `${title.categoryName} - ${title.racingName}`,
        style: 'subtitle',
      },
      {
        text: `${totalBattles} ${subtitle}`,
        style: 'totalBattles',
      },
      {
        layout: 'lightHorizontalLines', // optional
        table: {
          headerRows: 1,
          widths: ['*', '*'],

          body: [
            [
              {
                text: 'Competidor 1',
                bold: true,
                fillColor: 'gray',
                lineHeight: 1.75,
                fontSize: 18,
                alignment: 'justify',
              },
              {
                text: 'Competidor 2',
                bold: true,
                fillColor: 'gray',
                lineHeight: 1.75,
                fontSize: 18,
                alignment: 'justify',
              },
            ],
            ...rows,
          ],
        },
      },
    ],
    styles: {
      title: {
        fontSize: 20,
        marginBottom: 10,
        bold: true,
        color: 'black',
        alignment: 'center',
      },
      subtitle: {
        fontSize: 18,
        marginBottom: 10,
        bold: true,
        color: secondaryColor,
        alignment: 'center',
      },
      totalBattles: {
        fontSize: 16.5,
        marginBottom: 10,
        color: 'black',
        alignment: 'center',
      },
      anotherStyle: {
        fontSize: 14,
        italics: false,
        lineHeight: 1.5,
      },
    },
  };

  pdfMake.createPdf(docDefinition).open();
};

export default generatePDF;
