import React, { useRef, useContext, useCallback } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { Form } from '@unform/web';
import * as Yup from 'yup';
import Header from '../../components/Header';
import Footer from '../../components/Footer';
import coloricon from '../../styles/coloricon';
import { AuthContext } from '../../context/AuthContext';
import { Banner, Content, LogoArea, BannerArea } from './styles';
import Input from '../../components/Input';
import Button from '../../components/Button';

export default function Login() {
  const navigation = useNavigate();
  const { IconMail, IconLock, IconKey, IconHome } = coloricon;
  const formRef = useRef(null);
  const { handleLoginManager } = useContext(AuthContext);

  const handleSubmitLogin = useCallback(
    async (data) => {
      try {
        const schemaValidation = Yup.object().shape({
          email: Yup.string()
            .email('Endereço inválido!')
            .required('O e-mail é obrigatório!'),

          password: Yup.string()
            .required('A senha é obrigatória!')
            .min(6, 'A senha precisa mínimo 6 dígitos')
            .max(12, 'A senha suporta máximo 12 dígitos'),
        });

        await schemaValidation.validate(data, {
          abortEarly: false,
        });

        formRef.current.setErrors({});

        const { email, password } = data;
        const response = await handleLoginManager(email, password);
        if (response) {
          const path = response;
          navigation({ pathname: path });
        }
      } catch (err) {
        if (err instanceof Yup.ValidationError) {
          const errorMessages = {};

          err.inner.forEach((error) => {
            errorMessages[error.path] = error.message;
          });

          formRef.current.setErrors(errorMessages);
        } else {
          toast.info(err.response.data.error);
        }
      }
    },
    [handleLoginManager, navigation]
  );

  return (
    <>
      <Banner>
        <Header />
        <BannerArea>
          <Content>
            <LogoArea />

            <h1>Acesso Administrador</h1>
            <Form ref={formRef} onSubmit={handleSubmitLogin}>
              <Input
                name="email"
                type="email"
                placeholder="E-mail"
                required
                icon={IconMail}
              />

              <Input
                name="password"
                type="password"
                autoComplete="on"
                placeholder="Senha"
                icon={IconLock}
                inputProps={{ minLength: 6, maxLength: 12, size: 12 }}
                required
              />

              <Button type="submit">Entrar</Button>
            </Form>

            <Link to="/forgotPassManager">
              <IconKey size={22} /> Esqueceu a senha?
            </Link>
            <Link to="/">
              <IconHome size={22} /> Página Inicial
            </Link>
          </Content>
        </BannerArea>
      </Banner>
      <Footer />
    </>
  );
}
