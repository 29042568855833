const equiprovas = {
  url:
    process.env.REACT_APP_ENV === 'development'
      ? 'http://localhost:3333'
      : 'https://api.equiprovasbahia.com.br',
  name: 'Equiprovas Bahia',
  support_mail: 'contato@equiprovasbahia.com.br',
  support_phone: '(73) 99183-7069',
  facebook: 'https://www.facebook.com/',
  instagram: 'https://www.instagram.com/',
  whatsapp: 'https://wa.me/557391837069',
};

export default equiprovas;
