import styled from 'styled-components';
import coloricon from '../../styles/coloricon';

const { secondaryColor, secondaryColorHover, fontColor } = coloricon;

export const Container = styled.div`
  width: 100%;
  max-width: 900px;
  display: flex;
  flex-direction: column;
  align-items: center;
  min-height: calc(100vh - 80px - 100px);
`;

export const InfoSecondary = styled.h1`
  color: ${fontColor};
  font-size: 1.1em;
  margin: 30px 0px;
  text-align: center;
`;

export const Title = styled.h1`
  color: ${secondaryColor};
  font-weight: bold;
  font-size: 1.3em;
  margin: 30px 0px 10px 0px;
  text-align: center;
`;

export const FileName = styled.p`
  color: ${fontColor};
  font-size: 1em;
  margin: 30px 0px;
  text-align: center;
`;

export const ActionArea = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-around;
  width: 100%;

  > button {
    max-width: 30%;
    width: 100%;

    > div {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;

      > svg {
        margin-right: 20px;
      }
    }
  }
`;

export const ButtonView = styled.a`
  cursor: pointer;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  color: ${fontColor};
  background-color: ${secondaryColor};
  transition: all 0.3s ease-in-out;
  font-weight: bold;
  margin-top: 10px;
  height: 45px;
  border: 0;
  border-radius: 5px;
  width: 30%;

  &:hover {
    background-color: ${secondaryColorHover};
  }

  > svg {
    color: ${fontColor};
    margin-right: 15px;
  }
`;
