import React from 'react';
import { Container, Content } from './styles';
import Header from '../../components/Header';
import Footer from '../../components/Footer';

export default function RegisterUser() {
  return (
    <>
      <Header />
      <Container>
        <Content>
          <h1>Plataforma EquiProvasBahia</h1>
          <h2>Seja bem vindo!</h2>
          <h3>
            Somos um sistema para gerenciamento de provas equinas. Aqui você
            consegue ver os próximos eventos, resultados, faz a sua inscrição e
            ainda conta com várias outras funcionalidades do nosso sistema. Para
            maiores informações ou interesse na utilização, entre em contato.
          </h3>
        </Content>
      </Container>
      <Footer />
    </>
  );
}
