import styled from 'styled-components';
import coloricon from '../../styles/coloricon';

const {
  backgroundHeader,
  fontColor,
  placeholderInputColor,
  backgroundTableHover,
  backgroundInput,
} = coloricon;

export const TableContainer = styled.table`
  width: 100%;
  border-collapse: collapse;
  font-family: 'Roboto Slab', serif;

  thead {
    > tr th {
      width: auto;
      background-color: ${backgroundHeader};
      color: ${fontColor};
      padding: 7.5px;
    }
  }

  tbody {
    > tr {
      width: auto;
      color: ${fontColor};
      text-align: center;
      vertical-align: middle;
      padding: 5px 0px;
      border-bottom: 2px dotted ${placeholderInputColor};
      transition: all 0.2s ease-in-out;

      :hover {
        background-color: ${backgroundTableHover};
      }
    }

    > tr td {
      padding: 3px 0px;
    }
  }
`;

export const Select = styled.select`
  position: relative;
  background-color: ${fontColor};
  height: 40px;
  border: 0;
  outline: 0;
  border-radius: 4px;
  padding: 0px 3px;
  font-family: 'Roboto Slab', serif;
  font-size: 1em;
  color: ${backgroundInput};

  @media (max-width: 650px) {
    margin-top: 15px;
  }
`;

export const Input = styled.input`
  margin-left: 3px;
  width: 50px;
  text-align: center;
  height: 40px;
  border-radius: 4px;
  border: 0;
  outline: 0;
  color: ${backgroundInput};
  background-color: ${fontColor};

  @media (max-width: 650px) {
    height: 30px;
    margin-left: 15px;
  }

  ::-webkit-inner-spin-button {
    -webkit-appearance: none;
  }
`;

export const Button = styled.button`
  border: 0;
  outline: 0;
  background-color: transparent;
  padding: 0px 5px;
  height: 40px;

  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;

  > svg {
    font-size: 2em;
    font-weight: bold;
    color: ${fontColor};
  }
`;

export const PaginationArea = styled.div`
  width: 100%;
  height: 50px;
  margin-top: 15px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;

  @media (max-width: 650px) {
    flex-direction: column;
    justify-content: center;
    height: auto;

    > span {
      margin-top: 15px;
    }
  }
`;
