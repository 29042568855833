import React, { useState, useEffect } from 'react';
import { toast } from 'react-toastify';
import { useParams, Link } from 'react-router-dom';
import coloricon from '../../styles/coloricon';
import ReactTable from '../../components/ReactTable';
import Loading from '../../components/Loading';
import BreadCrumbs from '../../components/BreadcrumbsMUI';
import api from '../../services/api';
import { IconArea } from './styles';

export default function RegistrationByManager() {
  const { idEvent, idCategory } = useParams();
  const [loading, setLoading] = useState(true);
  const { IconLogin } = coloricon;
  const [players, setPlayers] = useState([]);

  useEffect(() => {
    async function loaderAnimal() {
      try {
        const response = await api.get('/playersByManager');
        setPlayers(response.data.players);
        setLoading(false);
      } catch (error) {
        setLoading(false);
        toast.info(error.response.data.error || 'Falha no carregamento!');
      }
    }
    loaderAnimal();
  }, []);

  return loading ? (
    <Loading />
  ) : (
    <>
      <BreadCrumbs
        nameOfCurrentPage="Competidores"
        arrayOfTitleAndPath={[
          {
            title: 'Início',
            path: '/dashManager',
          },
          {
            title: 'Inscrições',
            path: '/eventsByManager',
          },
          {
            title: 'Detalhes',
            path: `/eventDetailsManager/${idEvent}`,
          },
        ]}
      />
      {players && players.length === 0 && (
        <h1>
          Não existem competidores cadastrados para seguir com a inscrição.
        </h1>
      )}
      {players && players.length > 0 && (
        <>
          <div style={{ marginTop: 30 }} />
          <ReactTable
            data={players}
            columns={[
              {
                Header: 'Nome',
                accessor: 'name',
                width: '70%',
              },
              {
                Header: 'Apelido',
                accessor: 'nickname',
              },
              { Header: 'Celular', accessor: 'phone' },
              {
                Header: 'Inscrição',
                Cell: ({ row }) => (
                  <IconArea>
                    <Link
                      to={`/registrationByManagerNextStep/${idEvent}/${idCategory}/${row.original.id}`}
                    >
                      <IconLogin />
                    </Link>
                  </IconArea>
                ),
              },
            ]}
          />
        </>
      )}
    </>
  );
}
