import styled from 'styled-components';
import coloricon from '../../styles/coloricon';

const { secondaryColor, backgroundHeader, fontColor } = coloricon;

export const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  min-height: 100vh;
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  max-width: 900px;
  text-align: center;
  padding: 30px;
  border-radius: 5px;
  background: ${backgroundHeader};

  @media (max-width: 768px) {
    font-size: 15px;
  }

  @media (max-width: 425px) {
    font-size: 14px;
  }

  @media (max-width: 375px) {
    font-size: 13px;
  }

  @media (max-width: 320px) {
    font-size: 12px;
  }

  > h1 {
    color: ${secondaryColor};
    margin-top: 20px;
  }

  > div {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    margin-top: 20px;

    > svg {
      font-size: 20px;
      color: ${secondaryColor};
    }

    > p {
      font-size: 1.3em;
      margin: 0px 10px;
    }
  }

  > a {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    margin-top: 20px;
    transition: all 0.3s ease-in-out;
    color: ${fontColor};

    :hover {
      svg,
      p {
        color: ${secondaryColor};
      }
    }

    > svg {
      font-size: 1.5em;
      transition: all 0.3s ease-in-out;
    }

    > p {
      font-size: 1.3em;
      margin: 0px 10px;
      transition: all 0.3s ease-in-out;
    }
  }
`;
