import styled from 'styled-components';
import coloricon from '../../styles/coloricon';

const { secondaryColor, secondaryColorHover } = coloricon;

export const GroupButton = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-around;
  margin-bottom: 15px;

  @media (max-width: 768px) {
    flex-direction: column;
  }

  > button {
    width: 100%;
    margin: 10px;
  }
`;

export const ButtonArea = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  margin: 15px 0px;

  button {
    width: 100%;
  }
`;

export const IconArea = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  cursor: pointer;

  > a {
    color: ${secondaryColor};
    cursor: pointer;
    transition: all 0.3s ease-in-out;

    &:hover {
      color: ${secondaryColorHover};
    }
  }

  > svg {
    color: ${secondaryColor};
    cursor: pointer;
    transition: all 0.3s ease-in-out;

    &:hover {
      color: ${secondaryColorHover};
    }
  }
`;
