import React, { useState } from 'react';
import { useParams, useLocation } from 'react-router-dom';

import Box from '@mui/material/Box';
import Tab from '@mui/material/Tab';
import TabContext from '@material-ui/lab/TabContext';
import TabList from '@material-ui/lab/TabList';
import TabPanel from '@material-ui/lab/TabPanel';
import { makeStyles } from '@material-ui/core/styles';
import BreadCrumbs from '../../components/BreadcrumbsMUI';
import { TitleDetails } from './stepsGlobalStyles';

// abas separadas
import Step1 from './Step1';
import Step2 from './Step2';
import Step3 from './Step3';
import Step4 from './Step4';
import Step5 from './Step5';
import Step6 from './Step6';
import Step7 from './Step7';
import Step8 from './Step8';
import Points from './Points';

export default function Steps() {
  const { idEvent, idCategory, idRacing } = useParams();
  const { state } = useLocation();
  const [value, setValue] = useState('1');

  const handleChangeTabs = (event, newValue) => {
    setValue(newValue);
  };

  const useStyles = makeStyles({
    root: {
      '& .MuiButtonBase-root': {
        fontFamily: 'Roboto Slab , serif',
      },
    },
  });

  const classes = useStyles();

  return (
    <>
      <BreadCrumbs
        nameOfCurrentPage="Tomada de Tempo"
        arrayOfTitleAndPath={[
          {
            title: 'Início',
            path: '/dashManager',
          },
          {
            title: 'Eventos',
            path: '/classification',
          },
          {
            title: 'Categorias',
            path: `/classificationEventCategory/${idEvent}`,
          },
          {
            title: 'Corridas',
            path: `/racings/${idEvent}/${idCategory}`,
          },
        ]}
      />
      {/* dados detalhados do evento em análise */}
      <TitleDetails>
        {state.eventName} - {state.categoryName} - {state.racingName}
      </TitleDetails>

      {/* implantacao das tabs */}
      <div style={{ marginTop: 15 }} />
      <Box
        sx={{
          width: '100%',
          typography: 'body1',
          fontFamily: 'inherit',
        }}
        className={classes.root}
      >
        <TabContext value={value}>
          <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
            <TabList
              onChange={handleChangeTabs}
              aria-label="Informações de classificação"
              textColor="inherit"
              indicatorColor="primary"
              variant="fullWidth"
              fontFamily="inherit"
            >
              <Tab label="1ª fase" value="1" />
              <Tab label="2ª fase" value="2" />
              <Tab label="3ª fase" value="3" />
              <Tab label="4ª fase" value="4" />
              <Tab label="Oitavas" value="5" />
              <Tab label="Quartas" value="6" />
              <Tab label="Semi" value="7" />
              <Tab label="Final" value="8" />
              <Tab label="Pontos" value="9" />
            </TabList>
          </Box>
          <TabPanel value="1">
            <Step1
              idEvent={idEvent}
              idCategory={idCategory}
              idRacing={idRacing}
              state={state}
            />
          </TabPanel>
          <TabPanel value="2">
            <Step2
              idEvent={idEvent}
              idCategory={idCategory}
              idRacing={idRacing}
              state={state}
            />
          </TabPanel>
          <TabPanel value="3">
            <Step3
              idEvent={idEvent}
              idCategory={idCategory}
              idRacing={idRacing}
              state={state}
            />
          </TabPanel>
          <TabPanel value="4">
            <Step4
              idEvent={idEvent}
              idCategory={idCategory}
              idRacing={idRacing}
              state={state}
            />
          </TabPanel>
          <TabPanel value="5">
            <Step5
              idEvent={idEvent}
              idCategory={idCategory}
              idRacing={idRacing}
              state={state}
            />
          </TabPanel>
          <TabPanel value="6">
            <Step6
              idEvent={idEvent}
              idCategory={idCategory}
              idRacing={idRacing}
              state={state}
            />
          </TabPanel>
          <TabPanel value="7">
            <Step7
              idEvent={idEvent}
              idCategory={idCategory}
              idRacing={idRacing}
              state={state}
            />
          </TabPanel>
          <TabPanel value="8">
            <Step8
              idEvent={idEvent}
              idCategory={idCategory}
              idRacing={idRacing}
              state={state}
            />
          </TabPanel>
          <TabPanel value="9">
            <Points
              idEvent={idEvent}
              idCategory={idCategory}
              idRacing={idRacing}
              state={state}
            />
          </TabPanel>
        </TabContext>
      </Box>
    </>
  );
}
