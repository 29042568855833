import React from 'react';
import BreadCrumbsMUI from '../../components/BreadcrumbsMUI';
import { Container } from './styles';

export default function DashPlayer() {
  return (
    <>
      <BreadCrumbsMUI
        nameOfCurrentPage="Boas vindas"
        arrayOfTitleAndPath={[
          {
            title: 'Início',
            path: '/dashPlayer',
          },
        ]}
      />
      <Container>
        <h1 style={{ marginTop: 50 }}>
          Seja bem vindo a plataforma Equiprovas Bahia!
        </h1>
      </Container>
    </>
  );
}
