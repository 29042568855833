import {
  FiCamera,
  FiTrash2,
  FiMail,
  FiMenu,
  FiPhone,
  FiUser,
  FiUsers,
  FiMessageSquare,
  FiUpload,
  FiClock,
  FiPercent,
  FiLock,
  FiLogIn,
  FiSmile,
  FiEdit,
  FiShoppingCart,
  FiAlertTriangle,
  FiDownload,
  FiUserPlus,
  FiMonitor,
  FiDollarSign,
  FiMapPin,
  FiUserCheck,
  FiMinus,
  FiPlus,
  FiThumbsUp,
  FiCode,
  FiChevronLeft,
  FiUserX,
  FiBook,
  FiHelpCircle,
  FiBookOpen,
  FiSettings,
  FiImage,
  FiTool,
  FiBriefcase,
  FiScissors,
  FiFileText,
  FiGift,
  FiFilter,
  FiLogOut,
  FiBarChart2,
  FiInstagram,
  FiFacebook,
  FiCopy,
  FiCheckCircle,
  FiSave,
} from 'react-icons/fi';
import {
  FaCity,
  FaSearch,
  FaBarcode,
  FaChevronRight,
  FaRegHandshake,
  FaRegIdCard,
  FaCalculator,
  FaSortAmountDown,
  FaWhatsapp,
  FaRegFilePdf,
  FaHorse,
} from 'react-icons/fa';
import {
  MdMyLocation,
  MdSubject,
  MdComputer,
  MdNotifications,
} from 'react-icons/md';
import { RiCoupon2Line, RiProductHuntLine } from 'react-icons/ri';
import { SiMicrosoftexcel } from 'react-icons/si';
import {
  BsCreditCard,
  BsCalendar,
  BsCardText,
  BsInfoCircle,
} from 'react-icons/bs';
import { BiKey } from 'react-icons/bi';
import { AiOutlineHome } from 'react-icons/ai';
import { GoPackage } from 'react-icons/go';
import { CgToolbox, CgClose } from 'react-icons/cg';
import { ImManWoman, ImQrcode } from 'react-icons/im';

const coloricon = {
  background: '#312e38',
  backgroundInput: '#232129',
  textButton: '#232129',
  placeholderInputColor: '#666360',
  backgroundHeader: '#28262e',
  backgroundHeaderHover: '#4c4a52',
  backgroundTableHover: '#3a383e',
  fontColor: '#f4ede8',
  secondaryColorHover: '#0d6efd',
  secondaryColor: '#034ebd',

  // icons
  IconCamera: FiCamera,
  IconDelete: FiTrash2,
  IconEdit: FiEdit,
  IconCity: FaCity,
  IconLocation: MdMyLocation,
  IconAccess: MdComputer,
  IconPackage: GoPackage,
  IconComputer: FiMonitor,
  IconNotification: MdNotifications,
  IconMap: FiMapPin,
  IconUpload: FiUpload,
  IconDetails: BsInfoCircle,
  IconClose: CgClose,
  IconArticles: FiBook,
  IconHelp: FiHelpCircle,
  IconHistory: FiBookOpen,
  IconImage: FiImage,
  IconTool: FiTool,
  IconCut: FiScissors,
  IconGift: FiGift,
  IconFilter: FiFilter,
  IconPDF: FaRegFilePdf,
  IconCopy: FiCopy,
  IconCheckCircle: FiCheckCircle,
  IconSave: FiSave,
  IconMenu: FiMenu,

  IconAdd: FiPlus,
  IconSub: FiMinus,
  IconService: FiBriefcase,
  IconProduct: RiProductHuntLine,
  IconSearch: FaSearch,
  IconAlert: FiAlertTriangle,

  IconExcel: SiMicrosoftexcel,
  IconCreditCard: BsCreditCard,
  IconBarCode: FaBarcode,
  IconChevron: FaChevronRight,
  IconDownload: FiDownload,

  // precos
  IconPercentage: FiPercent,
  IconPrice: FiDollarSign,
  IconPartner: FaRegHandshake,
  IconCalculator: FaCalculator,
  IconReport: FiBarChart2,
  IconCart: FiShoppingCart,
  IconCoupon: RiCoupon2Line,
  IconFaceOk: FiThumbsUp,
  IconCode: FiCode,
  IconAmountDecrement: FaSortAmountDown,
  IconQrCode: ImQrcode,

  // usuarios e logins
  IconMail: FiMail,
  IconPhone: FiPhone,
  IconUser: FiUser,
  IconUsers: FiUsers,
  IconAddAccount: FiUserPlus,
  IconRemoveAccount: FiUserX,
  IconLock: FiLock,
  IconLogin: FiLogIn,
  IconLogout: FiLogOut,
  IconHome: AiOutlineHome,
  IconKey: BiKey,
  IconSmile: FiSmile,
  IconNameCompany: FaRegIdCard,
  IconDocument: FiFileText,
  IconCheck: FiUserCheck,
  IconSex: ImManWoman,
  IconHorse: FaHorse,

  // textos
  IconText: BsCardText,
  IconSubject: MdSubject,
  IconTextArea: FiMessageSquare,
  IconBack: FiChevronLeft,

  // calendario e hora
  IconClock: FiClock,
  IconCalendar: BsCalendar,
  IconToolBox: CgToolbox,
  IconSettings: FiSettings,

  // redes sociais
  IconFacebook: FiFacebook, // FaFacebookF,
  IconInstagram: FiInstagram, // ImInstagram,
  IconWhatsApp: FaWhatsapp,
};

export default coloricon;
