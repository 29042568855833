const questionsPlayer = [
  {
    controls: 'panel1-content',
    id: 'panel1-header',
    title: 'O uso da plataforma é gratuito?',
    content: 'Sim. A utilização é 100% gratuita.',
  },
  {
    controls: 'panel2-content',
    id: 'panel2-header',
    title: 'Como faço o cancelamento da minha conta?',
    content:
      'O cancelamento pode ser realizado no menu principal, na opção "Desativar conta".',
  },
  {
    controls: 'panel4-content',
    id: 'panel4-header',
    title: 'Como faço para me inscrever nos eventos?',
    content: `É preciso ter uma conta na plataforma e estar logado na área de competidor. Depois basta acessar o menu de eventos se cadastrar no evento e categoria desejado.".`,
  },
];

export { questionsPlayer };
