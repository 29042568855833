import React, { useEffect, useState, useRef, useCallback } from 'react';
import { toast } from 'react-toastify';
import { Form } from '@unform/web';
import Loading from '../../../components/Loading';
import coloricon from '../../../styles/coloricon';
import Button from '../../../components/Button';
import Modal from '../../../components/Modal';
import Input from '../../../components/Input';
import ReactTable from '../../../components/ReactTable';
import BlockUI from '../../../components/BlockUi';
import {
  Container,
  ContentBattle,
  AutoClassification,
  NoHaveBattle,
  GroupPlayer,
  GroupInputPlayer,
  Winner,
  AlertFinished,
} from '../stepsGlobalStyles';
import api from '../../../services/api';
import generatePDF from '../../../utils/generatePDFFromBattles';

export default function Step5({ idEvent, idCategory, idRacing, state }) {
  const formRef = useRef(null);
  const [battles, setBattles] = useState([]);
  const [loading, setLoading] = useState(true);
  const [finished, setFinished] = useState(false);
  const [openBlockUI, setOpenBlockUI] = useState(false);
  const [openModalFinished, setOpenModalFinished] = useState(false);
  const { IconClock, IconSave, IconAlert } = coloricon;
  const battleNumber = '5';
  const previousStep = '4';
  const currentStep = '5';

  const loaderBattles = useCallback(async (idEve, idCate, idRac) => {
    try {
      const response = await api.get(
        `/preparePlayerFromBattle/${idEve}/${idCate}/${idRac}/${battleNumber}/${previousStep}/${currentStep}`
      );
      setBattles(response.data.registrations);
      setFinished(response.data.finished);
      setLoading(false);
    } catch (error) {
      toast.info(
        error?.response?.data.error || 'Falha no carregamento das informações!'
      );
      setLoading(false);
    }
  }, []);

  useEffect(() => {
    loaderBattles(idEvent, idCategory, idRacing);
  }, [idCategory, idEvent, idRacing, loaderBattles]);

  const handleOpenModalFinished = useCallback(() => {
    setOpenModalFinished(true);
  }, []);

  const handleCloseModalFinished = useCallback(() => {
    setOpenModalFinished(false);
  }, []);

  const handleUpdateTime = useCallback(
    async (key) => {
      try {
        setOpenBlockUI(true);
        // tempo enviado para atualizacao
        const time1 = formRef.current.getFieldValue(`time1-${key}`);
        const time2 = formRef.current.getFieldValue(`time2-${key}`);

        //! para acionar o save time precisa ao menos enviar um número preenchido
        if (
          (!time1 && !time2) ||
          (time1 && time1 === 0) ||
          (time2 && time2 === 0)
        ) {
          toast.info(
            'É preciso preencher ao menos um dos campos e com valor diferente de zero!'
          );
          return;
        }

        // descobrindo o adversario dele e respectivos tempos
        const getOpponent = battles.find(
          (item) =>
            item.player1?.registration_id === key ||
            item.player2?.registration_id === key
        );

        const keyPlayer1 = getOpponent?.player1?.registration_id;
        const keyPlayer2 = getOpponent?.player2?.registration_id;
        const getKeyFromOpponent = key === keyPlayer1 ? keyPlayer2 : keyPlayer1;
        const time3 = formRef.current.getFieldValue(
          `time1-${getKeyFromOpponent}`
        );
        const time4 = formRef.current.getFieldValue(
          `time2-${getKeyFromOpponent}`
        );

        const data = {
          time1ToUpdate: time1 ? Number(time1) : null,
          time2ToUpdate: time2 ? Number(time2) : null,
          time3FromOpponent: time3 ? Number(time3) : null,
          time4FromOpponent: time4 ? Number(time4) : null,
          idRegistrationToUpdate: key,
          idRegistrationFromOpponent: getKeyFromOpponent,
          idRacing,
          numberOfStep: currentStep,
        };

        await api.post('/stepMain', data);
        toast.info('Registro atualizado!');
        setOpenBlockUI(false);
      } catch (error) {
        setOpenBlockUI(false);
        toast.info(
          error?.response?.data.error || 'Falha na atualização do item'
        );
      }
      await loaderBattles(idEvent, idCategory, idRacing);
    },
    [idCategory, idEvent, idRacing, loaderBattles, battles]
  );

  const handleValidateSendNextStep = useCallback(
    async (isNeedShowToast = true) => {
      const response = await api.get(
        `/preparePlayerFromBattle/${idEvent}/${idCategory}/${idRacing}/${battleNumber}/${previousStep}/${currentStep}`
      );
      const results = response.data?.registrations;
      if (results?.length === 0) {
        toast.info(
          'É preciso preencher todos os campos para calcular os confrontos da próxima fase!'
        );
        return false;
      }
      // nao preciso analisar todos, apenas os diferentes de auto e inexistente
      const resultsWithoutAutoAndEmpty = results.filter(
        (item) =>
          item.confrontationDoesNotExist === false &&
          item.isAutomaticWinner === false
      );

      const errorList = resultsWithoutAutoAndEmpty.filter(
        (item) =>
          !item?.player1?.resultCurrent ||
          !item?.player1?.time1Current ||
          !item?.player1?.time2Current ||
          !item?.player2?.resultCurrent ||
          !item?.player2?.time1Current ||
          !item?.player2?.time2Current
      );
      if (errorList?.length > 0) {
        toast.info(
          `É preciso preencher os todos os campos para calcular os próximos confrontos.Exemplo: Confronto ${errorList[0]?.pair}-${errorList[0]?.player1?.nickname} Vs ${errorList[0]?.player2?.nickname}`
        );
        return false;
      }
      isNeedShowToast
        ? toast.info(`Não existem pendências para avançar para a próxima fase!`)
        : null;

      return true;
    },
    [idEvent, idCategory, idRacing]
  );

  const handleFinishedStep = useCallback(async () => {
    try {
      const isValid = await handleValidateSendNextStep(false);
      if (isValid === false) {
        handleCloseModalFinished();
        return;
      }
      const response = await api.put(
        `/finished/event/${idEvent}/category/${idCategory}/racing/${idRacing}/step/${Number(
          currentStep
        )}`
      );
      handleCloseModalFinished();
      toast.info(
        response?.data.message || 'A etapa foi finalizada com sucesso!'
      );
    } catch (error) {
      toast.info(
        error?.response.data.error || 'Falha no processamento das informações!'
      );
    }
  }, [
    handleValidateSendNextStep,
    idCategory,
    idEvent,
    idRacing,
    handleCloseModalFinished,
  ]);

  const handleGeneratePDF = useCallback(() => {
    generatePDF(battles, state, 'Confrontos Oitavas de Final');
  }, [battles, state]);

  return loading === true ? (
    <Loading />
  ) : (
    <>
      <BlockUI blocking={openBlockUI} />
      <Container>
        {battles.length === 0 && (
          <p>
            Os dados dessa página estarão disponíveis quando concluir a etapa
            anterior!
          </p>
        )}
        {finished && finished === true && (
          <AlertFinished>
            <IconAlert />
            <h2>Essa etapa já foi finalizada!</h2>
          </AlertFinished>
        )}
        {battles.length > 0 && (
          <>
            {finished === false && (
              <div>
                <Button type="button" onClick={handleValidateSendNextStep}>
                  Verificar pendências - Total: {battles.length} confrontos
                </Button>
                <Button type="button" onClick={handleOpenModalFinished}>
                  Encerrar etapa
                </Button>
                <Button type="button" onClick={handleGeneratePDF}>
                  Gerar PDF
                </Button>
              </div>
            )}
            <Form ref={formRef}>
              <ReactTable
                data={battles}
                hiddenColumn={finished ? ['Editar', 'Excluir'] : []}
                columns={[
                  {
                    Header: 'Duelo',
                    width: '15%',
                    Cell: ({ row }) => (
                      <>
                        {row.original.isAutomaticWinner && <span>Auto</span>}
                        {row.original.confrontationDoesNotExist && (
                          <span>Não</span>
                        )}
                        {!row.original.isAutomaticWinner &&
                          !row.original.confrontationDoesNotExist && (
                            <span>Sim</span>
                          )}
                      </>
                    ),
                  },

                  {
                    Header: 'Mapa',
                    width: '20%',
                    Cell: ({ row }) => <span>{row.original?.pair}</span>,
                  },

                  {
                    Header: 'Dados do Confronto',
                    width: '65%',
                    Cell: ({ row }) => (
                      <>
                        {row.original?.confrontationDoesNotExist === true && (
                          <NoHaveBattle>
                            <p>Não existem competidores com esse ranking!</p>
                          </NoHaveBattle>
                        )}
                        {row.original?.isAutomaticWinner === true && (
                          <AutoClassification>
                            <p>Competidor classificado automaticamente:</p>
                            <h1>
                              {row.original?.winner?.ranking}º -{' '}
                              {row.original?.winner?.nickname} -{' '}
                              {row.original?.winner?.animal} -{' '}
                              {row.original?.winner?.representation}
                            </h1>
                          </AutoClassification>
                        )}
                        {row.original?.confrontationDoesNotExist === false &&
                          row.original?.isAutomaticWinner === false && (
                            <ContentBattle>
                              <GroupPlayer>
                                <h1>
                                  {row.original?.player1?.ranking}º -{' '}
                                  {row.original?.player1?.nickname} -{' '}
                                  {row.original?.player1?.animal} -{' '}
                                  {row.original?.player1?.representation}
                                </h1>
                                <GroupInputPlayer>
                                  <div>
                                    <Input
                                      type="number"
                                      name={`time1-${row?.original?.player1?.registration_id}`}
                                      placeholder="Tempo1"
                                      icon={IconClock}
                                      initialData={
                                        row?.original?.player1?.time1Current ||
                                        null
                                      }
                                      disabled={finished}
                                    />
                                  </div>
                                  <div>
                                    <Input
                                      type="number"
                                      name={`time2-${row?.original?.player1?.registration_id}`}
                                      placeholder="Tempo2"
                                      icon={IconClock}
                                      initialData={
                                        row?.original?.player1?.time2Current ||
                                        null
                                      }
                                      disabled={finished}
                                    />
                                  </div>
                                  <div>
                                    <Input
                                      type="number"
                                      name={`resultCurrent1-${row?.original?.player1?.registration_id}`}
                                      placeholder="Resultado1"
                                      disabled
                                      style={{ opacity: 0.7 }}
                                      icon={IconClock}
                                      initialData={
                                        row?.original?.player1?.resultCurrent ||
                                        null
                                      }
                                    />
                                  </div>
                                  {finished && finished === false && (
                                    <div>
                                      <IconSave
                                        onClick={() =>
                                          handleUpdateTime(
                                            row?.original?.player1
                                              ?.registration_id
                                          )
                                        }
                                      />
                                    </div>
                                  )}
                                </GroupInputPlayer>
                              </GroupPlayer>
                              <GroupPlayer>
                                <h1>
                                  {row.original?.player2?.ranking}º -{' '}
                                  {row.original?.player2?.nickname} -{' '}
                                  {row.original?.player2?.animal} -{' '}
                                  {row.original?.player2?.representation}
                                </h1>
                                <GroupInputPlayer>
                                  <div>
                                    <Input
                                      type="number"
                                      name={`time1-${row?.original?.player2?.registration_id}`}
                                      placeholder="Tempo1"
                                      icon={IconClock}
                                      initialData={
                                        row?.original?.player2?.time1Current ||
                                        null
                                      }
                                      disabled={finished}
                                    />
                                  </div>
                                  <div>
                                    <Input
                                      type="number"
                                      name={`time2-${row?.original?.player2?.registration_id}`}
                                      placeholder="Tempo2"
                                      icon={IconClock}
                                      initialData={
                                        row?.original?.player2?.time2Current ||
                                        null
                                      }
                                      disabled={finished}
                                    />
                                  </div>
                                  <div>
                                    <Input
                                      type="number"
                                      name={`resultCurrent2-${row?.original?.player2?.registration_id}`}
                                      placeholder="Resultado2"
                                      disabled
                                      style={{ opacity: 0.7 }}
                                      icon={IconClock}
                                      initialData={
                                        row?.original?.player2?.resultCurrent ||
                                        null
                                      }
                                    />
                                  </div>
                                  {finished && finished === false && (
                                    <div>
                                      <IconSave
                                        onClick={() =>
                                          handleUpdateTime(
                                            row?.original?.player2
                                              ?.registration_id
                                          )
                                        }
                                      />
                                    </div>
                                  )}
                                </GroupInputPlayer>
                              </GroupPlayer>
                              <Winner>
                                {row.original?.winner?.nickname && (
                                  <h1>
                                    Vencedor: {row.original?.winner?.ranking}º -{' '}
                                    {row.original?.winner?.nickname} -{' '}
                                    {row.original?.winner?.animal} -{' '}
                                    {row.original?.winner?.representation}
                                  </h1>
                                )}
                              </Winner>
                            </ContentBattle>
                          )}
                      </>
                    ),
                  },
                ]}
              />
            </Form>
          </>
        )}
      </Container>
      {/* modal finished */}
      <Modal
        isOpen={openModalFinished}
        onClickClose={handleCloseModalFinished}
        onClickAction={handleFinishedStep}
        title="Confirma o encerramento da etapa?"
        nameOfItem="Encerramento da Etapa: Oitavas de Final"
      />
    </>
  );
}
