import styled from 'styled-components';
import coloricon from '../../styles/coloricon';

const { secondaryColor, backgroundHeaderHover, backgroundHeader } = coloricon;

export const Container = styled.div`
  display: flex;
  flex-direction: row;

  & + div {
    margin-top: 10px;
  }

  p {
    margin-left: 15px;
  }

  input[type='checkbox'] {
    position: relative;
    cursor: pointer;
    width: 20px;
    height: 20px;

    ::before {
      content: '';
      display: block;
      position: absolute;
      width: 20px;
      height: 20px;
      top: 0;
      left: 0;
      border: 2px solid ${backgroundHeaderHover};
      //border-radius: 3px;
      background-color: ${backgroundHeader};
    }

    :checked::after {
      content: '';
      display: block;
      width: 6px;
      height: 13px;
      border: solid ${secondaryColor};
      border-width: 0 2px 2px 0;
      -webkit-transform: rotate(45deg);
      -ms-transform: rotate(45deg);
      transform: rotate(45deg);
      position: absolute;
      top: 2px;
      left: 7px;
    }
  }
`;

export const ContainerError = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: transparent;
  width: 100%;
  margin-bottom: 10px;

  span {
    display: flex;
    align-items: center;
    color: ${secondaryColor};
    font-size: 14px;
  }

  svg {
    color: ${secondaryColor};
    margin-right: 5px;
  }
`;
