import React, { useState, useEffect, useCallback, useRef } from 'react';
import { toast } from 'react-toastify';
import { useParams } from 'react-router-dom';
import { Form } from '@unform/web';
import Modal from '../../components/Modal';
import api from '../../services/api';
import coloricon from '../../styles/coloricon';
import ReactTable from '../../components/ReactTable';
import Loading from '../../components/Loading';
import Input from '../../components/Input';
import BreadCrumbs from '../../components/BreadcrumbsMUI';
import { GroupButton, IconArea } from './styles';
import ExportCSV from '../../utils/ExportCSV';

export default function PlayerByManager() {
  const { IconPrice, IconEdit } = coloricon;
  const [loading, setLoading] = useState(true);
  const [openModal, setOpenModal] = useState(false);
  const [itemModal, setItemModal] = useState({});
  const [players, setPlayers] = useState([]);
  const formRef = useRef(null);
  const { idEvent } = useParams();

  const fieldNames = {
    id: 'Código',
    registration_number: 'numero_inscricao',
    category: 'categoria',
    playerName: 'nome_competidor',
    amount: 'Valor',
    playerNickname: 'apelido_competidor',
    playerPhone: 'celular_competidor',
    paid: 'pagamento_efetuado',
  };

  useEffect(() => {
    async function loaderPlayers() {
      try {
        const response = await api.get(`/financialsDetails/${idEvent}`);
        setPlayers(response.data);
        setLoading(false);
      } catch (error) {
        setLoading(false);
        toast.info(error?.response.data.error || 'Falha no carregamento!');
      }
    }
    loaderPlayers();
  }, [idEvent]);

  const handleOpenModal = useCallback((model) => {
    setItemModal(model);
    setOpenModal(true);
  }, []);

  const handleCloseModal = useCallback(() => {
    setOpenModal(false);
    setItemModal({});
  }, []);

  const handleUpdate = useCallback(async () => {
    try {
      const finalValue = formRef?.current?.getFieldValue('discount_value');
      const finalValueFormatted =
        !finalValue || finalValue === 0 || finalValue === ''
          ? 0
          : Number(finalValue);
      await api.post('/updatePayment', {
        idRegistration: itemModal.id,
        paid: itemModal.paid !== 'Sim',
        final_value: finalValueFormatted,
      });
      const findIndex = players.findIndex((item) => item.id === itemModal.id);
      const playersFormatted = players;
      playersFormatted[findIndex].paid =
        itemModal.paid === 'Sim' ? 'Não' : 'Sim';
      playersFormatted[findIndex].final_value =
        itemModal.paid === 'Sim'
          ? finalValueFormatted
          : playersFormatted[findIndex].default_value;

      setPlayers(playersFormatted);
      toast.info('O pagamento foi atualizado com sucesso!');
      setOpenModal(false);
      setItemModal({});
    } catch (error) {
      toast.info(
        error?.response.data.message ||
          'Falha no processamento das informações!'
      );
    }
  }, [itemModal, players]);

  return loading === true ? (
    <Loading />
  ) : (
    <>
      <BreadCrumbs
        nameOfCurrentPage="Inscrições"
        arrayOfTitleAndPath={[
          {
            title: 'Início',
            path: '/dashManager',
          },
          {
            title: 'Finanças',
            path: '/financial',
          },
        ]}
      />
      {players && players.length === 0 && (
        <p>Não existem registros para exibir.</p>
      )}

      {players && players.length > 0 && (
        <>
          <GroupButton>
            <div>
              <ExportCSV
                fileName="dados_pagamento"
                csvData={players}
                fieldNames={fieldNames}
              />
            </div>
          </GroupButton>
          <ReactTable
            data={players}
            columns={[
              {
                Header: 'Categoria',
                accessor: 'category',
                width: '70%',
              },

              {
                Header: 'Apelido',
                accessor: 'playerNickname',
              },
              { Header: 'Celular', accessor: 'playerPhone' },
              { Header: 'Valor', accessor: 'final_value' },
              { Header: 'Pagou', accessor: 'paid' },
              {
                Header: 'Editar',
                Cell: ({ row }) => (
                  <IconArea>
                    <IconEdit onClick={() => handleOpenModal(row.original)} />
                  </IconArea>
                ),
              },
            ]}
          />
        </>
      )}

      <Modal
        isOpen={openModal}
        onClickClose={handleCloseModal}
        onClickAction={handleUpdate}
        title={
          itemModal.paid === 'Sim'
            ? 'Confirma a exclusão do pagamento'
            : 'Confirma o recebimento do pagamento'
        }
        nameOfItem={`O valor ${itemModal.final_value} do competidor ${
          itemModal.playerNickname
        } será ${itemModal.paid === 'Sim' ? 'excluído!' : 'confirmado!'}`}
      >
        {itemModal.paid !== 'Sim' && (
          <Form ref={formRef}>
            <Input
              type="number"
              name="discount_value"
              placeholder="Valor com desconto"
              icon={IconPrice}
            />
          </Form>
        )}
      </Modal>
    </>
  );
}
