import * as React from 'react';
import { styled, useTheme } from '@mui/material/styles';
import { useNavigate } from 'react-router-dom';
import Box from '@mui/material/Box';
import MuiDrawer from '@mui/material/Drawer';
import { FiPower } from 'react-icons/fi';
import MuiAppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import List from '@mui/material/List';
import CssBaseline from '@mui/material/CssBaseline';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import { ButtonHeader, MenuAside } from './styles';
import { AuthContext } from '../../context/AuthContext';
import coloricon from '../../styles/coloricon';

const { IconChevron, IconMenu, secondaryColor, fontColor, background } =
  coloricon;

const drawerWidth = 200;

const openedMixin = (theme) => ({
  width: drawerWidth,
  backgroundColor: secondaryColor,
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: 'hidden',
});

const closedMixin = (theme) => ({
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  backgroundColor: secondaryColor,
  overflowX: 'hidden',
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up('sm')]: {
    width: `calc(${theme.spacing(8)} + 1px)`,
  },
});

const DrawerHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-end',
  fontFamily: 'Roboto Slab , serif',
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
}));

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== 'open',
})(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(['width', 'margin'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== 'open',
})(({ theme, open }) => ({
  width: drawerWidth,
  flexShrink: 0,
  whiteSpace: 'nowrap',
  boxSizing: 'border-box',
  ...(open && {
    ...openedMixin(theme),
    '& .MuiDrawer-paper': openedMixin(theme),
  }),
  ...(!open && {
    ...closedMixin(theme),
    '& .MuiDrawer-paper': closedMixin(theme),
  }),
}));

function MiniDrawer({ children, menu }) {
  const theme = useTheme();
  const [open, setOpen] = React.useState(true);
  const navigate = useNavigate();

  const { dataManager, dataPlayer, handleLogoutManager, handleLogoutPlayer } =
    React.useContext(AuthContext);

  const userName = dataManager?.manager?.name || dataPlayer?.player?.name;
  const logout = dataManager?.manager?.name
    ? handleLogoutManager
    : handleLogoutPlayer;

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  return (
    <Box sx={{ display: 'flex' }}>
      <CssBaseline />
      <AppBar position="fixed" open={open}>
        <Toolbar
          style={{
            backgroundColor: secondaryColor,
            color: fontColor,
            fontFamily: 'Roboto Slab , serif',
          }}
        >
          <IconButton
            color="inherit"
            aria-label="open drawer"
            onClick={handleDrawerOpen}
            edge="start"
            sx={{
              marginRight: 5,
              backgroundColor: secondaryColor,
              color: fontColor,
              ...(open && { display: 'none' }),
            }}
          >
            <IconMenu />
          </IconButton>
          <MenuAside>
            <Typography variant="h6" noWrap component="div">
              EquiprovasBahia
            </Typography>
            <Typography variant="h6" noWrap component="div">
              Bem vindo {userName || 'Usuário'}!
            </Typography>
            <ButtonHeader
              type="button"
              onClick={logout}
              style={{ backgroundColor: 'transparent' }}
            >
              <FiPower />
            </ButtonHeader>
          </MenuAside>
        </Toolbar>
      </AppBar>
      <Drawer variant="permanent" open={open}>
        <DrawerHeader>
          <IconButton onClick={handleDrawerClose}>
            {theme.direction === 'rtl' ? (
              <IconMenu color={fontColor} />
            ) : (
              <IconChevron color={fontColor} />
            )}
          </IconButton>
        </DrawerHeader>
        <Divider />
        <List>
          {menu.map((item) => {
            const { text, Icon, path } = item;
            return (
              <ListItem
                key={text}
                disablePadding
                sx={{ display: 'block' }}
                onClick={() => navigate(path)}
              >
                <ListItemButton
                  sx={{
                    minHeight: 48,
                    justifyContent: open ? 'initial' : 'center',
                    px: 2.5,
                  }}
                >
                  {Icon && (
                    <ListItemIcon
                      sx={{
                        minWidth: 0,
                        mr: open ? 3 : 'auto',
                        justifyContent: 'center',
                        color: fontColor,
                      }}
                    >
                      <Icon />
                    </ListItemIcon>
                  )}
                  <ListItemText primary={text} sx={{ opacity: open ? 1 : 0 }} />
                </ListItemButton>
              </ListItem>
            );
          })}
        </List>
      </Drawer>
      <Box
        component="main"
        sx={{ flexGrow: 1, p: 3 }}
        style={{ backgroundColor: background }}
      >
        <DrawerHeader />
        {children}
      </Box>
    </Box>
  );
}

export default MiniDrawer;
