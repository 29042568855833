import React, { useEffect, useState } from 'react';
import { useParams, Link } from 'react-router-dom'; // para pegar query params
import Loading from '../../components/Loading';
import coloricon from '../../styles/coloricon';
import { Container, Content } from './styles';
import api from '../../services/api';

export default function ConfirmationMailPlayer() {
  const [loading, setLoading] = useState(true);
  const { IconCheck, IconClose, IconHome } = coloricon;
  const [message, setMessage] = useState(null);
  const { token } = useParams();

  useEffect(() => {
    async function loaderConfirmationMail() {
      try {
        const response = await api.post('/confirmationMailPlayer', {
          token,
        });
        setMessage(response.data.message);

        setLoading(false);
      } catch (error) {
        setLoading(false);
        setMessage(error.response.data.error);
      }
    }
    loaderConfirmationMail();
  }, [token]);

  return loading === true ? (
    <Loading />
  ) : (
    <Container>
      <Content>
        <h1>Verificação de conta</h1>
        <div>
          {message ? <IconCheck /> : <IconClose />}
          {message ? (
            <p>{message}</p>
          ) : (
            <p>As informações enviadas são inválidas!</p>
          )}
        </div>
        <Link to="/loginPlayer">
          <IconHome /> <p>Acessar a plataforma</p>
        </Link>
      </Content>
    </Container>
  );
}
